export const callCoinAnimation = (number: number) => {
  const pushContianer = document.querySelector('.push-container') as any;
  const Emojis = document.querySelectorAll('.emoji');
  const points_labels = document.getElementById('points-label');
  if (points_labels) {
    const current_value = parseInt(points_labels.innerHTML);
    points_labels.innerHTML = (current_value + number) as any;
  }
  if (typeof number === 'number' && number > 0) {
    if (pushContianer && parseInt(pushContianer.childElementCount) >= number) {
      pushContianer.innerHTML = '';
    }
    if (Emojis && Emojis.length > 0 && pushContianer) {
      const emoji = Emojis[pushContianer.childElementCount] as any;
      Array.from({ length: number }, async (_index) => {
        const interval = Math.floor(Math.random() * (number * 300) + 1);
        await new Promise((r: any) =>
          setTimeout(() => {
            r();
          }, interval),
        );
        emoji.click();
      });

      Emojis.forEach((emoji) => {
        emoji.addEventListener('click', () => {
          const temp = emoji.cloneNode() as any;
          temp.classList.add(
            'animate-' + Math.floor(Math.random() * (7 - 1) + 1),
          );
          pushContianer.appendChild(temp);
        });
      });
    }
  }
};
