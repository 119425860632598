export const COLORS = {
  GRAY_EXTRA_LIGHT: '#E9E9E9',
  GRAY_LIGHT: '#E1E2E9',
  GRAY: '#707070',
  RED: '#FF0050',
  GREEN: '#18E58F',
  WHITE: '#FFF',
  LINK: '#FF8CB1',
  BLACK: '#0d0d0d',
};

export enum TYPOGRAPHY {
  bold = 'Gotham Rounded Bold',
  regular = 'Gotham Rounded',
}
