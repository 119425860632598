import { makeStyles } from 'tss-react/mui';

export const dialogStyles = makeStyles()((_theme: any) => ({
  dialog: {
    backdropFilter: 'blur(15px)',
    '& .MuiPaper-root': {
      backgroundImage:
        'linear-gradient(to bottom right, #572E88 -40%, white 50%)',
      borderRadius: '30px',
    },
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
}));
