import { CustomThemeValues } from '@/constants/styles';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

export const ProductItem = ({
  decription,
  imageUrl,
  name,
  price,
  priceWithDescount,
  stock,
  brand,
  id,
}: {
  imageUrl: string;
  name: string;
  price: number;
  decription: string;
  priceWithDescount: number;
  stock: number;
  brand: string;
  id: number;
}) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        borderRadius: CustomThemeValues.BORDER,
        border: CustomThemeValues.CARD,
        cursor: 'pointer',
        minHeight: '21rem',
      }}
    >
      <Box
        component={RouterLink}
        to={`/points/products/${id}?hide_navbar=true`}
        style={{ textDecoration: 'none' }}
      >
        <Box sx={{ padding: '2px .5rem 0 .5rem' }}>
          <img
            style={{
              width: '100%',
              height: '10rem',
              objectFit: 'contain',
            }}
            src={imageUrl}
            alt={name}
          />
        </Box>
        <Box
          sx={{
            padding: '.5rem 1rem 1rem 1rem',
          }}
        >
          <div
            style={{
              fontSize: '10px',
              color: '#1F1333',
              fontFamily: 'Gotham Rounded Bold',
              marginBottom: '5px',
            }}
          >
            {brand}
          </div>
          <div
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              fontSize: '11px',
              color: '#1F1333',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical',
              marginBottom: '3px',
            }}
          >
            {decription}
          </div>
          <div
            style={{
              color: '#797185',
              fontSize: '10px',
            }}
          >
            {name}
          </div>
          <Box>
            {priceWithDescount ? (
              <>
                <span
                  style={{
                    color: '#572D88',
                    fontSize: '1rem',
                    fontFamily: 'Gotham Rounded Bold',
                    marginRight: '.5rem',
                  }}
                >
                  {priceWithDescount} Pts
                </span>
                <span
                  style={{
                    textDecoration: 'line-through',
                    fontSize: '12px',
                    color: '#797185',
                  }}
                >
                  {price} Pts
                </span>
              </>
            ) : (
              <>
                <span
                  style={{
                    color: '#572D88',
                    fontSize: '1rem',
                    fontFamily: 'Gotham Rounded Bold',
                    marginRight: '.5rem',
                  }}
                >
                  {price} Pts
                </span>
              </>
            )}
          </Box>
          <footer style={{ fontSize: '10px', color: '#797185' }}>
            {stock} {t('products.stock')}
          </footer>
        </Box>
      </Box>
    </Box>
  );
};
