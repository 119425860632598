import { AppLayout } from '@/components';
import { Card } from '@/components/@core/card';
import { Box, Skeleton } from '@mui/material';

export const SkeletonProfileGateway = () => {
  return (
    <AppLayout>
      <Card
        style={{
          minHeight: '170px',
          padding: '1rem',
          marginTop: '2rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Skeleton variant="rounded" width={60} height={30} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div>
            <Skeleton
              sx={{
                margin: 'auto',
              }}
              variant="rounded"
              width={60}
              height={60}
            />
            <Skeleton variant="text" width={200} />
          </div>
        </Box>
      </Card>
      <Box marginTop={'1rem'}>
        <Card>
          <Box
            display={'flex'}
            flexDirection={'column'}
            gap={'2.5rem'}
            padding={'1rem'}
          >
            <Item />
            <Item />
            <Item />
            <Item />
            <Item />
          </Box>
        </Card>
      </Box>
    </AppLayout>
  );
};

const Item = () => {
  return (
    <Box display={'flex'} justifyContent={'space-between'}>
      <Box display={'flex'} alignItems={'center'} gap={'1rem'}>
        <Skeleton variant="rounded" width={20} height={20} />
        <Skeleton variant="text" width={60} />
      </Box>
      <Box>
        <Skeleton variant="rounded" width={40} height={20} />
      </Box>
    </Box>
  );
};
