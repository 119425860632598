export function Book2Icon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0003 18.4C9.75033 18.4 9.50033 18.3417 9.29199 18.225C7.73366 17.375 4.99199 16.475 3.27533 16.25L3.03366 16.2167C1.94199 16.0833 1.04199 15.0583 1.04199 13.95V3.88332C1.04199 3.22499 1.30033 2.62499 1.77533 2.19165C2.25033 1.75832 2.86699 1.54999 3.51699 1.60832C5.35033 1.74999 8.11699 2.66665 9.68366 3.64999L9.88366 3.76665C9.94199 3.79999 10.067 3.79999 10.117 3.77499L10.2503 3.69165C11.817 2.70832 14.5837 1.77499 16.4253 1.61665C16.442 1.61665 16.5087 1.61665 16.5253 1.61665C17.1337 1.55832 17.7587 1.77499 18.2253 2.20832C18.7003 2.64165 18.9587 3.24165 18.9587 3.89999V13.9583C18.9587 15.075 18.0587 16.0917 16.9587 16.225L16.6837 16.2583C14.967 16.4833 12.217 17.3917 10.692 18.2333C10.492 18.35 10.2503 18.4 10.0003 18.4ZM3.31699 2.84999C3.05033 2.84999 2.80866 2.94165 2.61699 3.11665C2.40866 3.30832 2.29199 3.58332 2.29199 3.88332V13.95C2.29199 14.4417 2.71699 14.9167 3.19199 14.9833L3.44199 15.0167C5.31699 15.2667 8.19199 16.2083 9.85866 17.1167C9.93366 17.15 10.042 17.1583 10.0837 17.1417C11.7503 16.2167 14.642 15.2667 16.5253 15.0167L16.8087 14.9833C17.2837 14.925 17.7087 14.4417 17.7087 13.95V3.89165C17.7087 3.58332 17.592 3.31665 17.3837 3.11665C17.167 2.92499 16.892 2.83332 16.5837 2.84999C16.567 2.84999 16.5003 2.84999 16.4837 2.84999C14.892 2.99165 12.3253 3.84999 10.9253 4.72499L10.792 4.81665C10.3337 5.09999 9.68366 5.09999 9.24199 4.82499L9.04199 4.70832C7.61699 3.83332 5.05033 2.98332 3.41699 2.84999C3.38366 2.84999 3.35033 2.84999 3.31699 2.84999Z"
        fill="#352B47"
      />
      <path
        d="M10 17.7C9.65833 17.7 9.375 17.4167 9.375 17.075V4.57501C9.375 4.23335 9.65833 3.95001 10 3.95001C10.3417 3.95001 10.625 4.23335 10.625 4.57501V17.075C10.625 17.425 10.3417 17.7 10 17.7Z"
        fill="#352B47"
      />
      <path
        d="M6.45801 7.70001H4.58301C4.24134 7.70001 3.95801 7.41668 3.95801 7.07501C3.95801 6.73335 4.24134 6.45001 4.58301 6.45001H6.45801C6.79967 6.45001 7.08301 6.73335 7.08301 7.07501C7.08301 7.41668 6.79967 7.70001 6.45801 7.70001Z"
        fill="#352B47"
      />
      <path
        d="M7.08301 10.2H4.58301C4.24134 10.2 3.95801 9.91668 3.95801 9.57501C3.95801 9.23335 4.24134 8.95001 4.58301 8.95001H7.08301C7.42467 8.95001 7.70801 9.23335 7.70801 9.57501C7.70801 9.91668 7.42467 10.2 7.08301 10.2Z"
        fill="#352B47"
      />
    </svg>
  );
}
