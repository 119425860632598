import {
  differenceInSeconds,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  differenceInMonths,
  parseISO,
  format,
} from 'date-fns';
import esLocale from 'date-fns/locale/es';
import enLocale from 'date-fns/locale/en-US';
import { t } from 'i18next';

export const APP_NAME_PREFIX = '@Hackup';

export const APP_DAYS_CALENDAR = [
  { name: 'Monday', label: 'Lunes' },
  { name: 'Friday', label: 'Viernes' },
  { name: 'Tuesday', label: 'Martes' },
  { name: 'Saturday', label: 'Sábado' },
  { name: 'Wednesday', label: 'Miércoles' },
  { name: 'Sunday', label: 'Domingo' },
  { name: 'Thursday', label: 'Jueves' },
];

export const buildActionType = (actionType: string) => {
  return `${APP_NAME_PREFIX}/${actionType}`;
};

/**
 * It takes a date as a parameter and returns a string with the difference between the current date and
 * the date passed as a parameter
 * @param {any} date - any
 * @returns A string.
 */
export const notificationDate = (date: any) => {
  const seconds = differenceInSeconds(new Date(), parseISO(date));
  const days = differenceInDays(new Date(), parseISO(date));
  const months = differenceInMonths(new Date(), parseISO(date));
  const hours = differenceInHours(new Date(), parseISO(date));
  const minutes = differenceInMinutes(new Date(), parseISO(date));

  if (days > 30) {
    return `Hace ${months} ${months > 1 ? 'meses' : 'mes'}.`;
  }
  if (seconds > 86400) {
    return `Hace ${days} ${days > 1 ? 'días' : 'día'}.`;
  }
  if (seconds > 3600) {
    return `Hace ${hours} ${hours > 1 ? 'horas' : 'hora'}.`;
  }
  if (seconds > 60) {
    return `Hace ${minutes} ${minutes > 1 ? 'minutos' : 'minuto'}.`;
  }
  return `Hace ${seconds} segundo(s).`;
};

/**
 * It takes a file as an argument and returns a promise that resolves to the base64 representation of
 * the file
 * @param {any} file - The file to be converted to base64
 * @returns A promise that resolves to a base64 string.
 */
export const toBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onloadend = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

/**
 * It takes a base64 string, a file type, and an optional filename and returns a file object
 * @param {any} base64 - The base64 string that you want to convert to a file.
 * @param {string} type - The file type.
 * @param {string} [filename] - The name of the file.
 * @returns A file object.
 */
export const base64ToFile = (base64: any, type: string, filename?: string) => {
  const ext = type.split('/');
  return new File(
    [window.atob(base64)],
    filename ? filename : `${getDateUId()}.${ext[1]}`,
    { type },
  );
};

/**
 * It returns a string that is the current date and time in the format of yyyyMMddHHmmssSS
 * @returns A string with the current date and time in the format yyyyMMddHHmmssSS.
 */
export const getDateUId = () => {
  return format(new Date(), 'yyyyMMddHHmmssSS');
};

/**
 * It takes a date in any format and returns a date in the format "dd 'de' MMMM" (e.g. "01 de enero")
 * @param {any} date - any
 * @returns A function that takes a date and returns a string.
 */
export const parseDate = (date: any) => {
  try {
    return format(parseISO(date), `dd '${t('general.of')}' MMMM`, {
      locale: window.localStorage.getItem('language')?.includes('en')
        ? enLocale
        : esLocale,
    });
  } catch {
    return date;
  }
};

export const stepsToObtainPoints = [
  'Finalizas una cápsula educativa (5)',
  'Haces un comentario a una cápsula educativa (2)',
  'Pasas un examen (10)',
  'Le das like al comentario de alguien (1)',
  'Ves tus cápsulas educativas durante 4 semanas seguidas (20)',
  'Pasas tus pruebas de conocimiento: 1 intento (10), 2 intentos (5)',
];

/**
 * It takes a string of CSS and injects it into the page
 * @param {any} style - any
 */
export const injectStyle = (style: any) => {
  const styleElement = document.createElement('style');
  document.head.appendChild(styleElement);
  const styleSheet = styleElement.sheet;
  styleSheet?.insertRule(style, styleSheet?.cssRules.length);
};

/**
 * "sleep() returns a Promise that resolves after a given number of milliseconds."
 *
 * The function is asynchronous because it returns a Promise. The function is synchronous because it
 * doesn't use await
 * @param {number} ms - The number of milliseconds to sleep.
 * @returns A promise that resolves after a certain amount of time.
 */
export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

/**
 * It takes an error object and returns a string
 * @param {any} error - any
 * @returns A function that takes an error and returns a string.
 */
export const getErrorMessageApi = (error: any) => {
  try {
    let message = '';
    if (error instanceof Object) {
      if (error.msg) {
        message = error.msg;
      } else {
        const keys = Object.keys(error);
        keys.forEach((key, index) => {
          message += `${error[key]}`;
          if (index < keys.length - 1) message += ', ';
        });
      }
    } else if (error instanceof Array) {
      message += error.join(', ');
    } else {
      message += error;
    }
    return message;
  } catch (error) {
    return 'Error unknow!';
  }
};

/**
 * It takes a string, capitalizes the first letter, and returns the new string
 * @param {string} str - string - The string to capitalize.
 * @returns The first character of the string is being capitalized and then the rest of the string is
 * being returned.
 */
export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const get = (obj: unknown, path: string, defValue?: string) => {
  // If path is not defined or it has false value
  if (!path) return undefined;
  // Check if path is string or array. Regex : ensure that we do not have '.' and brackets.
  // Regex explained: https://regexr.com/58j0k
  const pathArray = Array.isArray(path)
    ? path
    : (path.match(/([^[.\]])+/g) as any[]);
  // Find value
  if (!pathArray) {
    return undefined;
  }

  const result = pathArray.reduce(
    (prevObj: any, key: any) => prevObj && prevObj[key],
    obj,
  );
  // If found value is undefined return default value; otherwise return the value
  return result === undefined ? defValue : result;
};

export const isFeatureFlagsEnabled = !!import.meta.env
  .VITE_APP_FEATURE_FLAG_ENVIRONMENT;

export const shuffle = (array: Array<any>) => {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex > 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

export function mobileCheck() {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4),
      )
    )
      check = true;
  })(navigator.userAgent);
  return check;
}

// a function to retry loading a chunk to avoid chunk load error for out of date code
export const lazyRetry = function (componentImport: any) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('retry-lazy-refreshed') || 'false',
    );
    // try to import the component
    componentImport()
      .then((component: any) => {
        window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
        resolve(component);
      })
      .catch((error: any) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  }) as any;
};
