export const removeDuplicateElementsFromArray = (array: any[]): any[] => {
  //@ts-ignore
  const unique = Array.from(
    //@ts-ignore
    new Set(array.map(JSON.stringify)),
    JSON.parse,
  );

  return unique;
};
