import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  LinearProgress,
  useTheme,
  Skeleton,
  IconButton,
} from '@mui/material';
import { TYPOGRAPHY } from '../ListContentPage/constants';
import { ItemAnswer } from './components/ItemAnswer';
// import useFetch from '../../hooks/useFetch'; // TODO use this hook
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { get, post } from '../../services/api';
import { AppLayout } from '../../components';
import { shuffle } from '../../utils/general';
import { ErrorModal } from './components/ErrorModal';
import { SuccessModal } from './components/SuccessModal';
import { callCoinAnimation } from '../../utils/animations';
import PointsAnimation from '../../components/PointsAnimation';
import { CloseIcon } from '../ContentDetailPage/Icons/CloseIcon';
import { CustomThemeValues } from '../../constants/styles';
import { removeDuplicateElementsFromArray } from '../../utils/removeDuplicateElementsFromArray';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '../../constants/queryKeys';

export interface ISelfAssesmentResponse {
  data: IData;
}

export interface IData {
  pk: number;
  self_assessment: ISelfAssessment;
}

export interface ISelfAssessment {
  pk: number;
  question: string;
  slug_name: string;
  available_points: number;
  questions_to_approve: number;
  questions: IQuestion[];
  extra_questions: IExtraQuestion[];
  image: string | null;
}

export interface IExtraQuestion {
  pk: number;
  question: string;
  slug_name: string;
  questions: IQuestion[];
}

export interface IQuestion {
  pk: number;
  option: string;
  text: string;
}

export const SelfAssessmentPage = () => {
  const params = useParams();

  const [selfAssesment, setSelfAssesment] = useState<ISelfAssessment>();
  const [contentName, setContentName] = useState<string>();
  const [allQuestion, setAllQuestion] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [activeQuestion, setActiveQuestion] = useState<number>(1);
  const [answers, setAnswers] = useState<any[]>([]);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [successModalData, setSuccessModalData] = useState({
    open: false,
    showNextButton: false,
    points: 0,
    new_delivery: 0,
    url: '',
    custom_feedback: '',
  });
  const [customFeedback, setCustomFeedback] = useState<null | string>(null);
  const indexOption = useRef(['A', 'B', 'C', 'D']);

  const navigate = useNavigate();
  const theme = useTheme();
  const queryClient = useQueryClient();

  const handleChange = (
    pkAnswer: number,
    pkQuestion: number,
    type: string,
    option: string,
    numberQuestion: number,
  ) => {
    const alreadyAnswered = answers.some(
      (answer: any) => pkQuestion === answer.pk,
    );
    if (alreadyAnswered) {
      const newAnswers = answers.filter((answer: any) => {
        return answer.pk !== pkQuestion;
      });
      setAnswers(newAnswers);
    }
    setAnswers((prev: any) => {
      return [
        ...prev,
        {
          response_pk: pkAnswer,
          pk: pkQuestion,
          question_type: type,
          option,
          questionIndex: numberQuestion,
          isCorrect: null,
        },
      ];
    });
  };

  const hasExtraQuestions =
    (selfAssesment?.extra_questions?.length as number) > 0;

  const allPercentage = Number(selfAssesment?.questions_to_approve);
  const progresValue = (activeQuestion * 100) / allPercentage;
  const size12 = 12;
  const isLastQuestion =
    activeQuestion === Number(allQuestion?.length) && allQuestion.length > 1;

  const invalidatePoints = () => {
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.POINTS],
      exact: true,
      refetchType: 'all',
    });
  };

  const { mutate } = useMutation({
    mutationKey: [QueryKeys.ANSWER_SELF_ASSESSMENT],
    mutationFn: (answers: any) => {
      return post(
        `contents/self-assessments/${params.selfAssesmentId}/validate`,
        {
          responses: removeDuplicateElementsFromArray(answers),
        },
      );
    },
    onSuccess: async (response) => {
      if (!response.data.resolved) {
        setOpenErrorModal(true);
        if (response.data?.wrong_choices?.length) {
          response.data.wrong_choices.forEach((element: any) => {
            const resolvedAnswers = answers.map((answer: any) => {
              if (answer.response_pk === element) {
                answer.isCorrect = false;
              } else {
                answer.isCorrect = true;
              }
              return answer;
            });
            setAnswers(resolvedAnswers);
            setCustomFeedback(response.data.custom_feedback);
          });
        }
      } else {
        callCoinAnimation(Number(response.data?.earned_points));
        const responseNextContent = await post(
          `contents/${params.deliveryPk}/unlock-next/`,
          {
            next_distribution_content: response.data.next_distribution_content,
          },
        );
        invalidatePoints();
        setSuccessModalData({
          open: true,
          showNextButton: response.data.next_distribution_content,
          points: response.data.hacku_points,
          new_delivery: responseNextContent.data?.delivery,
          url: responseNextContent.data?.url,
          custom_feedback: response.data.custom_feedback,
        });
      }
    },
  });

  const submitAssesment = () => {
    const responses = [
      ...answers.map((answer: any) => ({
        response_pk: answer.response_pk,
        pk: answer.pk,
        question_type: answer.question_type,
      })),
    ];
    mutate(responses);
  };

  const handleActiveQuestion = () => {
    if (activeQuestion < Number(allQuestion?.length)) {
      setActiveQuestion(activeQuestion + 1);
    }
  };

  const currentQuestion = () => {
    return allQuestion[activeQuestion - 1];
  };

  const tryAgain = () => {
    setAnswers([]);
    setOpenErrorModal(false);
    setActiveQuestion(1);
  };

  const getImageUrl = (): string => {
    return selfAssesment?.image ?? '';
  };

  useEffect(() => {
    get(`contents/self-assessments/${params.selfAssesmentId as string}/`).then(
      (response) => {
        setSelfAssesment(response?.data.self_assessment);
        setContentName(response?.data.content);

        const singleQuestion = {
          question: response?.data.self_assessment.question,
          questions: response?.data.self_assessment.questions,
          pk: response?.data?.self_assessment.pk,
        };

        const extraQuestions = response?.data.self_assessment.extra_questions;

        const allQuestions = [
          singleQuestion,
          ...extraQuestions?.map((question: any) => {
            return { ...question, type: 'EXTRA' };
          }),
        ];
        allQuestions.forEach((question: any) => {
          question.questions = shuffle(question.questions);
          question.questions.forEach((question: any, index: number) => {
            question.option = indexOption.current[index];
          });
        });
        setAllQuestion(allQuestions);
        setLoading(false);
      },
    );
  }, [params]);

  if (loading)
    return (
      <AppLayout>
        <SelfAssesmentSkeleton />
      </AppLayout>
    );

  return (
    <AppLayout>
      <Box
        sx={{
          position: 'relative',
        }}
      >
        <PointsAnimation />
        <Card
          sx={{
            borderRadius: CustomThemeValues.BORDER,
            border: CustomThemeValues.CARD,
          }}
        >
          <CardContent>
            <Box>
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Typography
                  fontFamily={TYPOGRAPHY.bold}
                  fontSize={'16px'}
                  textAlign="center"
                >
                  Prueba de conocimiento
                </Typography>
                <IconButton
                  aria-label="close"
                  onClick={() =>
                    navigate(`/content/detail/${params.deliveryPk}`)
                  }
                >
                  <CloseIcon />
                </IconButton>
              </Box>

              <Box>
                <Box
                  component={'span'}
                  fontFamily={TYPOGRAPHY.regular}
                  fontSize={'16px'}
                  fontWeight={350}
                  color={'#1F1333'}
                >
                  Capsula:
                </Box>{' '}
                <Box component={'span'} fontSize={'16px'} color={'#625A70'}>
                  {contentName}
                </Box>
              </Box>

              {getImageUrl().length > 0 && (
                <Box display={'flex'} justifyContent={'center'}>
                  <Box
                    component={'img'}
                    src={'https://placehold.co/600x400'}
                    sx={{
                      borderRadius: '1rem',
                      marginTop: '1rem',
                      maxWidth: '320px',
                      maxHeight: '200px',
                    }}
                  />
                </Box>
              )}
              {hasExtraQuestions && (
                <Box marginTop={'1rem'}>
                  <Typography
                    color={theme.palette.success.main}
                    fontFamily={TYPOGRAPHY.bold}
                    gutterBottom
                    fontSize={size12}
                  >
                    Pregunta {activeQuestion}/{allQuestion.length}
                  </Typography>
                  <LinearProgress value={progresValue} variant="determinate" />
                </Box>
              )}

              <Box marginTop={'1rem'}>
                <Typography
                  fontFamily={TYPOGRAPHY.bold}
                  gutterBottom
                  fontSize={'16px'}
                >
                  Pregunta
                </Typography>
                <Typography fontSize={14}>
                  {currentQuestion()?.question}
                </Typography>
              </Box>
              <Box marginTop={'1rem'}>
                <Typography
                  fontFamily={TYPOGRAPHY.bold}
                  gutterBottom
                  fontSize={'14px'}
                  color={'#1F1333'}
                  fontWeight={350}
                >
                  Elije la respuesta correcta
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1rem',
                }}
              >
                {currentQuestion()?.questions.map((question: any) => {
                  return (
                    <ItemAnswer
                      key={question.pk}
                      label={question.option}
                      pk={question.pk}
                      description={question.text}
                      onChange={handleChange}
                      currentQuestion={currentQuestion().pk}
                      type={currentQuestion().type || 'MAIN'}
                      answers={answers}
                      activeQuestion={activeQuestion}
                    />
                  );
                })}
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '3rem',
                marginBottom: '2rem',
              }}
            >
              {isLastQuestion && (
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: '4rem',
                    paddingX: '2rem',
                    paddingY: '.5rem',
                    fontFamily: 'Gotham Rounded',
                  }}
                  onClick={submitAssesment}
                  disabled={answers.length === 0}
                >
                  Guardar respuesta
                </Button>
              )}
              {!hasExtraQuestions && (
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: '4rem',
                    paddingX: '2rem',
                    paddingY: '.5rem',
                    fontFamily: 'Gotham Rounded',
                  }}
                  onClick={submitAssesment}
                  disabled={answers.length === 0}
                >
                  Guardar respuesta
                </Button>
              )}

              {hasExtraQuestions && !isLastQuestion && (
                <Button
                  style={{
                    boxShadow: 'none',
                  }}
                  variant="contained"
                  sx={{
                    borderRadius: '4rem',
                    paddingX: '2rem',
                    paddingY: '.5rem',
                    fontFamily: 'Gotham Rounded',
                  }}
                  onClick={handleActiveQuestion}
                  disabled={answers.length === 0}
                >
                  Siguiente
                </Button>
              )}
            </Box>
          </CardContent>
        </Card>
      </Box>
      <ErrorModal
        open={openErrorModal}
        onClose={() => navigate(`/content/detail/${params.deliveryPk}`)}
        answers={removeDuplicateElementsFromArray(answers)}
        tryAgain={tryAgain}
        customFeedback={customFeedback}
      />
      <SuccessModal
        onClose={() =>
          setSuccessModalData({ ...successModalData, open: false })
        }
        open={successModalData.open}
        answers={removeDuplicateElementsFromArray(answers)}
        showNextButton={successModalData.showNextButton}
        points={successModalData.points}
        newDelivery={successModalData.new_delivery}
        url={successModalData.url}
        customFeedback={successModalData.custom_feedback}
        deliveryPk={Number(params.deliveryPk)}
      />
    </AppLayout>
  );
};

const SelfAssesmentSkeleton = () => {
  return (
    <Box>
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <Skeleton width={'6rem'} />
        <Skeleton width={'12rem'} />
      </Box>
      <Box>
        <Skeleton width={'4rem'} />
        <Skeleton width={'100%'} />
      </Box>
      <Box>
        <Skeleton width={'4rem'} />
        <Skeleton width={'60%'} />
      </Box>
      <Box>
        <Skeleton width={'6rem'} />
      </Box>
      <Box>
        <Skeleton width={'4rem'} />
        <Skeleton width={'100%'} variant="rounded" height={'4rem'} />
      </Box>
      <Box>
        <Skeleton width={'4rem'} />
        <Skeleton width={'100%'} variant="rounded" height={'4rem'} />
      </Box>
      <Box>
        <Skeleton width={'4rem'} />
        <Skeleton width={'100%'} variant="rounded" height={'4rem'} />
      </Box>
      <Box display={'flex'} justifyContent={'center'} marginTop={'1rem'}>
        <Skeleton width={'200px'} variant="rounded" height={'2rem'} />
      </Box>
    </Box>
  );
};
