import { create } from 'zustand';
type RankingStore = {
  pages: number;
  rankingUser: any[];
  isLoading: boolean;
  isActiveFilter: boolean;
  setRankingUser: (value: any[]) => void;
  setIsLoading: (value: boolean) => void;
  setIsActiveFilter: (value: boolean) => void;
  setPages: (value: number) => void;
};

const useUserRankingStore = create<RankingStore>()((set) => ({
  pages: 0,
  rankingUser: [],
  isLoading: false,
  isActiveFilter: false,
  setRankingUser: (value) => set({ rankingUser: value }),
  setIsLoading: (value) => set({ isLoading: value }),
  setIsActiveFilter: (value) => set({ isActiveFilter: value }),
  setPages: (value) => set({ pages: value }),
}));

export { useUserRankingStore };
