import React, { FunctionComponent } from 'react';
import { Box } from '@mui/material';

interface BoxFlexProps {
  children: React.ReactNode;
  width?: number | string;
  my?: string | number;
  mx?: string | number;
  column?: boolean;
  color?: string;
  fontWeight?: string;
}

const BoxFlex: FunctionComponent<BoxFlexProps> = ({
  children,
  width,
  my,
  mx,
  column,
  color,
  fontWeight,
}) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      width={width ? width : '100%'}
      flexDirection={column ? 'column' : 'initial'}
      my={!my ? 3 : my}
      mx={!mx ? 'auto' : mx}
      color={color ? color : '#00000'}
      fontWeight={fontWeight ? fontWeight : 'normmal'}
    >
      {children}
    </Box>
  );
};

export default BoxFlex;
