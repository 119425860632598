import { FunctionComponent, useEffect } from 'react';

import {
  Box,
  Grid,
  useTheme,
  useMediaQuery,
  Typography,
  Switch,
} from '@mui/material';
// hooks

// styles
import { profilePageGatewayStyles } from './ProfilePageGateway.styles';
//components
import ModalConnection from './ModalConnection/ModalConnection';
import { Header } from './components/header';

import { useGateway } from '../../hooks';
import MissingDataModal from './MissingDataModal';
import { GatewayType } from '../../hooks/useGateway';
import { Card } from '@/components/@core/card';
import {
  User,
  useUser,
} from '@/components/AuthInterceptorComponent/hooks/useUser';
import { SkeletonProfileGateway } from '@/pages/ProfileGateway/components/skeleton-profile-gateway';

const ProfilePageGateway: FunctionComponent = () => {
  //use Hooks
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const { user } = useUser();
  const photo = user?.profile?.photo;
  const {
    handleClose,
    getOptions,
    handleOpen,
    open,
    activeOption,
    handleMissingDataModal,
    missingDataModalInfo,
    showMissingDataModal,
    hideMissingDataModal,
    setActiveOption,
    setOpen,
    getGateways,
    gatewaysElements,
  } = useGateway(user as User);
  const { classes } = profilePageGatewayStyles();
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  //effects
  useEffect(() => {
    getGateways();
  }, [getGateways]);

  if (gatewaysElements.length === 0) {
    return <SkeletonProfileGateway />;
  }

  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item xs={12} md={8} lg={8}>
        <Grid container spacing={isLargeScreen ? 3 : 2} marginTop={'1rem'}>
          <Grid item xs={12} className={classes.itemContainer}>
            <Header />
            <Card
              style={{
                marginTop: '1rem',
              }}
            >
              <Box display="flex" flexDirection="column">
                {getOptions(gatewaysElements)?.map((option, index) => (
                  <Box
                    key={index}
                    justifyContent={'space-between'}
                    display={'inline-flex'}
                    alignItems={'center'}
                    padding={'10px'}
                    paddingBottom={'1rem'}
                  >
                    <Box
                      display={'flex'}
                      textTransform={'capitalize'}
                      alignItems={'center'}
                    >
                      {option.icon}
                      <Typography ml="10px" fontSize={'12px'}>
                        {option.name}
                      </Typography>
                    </Box>
                    <Box>
                      {option.checked ? (
                        <span
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            handleOpen(option);
                          }}
                        >
                          <Switch
                            {...label}
                            checked={option.checked}
                            sx={{
                              pointerEvents: 'none',
                            }}
                          />
                        </span>
                      ) : (
                        <span
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            if (
                              !option.can_add ||
                              option.key.toLocaleLowerCase() === 'teams' ||
                              option.key.toLocaleLowerCase() === 'google_chat'
                            ) {
                              return handleOpen(option);
                            }

                            handleMissingDataModal(
                              option.key.toLocaleUpperCase() as GatewayType,
                            );
                            return setActiveOption(option);
                          }}
                        >
                          <Switch
                            sx={{
                              pointerEvents: 'none',
                            }}
                          />
                        </span>
                      )}
                    </Box>
                  </Box>
                ))}
              </Box>
            </Card>
            {open && (
              <ModalConnection
                activeOption={activeOption}
                handleClose={handleClose}
                open={open}
                photo={photo as string}
              />
            )}

            <MissingDataModal
              open={showMissingDataModal}
              handleClose={hideMissingDataModal}
              missingDataModalInfo={missingDataModalInfo}
              onSuccess={() => {
                getGateways().then((data) => {
                  const newActiveOption = getOptions(data).find(
                    (option) =>
                      option.name.toLocaleLowerCase() ===
                      activeOption.name.toLocaleLowerCase(),
                  );

                  // @ts-ignore
                  setActiveOption(newActiveOption);
                  setOpen(true);
                });
              }}
              onError={() => setOpen(false)}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProfilePageGateway;
