import { Input } from '@/components/@core/input';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useTranslation } from 'react-i18next';
import { Fragment } from 'react/jsx-runtime';

type ExtraFields = {
  name: string;
  title: string;
  required: true;
  field_type: 'SELECT' | 'CHARFIELD';
  choices: [
    {
      id: string;
      name: string;
    },
    {
      id: string;
      name: string;
    },
  ];
};

export const getExtraFieldsObject = (
  extraFields: ExtraFields[],
  extraFieldValues: {
    [key: string]: unknown;
  },
) => {
  return extraFields.reduce((obj, item) => {
    // @ts-ignore
    obj[item.name] = extraFieldValues[item.name] ?? '';
    return obj;
  }, {} as ExtraFields);
};

export const ExtraFieldsInputs = ({
  extra_fields,
  getFieldProps,
}: {
  extra_fields: ExtraFields[];
  getFieldProps: any;
}) => {
  const { t } = useTranslation();
  return (
    <>
      {extra_fields.map((field) => {
        if (field.field_type === 'SELECT') {
          return (
            <Fragment key={field.name}>
              <label
                htmlFor=""
                style={{
                  fontSize: '12px',
                  color: '#625A70',
                }}
              >
                {field.title}
              </label>

              <Select
                {...getFieldProps(`extra_fields.${field.name}`)}
                inputProps={{
                  'aria-label': 'Without label',
                }}
                sx={{
                  marginBottom: '1rem',
                  paddingRight: '.8rem',
                }}
                fullWidth
                // IconComponent={() => (
                //   <svg
                //     width="16"
                //     height="16"
                //     viewBox="0 0 16 16"
                //     fill="none"
                //     xmlns="http://www.w3.org/2000/svg"
                //   >
                //     <path
                //       d="M8.00001 11.2C7.53335 11.2 7.06668 11.02 6.71335 10.6667L2.36668 6.31999C2.17335 6.12666 2.17335 5.80666 2.36668 5.61332C2.56001 5.41999 2.88001 5.41999 3.07335 5.61332L7.42001 9.95999C7.74001 10.28 8.26001 10.28 8.58001 9.95999L12.9267 5.61332C13.12 5.41999 13.44 5.41999 13.6333 5.61332C13.8267 5.80666 13.8267 6.12666 13.6333 6.31999L9.28668 10.6667C8.93335 11.02 8.46668 11.2 8.00001 11.2Z"
                //       fill="#797185"
                //     />
                //   </svg>
                // )}
              >
                <MenuItem disabled value="">
                  {t('general.selectOption')}
                </MenuItem>
                {field.choices.map((choice) => (
                  <MenuItem key={choice.id} value={choice.id}>
                    {choice.name}
                  </MenuItem>
                ))}
              </Select>
            </Fragment>
          );
        }
        return (
          <Input
            label={field.title}
            key={field.name}
            style={{ marginBottom: '1rem' }}
            inputProps={{
              type: 'text',
              ...getFieldProps('extra_fields.' + field.name),
            }}
          />
        );
      })}
    </>
  );
};
