import React from 'react';
import { Route, Routes as ReactRoutes } from 'react-router-dom';
import ClientRoute from './clientRoute';
import ProfilePageGateway from '@/pages/ProfileGateway/ProfilePageGateway';
import { SelfAssessmentPage } from '@/pages/SelfAssessmentPage';
import ListContentPage from '@/pages/ListContentPage';
import Loadable from '../Loadable';
import { ProfilePage as ProfilePageV2 } from '@/pages/v2/profile';
import { lazyRetry } from '@/utils/general';
import { PointsPage } from '@/pages/PointsPage';
import { Error } from '@/pages/Error';
import { ProductList } from '@/pages/PointsRedemption/ProductList';
import { ProductDetails } from '@/pages/PointsRedemption/ProductDetails';
import { SuccessRedemption } from '@/pages/PointsRedemption/SuccessRedemption';

const HomePage = Loadable(
  React.lazy(() => lazyRetry(() => import('@/pages/HomePage'))),
);
const ChangeModulePage: any = Loadable(
  React.lazy(() => lazyRetry(() => import('@/pages/ChangeModulePage'))),
);
const FavoriteContentPage = Loadable(
  React.lazy(() => lazyRetry(() => import('@/pages/FavoriteContentPage'))),
);
const ContentDetailPage = Loadable(
  React.lazy(() => lazyRetry(() => import('@/pages/ContentDetailPage'))),
);
const ModulePage = Loadable(
  React.lazy(() => lazyRetry(() => import('@/pages/ModulePage'))),
);
const PositionPage = Loadable(
  React.lazy(() => lazyRetry(() => import('@/pages/PositionPage'))),
);
const ProfileImagePage = Loadable(
  React.lazy(() => lazyRetry(() => import('@/pages/v2/profileImagePage'))),
);
const CertificatePage = Loadable(
  React.lazy(() => lazyRetry(() => import('@/pages/CertificatePage'))),
);
const CertificateDetailPage = Loadable(
  React.lazy(() => lazyRetry(() => import('@/pages/CertificateDetailPage'))),
);
const EvaluationPage = Loadable(
  React.lazy(() => lazyRetry(() => import('@/pages/EvaluationPage'))),
);
const NotFoundPage = Loadable(
  React.lazy(() => lazyRetry(() => import('@/pages/NotFound/NotFound'))),
);
const ChangeTimeAndDayPage = Loadable(
  React.lazy(() => lazyRetry(() => import('@/pages/ChangeTimeAndDay'))),
);
// const ListContentPage = Loadable(
//   React.lazy(() => lazyRetry(() => import('@/pages/ListContentPage'))),
// );

const Routes = () => {
  return (
    <ReactRoutes>
      <Route path="/" element={<HomePage />}></Route>
      <Route path="/profile" element={<ProfilePageV2 />} />
      <Route path="/profile/image" element={<ProfileImagePage />}></Route>
      <Route path="/profile/gateway" element={<ProfilePageGateway />}></Route>
      <Route
        path="/profile/change-day-and-time"
        element={<ChangeTimeAndDayPage />}
      ></Route>
      <Route path="/position" element={<PositionPage />}></Route>
      <Route path="/certificate" element={<CertificatePage />}></Route>
      <Route
        path="/certificate/detail"
        element={<CertificateDetailPage />}
      ></Route>
      <Route path="/module" element={<ModulePage />}></Route>
      <Route path="/content" element={<ListContentPage />} />
      <Route
        path="/change-module"
        element={<ClientRoute element={<ChangeModulePage />}></ClientRoute>}
      />
      <Route
        path="/content/detail/:contentDeliveryPk"
        element={<ClientRoute element={<ContentDetailPage />}></ClientRoute>}
      ></Route>
      <Route
        path="/content/detail/self-assesment/:selfAssesmentId/:deliveryPk"
        element={<ClientRoute element={<SelfAssessmentPage />}></ClientRoute>}
      />
      <Route
        path="/content/favorites"
        element={<ClientRoute element={<FavoriteContentPage />}></ClientRoute>}
      ></Route>
      <Route
        path="/content/evaluation"
        element={<ClientRoute element={<EvaluationPage />}></ClientRoute>}
      ></Route>
      <Route
        path="/points"
        element={<ClientRoute element={<PointsPage />} />}
      ></Route>
      <Route
        path="/points/products"
        element={<ClientRoute element={<ProductList />} />}
      ></Route>
      <Route
        path="/points/products/:id"
        element={<ClientRoute element={<ProductDetails />} />}
      ></Route>
      <Route
        path="/points/products/success/:id"
        element={<ClientRoute element={<SuccessRedemption />} />}
      ></Route>
      <Route path="/error" element={<Error />}></Route>
      <Route path="*" element={<NotFoundPage />}></Route>
    </ReactRoutes>
  );
};

export default Routes;
