import { AppLayout } from '@/components';
import { Card } from '@/components/@core/card';
import { Box, Skeleton } from '@mui/material';
export const ProfileSkeleton = () => {
  return (
    <AppLayout>
      <Card>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Skeleton variant="rounded" width={60} height={30} />
          <Skeleton variant="rounded" width={60} height={30} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div>
            <Skeleton
              sx={{
                margin: 'auto',
              }}
              variant="rounded"
              width={60}
              height={60}
            />
            <Skeleton variant="text" width={200} />
            <Skeleton
              sx={{
                margin: 'auto',
              }}
              variant="rounded"
              width={100}
              height={30}
            />
          </div>
        </Box>
      </Card>

      {/* start Learning Experience */}
      <Box marginTop={'1rem'}>
        <Skeleton variant="text" width={200} />
      </Box>
      <Box marginTop={'2rem'}>
        <LearningExperienceItemSkeleton />
        <LearningExperienceItemSkeleton />
        <LearningExperienceItemSkeleton />
        <LearningExperienceItemSkeleton />
        <LearningExperienceItemSkeleton />
        <LearningExperienceItemSkeleton />
        <LearningExperienceItemSkeleton />
      </Box>
    </AppLayout>
  );
};

const LearningExperienceItemSkeleton = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: '1rem',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        <div>
          <Skeleton variant="rounded" width={40} height={40} />
        </div>
        <div>
          <Skeleton variant="text" width={60} />
          <Skeleton variant="text" width={150} />
        </div>
      </Box>
      <Box>
        <Skeleton variant="rounded" width={20} height={20} />
      </Box>
    </Box>
  );
};
