import { useTranslation } from 'react-i18next';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
// Hooks
import { useGoBack } from '../../../hooks/useGoBack';
import { usePoints } from '../../../hooks/usePoints';

// Components
import { BackIcon } from '@/components/icons/BackIcon';
import { useProducts } from '@/pages/PointsRedemption/hooks/useProducts';
import { usePendingContentRedirect } from '@/hooks/usePendingContentRedirect';
import { useConfig } from '@/hooks/useConfig';

export const Heading = () => {
  const { t } = useTranslation();
  const goBack = useGoBack();
  const points = usePoints();
  const config = useConfig();
  const theme = useTheme();
  const products = useProducts();
  const { handlePendingContentRedirect } = usePendingContentRedirect();
  return (
    <>
      <Box
        sx={{
          border: '1px solid #E1E2E9',
          borderRadius: '.5rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '25vh',
          position: 'relative',
          background: 'white',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '1rem',
            left: '1rem',
            color: '#E1E2E9',
            fontSize: '1.5rem',
            fontWeight: 'bold',
            cursor: 'pointer',
          }}
        >
          <Button
            onClick={() => goBack({ fallback: '/' })}
            size="small"
            variant="text"
            data-testid="back-button-card-theme"
            sx={{
              textTransform: 'capitalize',
              color: theme.palette.secondary.main,
              display: 'flex',
              gap: '5px',
            }}
          >
            <BackIcon color={theme.palette.secondary.main} />{' '}
            {t('general.home')}
          </Button>
        </Box>
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '1rem',
              marginTop: '1.5rem',
            }}
          >
            {config.coin_logo ? (
              <img
                style={{
                  width: '3rem',
                  height: '3rem',
                  objectFit: 'contain',
                }}
                src={config.coin_logo}
              />
            ) : (
              <HackuPointsIconNew color={theme.palette.primary.main} />
            )}
            <Box>
              <Typography
                fontSize={'28px'}
                fontFamily={'Gotham Rounded'}
                fontWeight={600}
                color={theme.palette.primary.main}
              >
                {points.points} Pts
              </Typography>
              <Typography sx={{ marginTop: '-5px' }} fontSize={'20px'}>
                Total disponible
              </Typography>
            </Box>
          </Box>
          {products.hasProducts ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '1rem',
              }}
            >
              <Button
                component={RouterLink}
                to={'/points/products?hide_navbar=true'}
                variant="contained"
                sx={{
                  width: '200px',
                  borderRadius: '20px',
                  textTransform: 'none',
                }}
              >
                {t('pointsScreen.redemptionPoints')}
              </Button>
            </Box>
          ) : (
            <></>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '1rem',
        }}
      >
        <Button
          onClick={handlePendingContentRedirect}
          variant="outlined"
          sx={{
            width: '200px',
            borderRadius: '20px',
            textTransform: 'none',
            textDecoration: 'none',
            background: 'white',
          }}
        >
          {t('pointsScreen.earnPoints')}
        </Button>
      </Box>
    </>
  );
};

export default Heading;

const HackuPointsIconNew = ({ color }: { color: string }) => {
  const theme = useTheme();
  return (
    <svg
      width="45"
      height="44"
      viewBox="0 0 45 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.4999 41.7083C15.1666 41.7083 9.20825 36.4466 9.20825 29.975V23.1917C9.20825 22.44 9.83159 21.8167 10.5833 21.8167C11.3349 21.8167 11.9583 22.44 11.9583 23.1917C11.9583 27.995 16.4866 31.625 22.4999 31.625C28.5133 31.625 33.0416 27.995 33.0416 23.1917C33.0416 22.44 33.6649 21.8167 34.4166 21.8167C35.1683 21.8167 35.7916 22.44 35.7916 23.1917V29.975C35.7916 36.4466 29.8333 41.7083 22.4999 41.7083ZM11.9583 30.1766C12.0866 35.035 16.7616 38.9583 22.4999 38.9583C28.2383 38.9583 32.9133 35.035 33.0416 30.1766C30.6583 32.7616 26.8816 34.375 22.4999 34.375C18.1183 34.375 14.3599 32.7616 11.9583 30.1766Z"
        fill={color}
      />
      <path
        d="M22.4999 25.2084C17.4399 25.2084 12.8932 22.9351 10.6749 19.2684C9.72156 17.7101 9.20825 15.8951 9.20825 14.0251C9.20825 10.8718 10.6199 7.90175 13.1683 5.66508C15.6616 3.48342 18.9799 2.29175 22.4999 2.29175C26.0199 2.29175 29.3199 3.48342 31.8316 5.64675C34.3799 7.90175 35.7916 10.8718 35.7916 14.0251C35.7916 15.8951 35.2783 17.6917 34.3249 19.2684C32.1066 22.9351 27.5599 25.2084 22.4999 25.2084ZM22.4999 5.04175C19.6399 5.04175 16.9633 5.99508 14.9649 7.75508C13.0216 9.44175 11.9583 11.6784 11.9583 14.0251C11.9583 15.4001 12.3249 16.6834 13.0216 17.8384C14.7632 20.6984 18.3933 22.4584 22.4999 22.4584C26.6066 22.4584 30.2366 20.6801 31.9783 17.8384C32.6933 16.6834 33.0416 15.4001 33.0416 14.0251C33.0416 11.6784 31.9783 9.44175 30.0166 7.71841C28.0183 5.99508 25.3599 5.04175 22.4999 5.04175Z"
        fill={color}
      />
      <path
        d="M22.4999 34.3751C14.9283 34.3751 9.20825 29.5718 9.20825 23.1917V14.0251C9.20825 7.55342 15.1666 2.29175 22.4999 2.29175C26.0199 2.29175 29.3199 3.48342 31.8316 5.64675C34.3799 7.90175 35.7916 10.8718 35.7916 14.0251V23.1917C35.7916 29.5718 30.0716 34.3751 22.4999 34.3751ZM22.4999 5.04175C16.6883 5.04175 11.9583 9.07508 11.9583 14.0251V23.1917C11.9583 27.9951 16.4866 31.6251 22.4999 31.6251C28.5133 31.6251 33.0416 27.9951 33.0416 23.1917V14.0251C33.0416 11.6784 31.9783 9.44175 30.0166 7.71841C28.0183 5.99508 25.3599 5.04175 22.4999 5.04175Z"
        fill={color}
      />
    </svg>
  );
};
