import { create } from 'zustand';
import { ContentItem } from '../hooks/useContents';
type ContentStore = {
  contents: ContentItem[];
  totalPendingContent: number;
  updatePendingContent: (newTotal: number) => void;
  setContents: (value: ContentItem[]) => void;
};

const useContentStore = create<ContentStore>()((set) => ({
  contents: [],
  totalPendingContent: 0,
  updatePendingContent: (value) => set({ totalPendingContent: value }),
  setContents: (value) => set({ contents: value }),
}));

export { useContentStore };
