import { get } from '../services/api';
import { apiUrls } from '../constants/apiUrls';
import { useQuery } from '@tanstack/react-query';

export const usePendingContent = () => {
  const { data, isLoading } = useQuery({
    queryFn: () => get(apiUrls.users.getPedingContent),
    queryKey: ['pendingContent'], // Provide a valid QueryKey value
    enabled: true,
  }) as any;

  return {
    hasPendingContent: data?.data?.pending_contents !== 0,
    pendings: data?.data?.pending_contents,
    isLoading: isLoading
  };
};
