import { Box, Button, Divider, Drawer, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { COLORS, TYPOGRAPHY } from '../../ListContentPage/constants';
import { CloseIcon } from '../../ContentDetailPage/Icons/CloseIcon';

import { CustomThemeValues } from '../../../constants/styles';
import useDescriptionPoints from '../hooks/useDescriptionPoints';

type ContentListDrawer = {
  open: boolean;
  onClose: () => void;
};

export default function InfoDrawer({ open, onClose }: ContentListDrawer) {
  const { t } = useTranslation();
  const { stepsToObtainPoints } = useDescriptionPoints();

  const handleClose = () => {
    onClose();
  };

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: `${CustomThemeValues.BORDER} ${CustomThemeValues.BORDER} 0 0`,
          marginBottom: '1rem',
        },
      }}
    >
      <Box>
        <Box
          sx={{
            paddingTop: '1rem',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div
            onClick={() => onClose()}
            style={{
              height: '10px',
              background: COLORS.GRAY_LIGHT,
              width: '100px',
              borderRadius: CustomThemeValues.BORDER,
            }}
          ></div>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '1rem',
          }}
        >
          <Typography fontFamily={TYPOGRAPHY.bold}>
            {t('pointsScreen.infoDrawerTitle')}
          </Typography>
          <Box
            component={'div'}
            onClick={handleClose}
            sx={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <CloseIcon />
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            minWidth: '300px',
            maxHeight: '500px',
            overflow: 'auto',
            paddingX: '1rem',
            paddingTop: '1rem',
          }}
        >
          <Box>
            {stepsToObtainPoints.map((step, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '1rem 0',
                }}
              >
                <strong style={{ marginRight: '5px' }}>{index + 1}.</strong>{' '}
                {step}
              </Box>
            ))}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            sx={{
              width: '95%',
              marginY: '10px',
              borderRadius: '25px',
              alignSelf: 'center',
              textTransform: 'unset',
              fontSize: '0.9rem',
              position: 'sticky',
              bottom: '5px',
            }}
            size="large"
            data-testId="chooseButton"
            variant="contained"
            onClick={handleClose}
          >
            {t('general.ok')}
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}
