import React, { useCallback, useState } from 'react';

import { get } from '../services/api';
import { MissingDataInfo } from '../pages/ProfileGateway/MissingDataModal';
import { create } from 'zustand';

import { useTranslation } from 'react-i18next';
import { User } from '@/components/AuthInterceptorComponent/hooks/useUser';

type Gateway = {
  gateway: GatewayType;
  url: string;
  is_active: boolean;
  text: string;
  method: string;
};

export const gatewayListEnum = {
  EMAIL: 'EMAIL',
  WHATSAPP: 'WHATSAPP',
  TEAMS: 'TEAMS',
  GOOGLE_CHAT: 'GOOGLE_CHAT',
  SMS: 'SMS',
};

export type GatewayType =
  | 'EMAIL'
  | 'WHATSAPP'
  | 'TEAMS'
  | 'GOOGLE_CHAT'
  | 'SMS';

type ActiveOption = {
  name: string;
  url: string;
  text: string;
  icon: JSX.Element;
};

export const gatewaysStore = create<{
  activeOption: ActiveOption;
  gatewaysElements: Gateway[];
  setActiveOption: (value: ActiveOption) => void;
  setGatewaysElements: (value: Gateway[]) => void;
}>()((set) => ({
  activeOption: {
    icon: <></>,
    name: '',
    text: '',
    url: '',
  },
  gatewaysElements: [],
  setActiveOption: (value: any) => set({ activeOption: value }),
  setGatewaysElements: (value: any) => set({ gatewaysElements: value }),
}));

export const useGateway = (user: User) => {
  const {
    activeOption,
    gatewaysElements,
    setActiveOption,
    setGatewaysElements,
  } = gatewaysStore((state) => state);
  const { t } = useTranslation();
  const [showMissingDataModal, setshowMissingDataModal] = useState(false);
  const [missingDataModalInfo, setMissingDataModalInfo] =
    useState<MissingDataInfo>({
      title: '',
      text: '',
      type: '',
      gateway: 'EMAIL',
      key: '',
    });

  const [open, setOpen] = React.useState(false);

  const getGateways = useCallback(async () => {
    const gateways = await get(`users/${user?.pk}/gateways`);
    if (gateways?.data && gateways.data.length) {
      const elements = gateways.data;
      setGatewaysElements(elements);
    }

    return gateways?.data;
  }, [get]);

  //fn
  const handleOpen = (option: any) => {
    setActiveOption(option);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const getOptions = (gateways: Gateway[]) => {
    const options = gateways.map((element: any) => {
      if (element.gateway === gatewayListEnum.TEAMS) {
        return {
          icon: (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_8209_15617)">
                <path
                  d="M10.5379 5.29813H1.57954C1.16724 5.29813 0.833008 5.63143 0.833008 6.04259V14.9761C0.833008 15.3873 1.16724 15.7206 1.57954 15.7206H10.5379C10.9502 15.7206 11.2844 15.3873 11.2844 14.9761V6.04259C11.2844 5.63143 10.9502 5.29813 10.5379 5.29813Z"
                  stroke="#1F1333"
                  strokeWidth="1.1"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.284 6.78714H15.0167C15.2147 6.78714 15.4046 6.86557 15.5446 7.00519C15.6846 7.1448 15.7632 7.33416 15.7632 7.5316V14.2317C15.7632 15.6138 15.2127 16.9393 14.2326 17.9166C13.2526 18.8939 11.9235 19.443 10.5375 19.443C9.41193 19.4432 8.31633 19.0811 7.41377 18.4104C6.5112 17.7397 5.84992 16.7963 5.52832 15.7207"
                  stroke="#1F1333"
                  strokeWidth="1.1"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.55252 8.27621H4.56641"
                  stroke="#1F1333"
                  strokeWidth="1.1"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.05957 12.7429V8.27612"
                  stroke="#1F1333"
                  strokeWidth="1.1"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15.0166 6.78714H18.3069C18.4242 6.78714 18.5367 6.83361 18.6197 6.91633C18.7026 6.99905 18.7492 7.11125 18.7492 7.22823V12.7428C18.7492 13.5326 18.4346 14.29 17.8746 14.8485C17.3146 15.4069 16.5551 15.7207 15.7631 15.7207C15.6913 15.7207 15.6194 15.7207 15.5485 15.7132"
                  stroke="#1F1333"
                  strokeWidth="1.1"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.95098 5.29849C7.63877 4.75853 7.50434 4.13448 7.56662 3.51429C7.62891 2.8941 7.88477 2.30903 8.29815 1.84153C8.71154 1.37403 9.26161 1.04767 9.87084 0.908437C10.4801 0.769206 11.1178 0.824124 11.694 1.06545C12.2703 1.30678 12.7561 1.72235 13.083 2.2536C13.4099 2.78485 13.5614 3.405 13.5161 4.02666C13.4709 4.64832 13.2312 5.24015 12.8307 5.71874C12.4303 6.19733 11.8894 6.53857 11.2842 6.69436"
                  stroke="#1F1333"
                  strokeWidth="1.1"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.4111 2.99718C13.6688 2.73289 13.988 2.53631 14.3404 2.42497C14.6928 2.31363 15.0673 2.29099 15.4305 2.35907C15.7938 2.42716 16.1345 2.58384 16.4223 2.81516C16.7101 3.04647 16.936 3.34522 17.0799 3.68475C17.2237 4.02429 17.2811 4.39406 17.2468 4.76107C17.2126 5.12809 17.0877 5.48094 16.8835 5.78815C16.6792 6.09537 16.4019 6.3474 16.0763 6.52175C15.7506 6.69611 15.3867 6.78737 15.0171 6.78741"
                  stroke="#1F1333"
                  strokeWidth="1.1"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_8209_15617">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          ),
          name: 'Microsoft Teams',
          checked: element.is_active,
          url: element.url,
          method: element.method,
          text: element.text,
          can_add: element?.can_add ?? false,
          key: gatewayListEnum.TEAMS,
        };
      }
      if (element.gateway === gatewayListEnum.EMAIL) {
        return {
          icon: (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.167 17.7083H5.83366C2.79199 17.7083 1.04199 15.9583 1.04199 12.9167V7.08332C1.04199 4.04166 2.79199 2.29166 5.83366 2.29166H14.167C17.2087 2.29166 18.9587 4.04166 18.9587 7.08332V12.9167C18.9587 15.9583 17.2087 17.7083 14.167 17.7083ZM5.83366 3.54166C3.45033 3.54166 2.29199 4.69999 2.29199 7.08332V12.9167C2.29199 15.3 3.45033 16.4583 5.83366 16.4583H14.167C16.5503 16.4583 17.7087 15.3 17.7087 12.9167V7.08332C17.7087 4.69999 16.5503 3.54166 14.167 3.54166H5.83366Z"
                fill="#1F1333"
              />
              <path
                d="M9.99998 10.725C9.29998 10.725 8.59165 10.5083 8.04998 10.0666L5.44164 7.98331C5.17498 7.76664 5.12498 7.37497 5.34165 7.10831C5.55831 6.84164 5.94998 6.79164 6.21665 7.00831L8.82497 9.09164C9.45831 9.59998 10.5333 9.59998 11.1666 9.09164L13.775 7.00831C14.0416 6.79164 14.4416 6.83331 14.65 7.10831C14.8666 7.37497 14.825 7.77498 14.55 7.98331L11.9416 10.0666C11.4083 10.5083 10.7 10.725 9.99998 10.725Z"
                fill="#1F1333"
              />
            </svg>
          ),
          name: 'Correo Electrónico',
          checked: element.is_active,
          url: element.url,
          method: element.method,
          text: element.text,
          can_add: element?.can_add ?? false,
          key: gatewayListEnum.EMAIL,
        };
      }
      if (element.gateway === gatewayListEnum.WHATSAPP) {
        return {
          icon: (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.5 17.5L3.875 14.3333C1.69848 11.2437 2.16086 7.01725 4.95392 4.47128C7.74698 1.9253 11.9981 1.85525 14.8735 4.30782C17.7489 6.76039 18.3503 10.9693 16.2768 14.129C14.2032 17.2887 10.1026 18.4119 6.70833 16.75L2.5 17.5"
                stroke="#1F1333"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.5 8.33334C7.5 8.56346 7.68655 8.75001 7.91667 8.75001C8.14679 8.75001 8.33333 8.56346 8.33333 8.33334V7.50001C8.33333 7.26989 8.14679 7.08334 7.91667 7.08334C7.68655 7.08334 7.5 7.26989 7.5 7.50001V8.33334C7.5 10.6345 9.36548 12.5 11.6667 12.5H12.5C12.7301 12.5 12.9167 12.3135 12.9167 12.0833C12.9167 11.8532 12.7301 11.6667 12.5 11.6667H11.6667C11.4365 11.6667 11.25 11.8532 11.25 12.0833C11.25 12.3135 11.4365 12.5 11.6667 12.5"
                stroke="#1F1333"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ),
          name: 'Whatsapp',
          checked: element.is_active,
          url: element.url,
          method: element.method,
          text: element.text,
          can_add: element?.can_add ?? false,
          key: 'WHATSAPP',
        };
      }

      if (element.gateway === gatewayListEnum.GOOGLE_CHAT) {
        return {
          icon: (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.51211 0.913727C1.62845 0.913727 0.912109 1.63007 0.912109 2.51373V3.59292V14.6523C0.912109 15.5359 1.62845 16.2523 2.51211 16.2523H4.57551C4.79642 16.2523 4.97551 16.4313 4.97551 16.6523V17.3564C4.97551 18.7908 6.7174 19.5006 7.71987 18.4747L9.77381 16.3727C9.84908 16.2957 9.95222 16.2523 10.0599 16.2523H17.5784C18.462 16.2523 19.1784 15.5359 19.1784 14.6523V6.07958C19.1784 5.66163 19.0148 5.26028 18.7227 4.96135L15.2384 1.3955C14.9373 1.08741 14.5247 0.913727 14.094 0.913727H2.51211ZM2.11211 2.51373C2.11211 2.29281 2.2912 2.11373 2.51211 2.11373H4.97754V5.0719H2.11211V3.59292V2.51373ZM6.17754 5.0719V2.11373H13.917V5.0719H6.17754ZM15.117 5.0719H17.153L15.117 2.98831V5.0719ZM6.17754 12.1003V6.2719H13.917V10.8936H7.73554C7.49324 10.8936 7.26117 10.9913 7.09183 11.1646L6.17754 12.1003ZM4.97754 12.8366L4.97754 12.8374V15.1032C4.84908 15.0699 4.71436 15.0523 4.57551 15.0523H2.51211C2.2912 15.0523 2.11211 14.8732 2.11211 14.6523V12.0938H4.97754V12.8366ZM6.17754 16.4839H6.16676C6.17254 16.5392 6.17551 16.5954 6.17551 16.6523V17.3564C6.17551 17.715 6.61098 17.8925 6.8616 17.636L8.91554 15.534C9.21659 15.2259 9.62915 15.0523 10.0599 15.0523H17.5784C17.7993 15.0523 17.9784 14.8732 17.9784 14.6523V6.2719H15.117V11.1936C15.117 11.6907 14.7141 12.0936 14.217 12.0936H7.86184L7.02253 12.9526L6.52125 13.4656C6.4178 13.5714 6.30036 13.6444 6.17754 13.6881V16.4839ZM4.97754 6.2719V10.8938H2.11211V6.2719H4.97754Z"
                fill="#1F1333"
              />
            </svg>
          ),
          name: 'Google Chat',
          checked: element.is_active,
          url: element.url,
          method: element.method,
          text: element.text,
          can_add: element?.can_add ?? false,
          key: gatewayListEnum.GOOGLE_CHAT,
        };
      }

      if (element.gateway === gatewayListEnum.SMS) {
        return {
          icon: (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.0003 19.0083C9.42533 19.0083 8.88366 18.7167 8.50033 18.2083L7.25033 16.5417C7.22533 16.5083 7.12533 16.4667 7.08366 16.4583H6.66699C3.19199 16.4583 1.04199 15.5167 1.04199 10.8333V6.66666C1.04199 2.98332 2.98366 1.04166 6.66699 1.04166H13.3337C17.017 1.04166 18.9587 2.98332 18.9587 6.66666V10.8333C18.9587 14.5167 17.017 16.4583 13.3337 16.4583H12.917C12.8503 16.4583 12.792 16.4917 12.7503 16.5417L11.5003 18.2083C11.117 18.7167 10.5753 19.0083 10.0003 19.0083ZM6.66699 2.29166C3.68366 2.29166 2.29199 3.68332 2.29199 6.66666V10.8333C2.29199 14.6 3.58366 15.2083 6.66699 15.2083H7.08366C7.50866 15.2083 7.99199 15.45 8.25033 15.7917L9.50033 17.4583C9.79199 17.8417 10.2087 17.8417 10.5003 17.4583L11.7503 15.7917C12.0253 15.425 12.4587 15.2083 12.917 15.2083H13.3337C16.317 15.2083 17.7087 13.8167 17.7087 10.8333V6.66666C17.7087 3.68332 16.317 2.29166 13.3337 2.29166H6.66699Z"
                fill="#1F1333"
              />
              <path
                d="M10.0003 10C9.53366 10 9.16699 9.62501 9.16699 9.16668C9.16699 8.70834 9.54199 8.33334 10.0003 8.33334C10.4587 8.33334 10.8337 8.70834 10.8337 9.16668C10.8337 9.62501 10.467 10 10.0003 10Z"
                fill="#1F1333"
              />
              <path
                d="M13.3333 10C12.8667 10 12.5 9.62501 12.5 9.16668C12.5 8.70834 12.875 8.33334 13.3333 8.33334C13.7917 8.33334 14.1667 8.70834 14.1667 9.16668C14.1667 9.62501 13.8 10 13.3333 10Z"
                fill="#1F1333"
              />
              <path
                d="M6.66634 10C6.19967 10 5.83301 9.62501 5.83301 9.16668C5.83301 8.70834 6.20801 8.33334 6.66634 8.33334C7.12467 8.33334 7.49967 8.70834 7.49967 9.16668C7.49967 9.62501 7.13301 10 6.66634 10Z"
                fill="#1F1333"
              />
            </svg>
          ),
          name: 'SMS',
          checked: element.is_active,
          url: element.url,
          method: element.method,
          text: element.text,
          can_add: element?.can_add ?? false,
          key: gatewayListEnum.GOOGLE_CHAT,
        };
      }

      return {
        icon: <></>,
        name: '',
        checked: element.is_active,
        url: element.url,
        method: element.method,
        text: element.text,
        can_add: element?.can_add ?? false,
        key: 'DEFAULT',
      };
    });

    return options.sort((a, b) => b.checked - a.checked);
  };

  const handleMissingDataModal = (key: GatewayType) => {
    if (key === gatewayListEnum.EMAIL) {
      setMissingDataModalInfo({
        title: t('gateWayScreen.email'),
        text: t('gateWayScreen.noEmail'),
        type: 'email',
        gateway: gatewayListEnum.EMAIL,
        key: gatewayListEnum.EMAIL,
      });
    }
    if (key === gatewayListEnum.WHATSAPP) {
      setMissingDataModalInfo({
        title: 'Whatsapp',
        text: t('gateWayScreen.noWhatsapp'),
        type: 'tel',
        gateway: gatewayListEnum.WHATSAPP,
        key: gatewayListEnum.WHATSAPP,
      });
    }
    if (key === gatewayListEnum.TEAMS) {
      setMissingDataModalInfo({
        title: 'Teams',
        text: t('gateWayScreen.noTeams'),
        type: 'email',
        gateway: gatewayListEnum.TEAMS,
        key: gatewayListEnum.WHATSAPP,
      });
    }

    if (key === gatewayListEnum.GOOGLE_CHAT) {
      setMissingDataModalInfo({
        title: 'Google Chat',
        text: t('gateWayScreen.noGoogle'),
        type: 'email',
        gateway: gatewayListEnum.GOOGLE_CHAT,
        key: key,
      });
    }
    if (key === gatewayListEnum.SMS) {
      setMissingDataModalInfo({
        title: 'SMS',
        text: t('gateWayScreen.noSms'),
        type: 'sms',
        gateway: gatewayListEnum.SMS,
        key: key,
      });
    }
    setshowMissingDataModal(true);
  };

  const hideMissingDataModal = () => {
    setshowMissingDataModal(false);
  };

  return {
    gatewaysElements,
    open,
    activeOption,
    showMissingDataModal,
    missingDataModalInfo,
    getOptions,
    handleClose,
    handleOpen,
    handleMissingDataModal,
    hideMissingDataModal,
    setActiveOption,
    setOpen,
    getGateways,
  };
};
