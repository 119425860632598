import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { CustomThemeValues } from '../../../constants/styles';
import { useRef } from 'react';

type ItemAnswerProps = {
  label: string;
  description: string;
  onChange: (
    pkAnswer: number,
    pkQuestion: number,
    type: string,
    option: string,
    questionIndex: number,
  ) => void;
  currentQuestion: number;
  pk: number;
  type: string;
  answers: any;
  activeQuestion: number;
};

export const ItemAnswer = ({
  description,
  label,
  pk,
  onChange,
  currentQuestion,
  type,
  answers,
  activeQuestion,
}: ItemAnswerProps) => {
  const checkboxStyles = {
    inputProps: { 'aria-label': label, name: label },
  };
  const checkRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (checkRef.current) {
      checkRef.current.click();
    }
  };

  const isChecked = () => {
    return answers.some((answer: any) => answer.response_pk === pk);
  };

  return (
    <Box
      sx={{
        cursor: 'pointer',
        borderRadius: CustomThemeValues.BORDER,
        border: isChecked()
          ? CustomThemeValues.CARD_IS_SELECTED
          : CustomThemeValues.CARD,
        minHeight: '50px',
        paddingLeft: '1rem',
        paddingY: '.5rem',
      }}
      component={'div'}
      onClick={handleClick}
    >
      <FormControlLabel
        control={
          <Checkbox
            {...checkboxStyles}
            checked={answers.some((answer: any) => answer.response_pk === pk)}
            onChange={() => {
              onChange(pk, currentQuestion, type, label, activeQuestion);
            }}
            icon={<RadioButtonUncheckedIcon />}
            checkedIcon={<CheckCircleOutlinedIcon />}
            inputRef={checkRef}
          />
        }
        label={label}
      />

      <Typography fontSize={'12px'}>{description}</Typography>
    </Box>
  );
};
