import { capitalize } from '@/utils/general';
import { Box } from '@mui/material';

export const LearningExprienceItem = ({
  currentCourse,
  title,
  showIcon = true,
  opacity = false,
  icon: Icon,
  onClick,
}: {
  title: string;
  icon: React.ReactNode;
  currentCourse: string;
  showIcon?: boolean;
  opacity?: boolean;
  onClick?: () => void;
}) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        paddingY: '1rem',
        cursor: 'pointer',
        justifyContent: 'space-between',
      }}
    >
      {Icon}
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <div
          style={{
            fontWeight: 'bold',
            fontFamily: 'Gotham Rounded',
            color: opacity ? 'rgba(0, 0, 0, 0.7)' : 'inherit',
          }}
        >
          {capitalize(title)}
        </div>
        <div
          style={{
            fontFamily: 'Gotham Rounded',
            fontSize: '14px',
            color: opacity ? 'rgba(0, 0, 0, 0.5)' : 'inherit',
          }}
        >
          {currentCourse.toLocaleLowerCase()}
        </div>
      </div>
      <div>
        {showIcon && (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.93998 13.78C5.81332 13.78 5.68665 13.7333 5.58665 13.6333C5.39332 13.44 5.39332 13.12 5.58665 12.9267L9.93332 8.58001C10.2533 8.26001 10.2533 7.74001 9.93332 7.42001L5.58665 3.07335C5.39332 2.88001 5.39332 2.56001 5.58665 2.36668C5.77998 2.17335 6.09998 2.17335 6.29332 2.36668L10.64 6.71335C10.98 7.05335 11.1733 7.51335 11.1733 8.00001C11.1733 8.48668 10.9866 8.94668 10.64 9.28668L6.29332 13.6333C6.19332 13.7267 6.06665 13.78 5.93998 13.78Z"
              fill="#352B47"
            />
          </svg>
        )}
      </div>
    </Box>
  );
};
