import { useNavigate as useHistory } from 'react-router-dom';
import { Box, Grid } from '@mui/material';

import BoxFlex from '../../pages/HomePage/components/BoxFlex';
import CardTheme from '../../components/CardTheme';
import Image from '../../components/Image';

import { ErrorStyles } from './Error.styles';

import '../../App.css';
import { useConfig } from '../../hooks/useConfig';
import { useTranslation } from 'react-i18next';
import { useUser } from '@/components/AuthInterceptorComponent/hooks/useUser';

interface ErrorBundary {
  resetError: () => void;
}

const Error: any = ({ resetError }: ErrorBundary) => {
  const { classes } = ErrorStyles();
  const history = useHistory();
  const config = useConfig();
  const { t } = useTranslation();

  const { user } = useUser();
  const photo = user?.profile?.photo;

  const goBack = () => {
    history('/');
    resetError();
  };

  const noImage = () => {
    if (config.hideHackuLogo) {
      return 'og_logo.png';
    } else {
      return 'og_logo_old.png';
    }
  };

  return (
    <div className="content">
      <Grid
        container
        justifyContent="center"
        alignContent="center"
        className={classes.container}
      >
        <Grid item xs={12} md={6} lg={6} className={classes.grid}>
          <Box>
            <CardTheme className={classes.cardTheme}>
              <Image
                route="toolIcon.svg"
                alt="image-tool"
                isStatic
                className={classes.iconTool}
              />
              <Image
                route="closePurpleIcon.svg"
                alt="image-close"
                isStatic
                className={classes.iconClose}
                onClick={goBack}
              />
              <></>
              <Image
                route={photo ? photo : noImage()}
                alt="image-hacku"
                isStatic={!photo}
                className={classes.userImage}
              />
              <BoxFlex width="fit-content" column mx="auto">
                <Box
                  p={1}
                  component="h3"
                  fontWeight={500}
                  m={2}
                  textAlign="center"
                >
                  <p>
                    {user?.first_name},
                    <b className={classes.bold}> {t('errorScreen.sorry')}</b>
                  </p>
                  <p>{t('errorScreen.alwaysTryBetter')}</p>
                </Box>
              </BoxFlex>
              <Image
                route="maintenance.png"
                alt="image-hacku"
                isStatic
                className={classes.errorImage}
              />
              <BoxFlex width="fit-content" column mx="auto" my={'0'}>
                <Box
                  component="h3"
                  fontWeight={600}
                  m={'0px 16px'}
                  textAlign="center"
                  fontFamily="Gotham Rounded Bold"
                >
                  {t('errorScreen.weWillSolveYouProblem')}
                </Box>
              </BoxFlex>
            </CardTheme>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Error;
