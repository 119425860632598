import { useConfig } from '@/hooks/useConfig';
import React from 'react';

type ColorType = 'SHADE' | 'SHADE_FINAL' | 'PRIMARY' | 'SECONDARY';

type StylesType = {
  [key: string]: React.CSSProperties;
};

export function BackgroudGradient() {
  const config = useConfig();
  const enableCustomBackground = config?.data?.colors.length > 2;
  if (!config?.data) {
    return <></>;
  }
  return <>{enableCustomBackground && <Backgroud config={config?.data} />}</>;
}

const Backgroud = ({ config }: { config: any }) => {
  const colors = config?.colors;
  const shadeColors = colors?.filter((color: { color_type: ColorType }) =>
    color.color_type.startsWith('SHADE'),
  );

  const firstColor = shadeColors.find(
    (color: any) => color.color_type === 'SHADE',
  );
  const secondColor = shadeColors.find(
    (color: any) => color.color_type === 'SHADE_FINAL',
  );

  const styles: StylesType = {
    gradient: {
      background: `linear-gradient(230deg, ${firstColor?.color}, ${secondColor?.color})`,
      height: '100vh',
      width: '100vw',
      position: 'fixed',
      zIndex: -101,
      overflow: 'auto',
    },
    whiteRounded: {
      background: firstColor.color,
      borderRadius: '50%',
      height: '500px',
      width: '500px',
      position: 'absolute',
      top: '-350px',
      right: '-350px',
      zIndex: -100,
      opacity: 0.5,
    },
    whiteSquare: {
      background: firstColor.color,
      height: '600px',
      width: '600px',
      position: 'absolute',
      bottom: '-300px',
      left: '-250px',
      borderRadius: '0px 1000px 1000px 1000px',
      zIndex: -100,
      transform: 'rotate(30deg)',
      opacity: 0.5,
    },
  };

  return (
    <div>
      <div style={styles.gradient}>
        <div style={styles.whiteRounded}></div>
        <div style={styles.whiteSquare}></div>
      </div>
    </div>
  );
};
