import { createTheme, buttonClasses } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#572E88',
    },
    secondary: {
      main: '#FF0050',
    },
    shade: {
      main: 'rgb(217, 207, 228)',
    },
    error: { main: '#FF0050' },
    success: { main: '#18E58F' },
    warning: { main: '#F9D10A' },
  },
  typography: {
    fontFamily: ['Gotham Book', 'Gotham Rounded Bold'].join(','),
  },
  components: {
    MuiButtonBase: {
      styleOverrides: {
        root: {
          [`&.${buttonClasses.disabled}`]: {
            opacity: 1,
            backgroundColor: 'rgb(225, 226, 233) !important',
          },
        },
      },
    },
  },
});

export default theme;
