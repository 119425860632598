export const CloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M15.7123 16.773L7.22706 8.28777C6.93714 7.99785 6.93714 7.51702 7.22706 7.22711C7.51697 6.93719 7.9978 6.93719 8.28772 7.22711L16.773 15.7124C17.0629 16.0023 17.0629 16.4831 16.773 16.773C16.4831 17.063 16.0023 17.063 15.7123 16.773Z"
        fill="#1F1333"
      />
      <path
        d="M7.227 16.773C6.93709 16.4831 6.93709 16.0023 7.227 15.7124L15.7123 7.22711C16.0022 6.93719 16.483 6.93719 16.7729 7.22711C17.0629 7.51702 17.0629 7.99785 16.7729 8.28777L8.28766 16.773C7.99775 17.063 7.51692 17.063 7.227 16.773Z"
        fill="#1F1333"
      />
    </svg>
  );
};
