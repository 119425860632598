import { Alert, Snackbar } from '@mui/material';
import { CheckIcon } from '../icons';
import { useConfig } from '../../hooks/useConfig';
import useToast from '../../hooks/useToast';
import { useTranslation } from 'react-i18next';

export function Toast() {
  const config = useConfig();
  const { openToast, setOpenToast } = useToast();
  const { t } = useTranslation();
  return (
    <Snackbar
      open={openToast}
      autoHideDuration={1000}
      onClose={() => setOpenToast(false)}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
    >
      <Alert
        onClose={() => setOpenToast(false)}
        severity="success"
        variant="filled"
        sx={{ width: '100%', textTransform: 'capitalize' }}
        icon={<CheckIcon />}
      >
        {`${config.courseLabel} ${t('changeModulePage.choosedWithSuccess')}`}
      </Alert>
    </Snackbar>
  );
}
