import { makeStyles } from 'tss-react/mui';
//TODO: review this
export const pendingContentFABStyles = makeStyles()((_theme: any) => ({
  playButton: {
    background: _theme.palette.primary.main,
    borderRadius: '20px',
    width: '38px',
    height: '38px',
    padding: '0 0 0 3px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > svg': {
      fill: 'white',
    },
  },
}));
