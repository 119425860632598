export function SelfAssessmentIcon() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0001 18.8999C9.75008 18.8999 9.50008 18.8416 9.29175 18.7249C7.73341 17.8749 4.99175 16.9749 3.27508 16.7499L3.03341 16.7166C1.94175 16.5833 1.04175 15.5583 1.04175 14.4499V4.38326C1.04175 3.72492 1.30008 3.12493 1.77508 2.69159C2.25008 2.25826 2.86675 2.04993 3.51675 2.10826C5.35008 2.24993 8.11675 3.16659 9.68342 4.14993L9.88342 4.26659C9.94175 4.29993 10.0667 4.29993 10.1167 4.27493L10.2501 4.19159C11.8167 3.20826 14.5834 2.27493 16.4251 2.11659C16.4417 2.11659 16.5084 2.11659 16.5251 2.11659C17.1334 2.05826 17.7584 2.27493 18.2251 2.70826C18.7001 3.14159 18.9584 3.74159 18.9584 4.39992V14.4583C18.9584 15.5749 18.0584 16.5916 16.9584 16.7249L16.6834 16.7583C14.9667 16.9833 12.2167 17.8916 10.6917 18.7333C10.4917 18.8499 10.2501 18.8999 10.0001 18.8999ZM3.31675 3.34993C3.05008 3.34993 2.80841 3.44159 2.61675 3.61659C2.40841 3.80826 2.29175 4.08326 2.29175 4.38326V14.4499C2.29175 14.9416 2.71675 15.4166 3.19175 15.4833L3.44175 15.5166C5.31675 15.7666 8.19175 16.7083 9.85841 17.6166C9.93342 17.6499 10.0417 17.6583 10.0834 17.6416C11.7501 16.7166 14.6417 15.7666 16.5251 15.5166L16.8084 15.4833C17.2834 15.4249 17.7084 14.9416 17.7084 14.4499V4.39159C17.7084 4.08326 17.5917 3.81659 17.3834 3.61659C17.1667 3.42493 16.8917 3.33326 16.5834 3.34993C16.5667 3.34993 16.5001 3.34993 16.4834 3.34993C14.8917 3.49159 12.3251 4.34993 10.9251 5.22493L10.7917 5.31659C10.3334 5.59993 9.68342 5.59993 9.24175 5.32493L9.04175 5.20826C7.61675 4.33326 5.05008 3.48326 3.41675 3.34993C3.38341 3.34993 3.35008 3.34993 3.31675 3.34993Z"
        fill="#1F1333"
      />
      <path
        d="M10 18.2C9.65833 18.2 9.375 17.9166 9.375 17.575V5.07495C9.375 4.73328 9.65833 4.44995 10 4.44995C10.3417 4.44995 10.625 4.73328 10.625 5.07495V17.575C10.625 17.925 10.3417 18.2 10 18.2Z"
        fill="#1F1333"
      />
      <path
        d="M6.45825 8.19995H4.58325C4.24159 8.19995 3.95825 7.91662 3.95825 7.57495C3.95825 7.23328 4.24159 6.94995 4.58325 6.94995H6.45825C6.79992 6.94995 7.08325 7.23328 7.08325 7.57495C7.08325 7.91662 6.79992 8.19995 6.45825 8.19995Z"
        fill="#1F1333"
      />
      <path
        d="M7.08325 10.7H4.58325C4.24159 10.7 3.95825 10.4166 3.95825 10.075C3.95825 9.73328 4.24159 9.44995 4.58325 9.44995H7.08325C7.42492 9.44995 7.70825 9.73328 7.70825 10.075C7.70825 10.4166 7.42492 10.7 7.08325 10.7Z"
        fill="#1F1333"
      />
    </svg>
  );
}
