export default function EvaluationIcon() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.00842 14.625C8.85008 14.625 8.69175 14.5666 8.56675 14.4416L7.31675 13.1916C7.07508 12.95 7.07508 12.55 7.31675 12.3083C7.55841 12.0666 7.95842 12.0666 8.20008 12.3083L9.00842 13.1166L11.9001 10.225C12.1417 9.98328 12.5417 9.98328 12.7834 10.225C13.0251 10.4666 13.0251 10.8666 12.7834 11.1083L9.45008 14.4416C9.33342 14.5666 9.16675 14.625 9.00842 14.625Z"
        fill="#1F1333"
      />
      <path
        d="M11.6667 6.12508H8.33341C7.53341 6.12508 6.04175 6.12508 6.04175 3.83341C6.04175 1.54175 7.53341 1.54175 8.33341 1.54175H11.6667C12.4667 1.54175 13.9584 1.54175 13.9584 3.83341C13.9584 4.63341 13.9584 6.12508 11.6667 6.12508ZM8.33341 2.79175C7.50841 2.79175 7.29175 2.79175 7.29175 3.83341C7.29175 4.87508 7.50841 4.87508 8.33341 4.87508H11.6667C12.7084 4.87508 12.7084 4.65841 12.7084 3.83341C12.7084 2.79175 12.4917 2.79175 11.6667 2.79175H8.33341Z"
        fill="#1F1333"
      />
      <path
        d="M12.5 19.4584H7.5C2.81667 19.4584 1.875 17.3084 1.875 13.8334V8.83344C1.875 5.03344 3.25 3.40844 6.63333 3.23344C6.975 3.21677 7.275 3.47511 7.29167 3.82511C7.30833 4.17511 7.04167 4.45844 6.7 4.47511C4.33333 4.60844 3.125 5.31677 3.125 8.83344V13.8334C3.125 16.9168 3.73333 18.2084 7.5 18.2084H12.5C16.2667 18.2084 16.875 16.9168 16.875 13.8334V8.83344C16.875 5.31677 15.6667 4.60844 13.3 4.47511C12.9583 4.45844 12.6917 4.15844 12.7083 3.81677C12.725 3.47511 13.0167 3.20844 13.3667 3.22511C16.75 3.40844 18.125 5.03344 18.125 8.82511V13.8251C18.125 17.3084 17.1833 19.4584 12.5 19.4584Z"
        fill="#1F1333"
      />
    </svg>
  );
}
