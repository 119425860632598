import { post } from '../services/api';
import { MetricsKeys } from '../constants';

type ActionType =
  | 'click-on-button'
  | 'click'
  | 'search'
  | 'click-select'
  | 'download';

type ExtraDataType = {
  path?: string;
  button: string;
};

type Metric = {
  action: ActionType;
  extra_data: ExtraDataType;
};

export const useMetrics = () => {
  const userId = window.localStorage.getItem('user_id');
  const path = window.location.pathname;

  const track = (log: Metric) => {
    post('utils/metrics/', {
      user_pk: userId,
      action: log.action,
      extra_data: {
        button: log.extra_data.button,
        path,
      },
    });
  };

  return { track, MetricsKeys };
};
