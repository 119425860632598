import { Box, Typography } from '@mui/material';
import { EyeIcon } from '../../../components/icons/EyeIcon';
import { formatDistance, isValid } from 'date-fns';
import i18n from '../../../i18n';
import { es, enUS } from 'date-fns/locale';
import { MessageIcon } from '../../../components/icons/MessageIcon';
import { LikeIcon } from '../../../components/icons/LikeIcon';
import EvaluationIcon from '../../../components/icons/EvaluationIcon';
import { SelfAssessmentIcon } from '../../../components/icons/SelfAssessmentIcon';
import { ChallengeTaskIcon } from '../../../components/icons/ChallengeTaskIcon';
import { StreakIcon } from '../../../components/icons/StreakIcon';
import { useTranslation } from 'react-i18next';
import HandShake from '../../../components/icons/HandShake';
import SurveyContentThumbnail from '@/pages/ListContentPage/icons/checkbox.svg?react';

export type Item = {
  action: string;
  created: string;
  instance: string;
  instance_humanize: string;
  points: number;
};

export default function ItemList({ created, instance, points }: Item) {
  const locale = i18n.language === 'es' ? es : enUS;
  const { t } = useTranslation();
  enum ConceptOptions {
    contentDeliveryMetrics = 'ContentDeliveryMetric',
    thread = 'Thread',
    interaction = 'Interaction',
    evaluacionUser = 'EvaluationUser',
    userSelfAssessment = 'UserSelfAssessment',
    challengeTask = 'ChallengeTask',
    manualAssingment = 'ManualAssignment',
    goodStudent = 'GoodStudent',
    userSurvey = 'UserSurvey',
    redemptionPoints = 'TransactionLogs',
  }

  // TODO translate
  const conceptTexts = {
    [ConceptOptions.contentDeliveryMetrics]: t('pointsScreen.viewingContent'),
    [ConceptOptions.thread]: t('pointsScreen.threads'),
    [ConceptOptions.interaction]: t('pointsScreen.likeOrReply'),
    [ConceptOptions.evaluacionUser]: t('pointsScreen.finishEvaluation'),
    [ConceptOptions.userSelfAssessment]: t('pointsScreen.finishSA'),
    [ConceptOptions.challengeTask]: t('pointsScreen.challengePoints'),
    [ConceptOptions.manualAssingment]: t('pointsScreen.manualAssingment'),
    [ConceptOptions.goodStudent]: t('pointsScreen.pointsByStreak'),
    [ConceptOptions.userSurvey]: t('pointsScreen.userSurvey'),
    [ConceptOptions.redemptionPoints]: t(
      'pointsScreen.redemptionPointsSuccess',
    ),
  };

  const conceptIcons = {
    [ConceptOptions.contentDeliveryMetrics]: (
      <EyeIcon size={20} color={'#1F1333'} />
    ),
    [ConceptOptions.thread]: <MessageIcon />,
    [ConceptOptions.interaction]: <LikeIcon />,
    [ConceptOptions.evaluacionUser]: <EvaluationIcon />,
    [ConceptOptions.userSelfAssessment]: <SelfAssessmentIcon />,
    [ConceptOptions.challengeTask]: <ChallengeTaskIcon />,
    [ConceptOptions.manualAssingment]: <HandShake />,
    [ConceptOptions.goodStudent]: <StreakIcon />,
    [ConceptOptions.userSurvey]: <SurveyContentThumbnail />,
    [ConceptOptions.redemptionPoints]: (
      <RedemptionPointsIcon color={'#1F1333'} />
    ),
  };

  /**
   * A description of the entire function.
   *
   * @param {string} status -
   * @return {typeof statusTexts[keyof typeof statusTexts]} description of return value
   */
  const getConcept = (concept: string) => {
    return conceptTexts[concept as keyof typeof conceptTexts];
  };
  const getIcon = (concept: string) => {
    return conceptIcons[concept as keyof typeof conceptIcons];
  };
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginY: '.8rem',
        gap: '.85rem',
      }}
    >
      <div>{getIcon(instance)}</div>
      <div
        style={{
          flex: 1,
        }}
      >
        <Typography variant="h3" fontSize={'.875rem'}>
          {getConcept(instance)}
        </Typography>
        <Typography fontSize={'.75rem'}>
          {isValid(new Date(created))
            ? formatDistance(new Date(created), new Date(), {
                addSuffix: true,
                locale,
              })
            : ''}
        </Typography>
      </div>
      <div
        style={{
          fontSize: '.875rem',
        }}
      >
        {points} pts
      </div>
    </Box>
  );
}

const RedemptionPointsIcon = ({ color = '#572D88' }: { color: string }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 45 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.4999 41.7083C15.1666 41.7083 9.20825 36.4466 9.20825 29.975V23.1917C9.20825 22.44 9.83159 21.8167 10.5833 21.8167C11.3349 21.8167 11.9583 22.44 11.9583 23.1917C11.9583 27.995 16.4866 31.625 22.4999 31.625C28.5133 31.625 33.0416 27.995 33.0416 23.1917C33.0416 22.44 33.6649 21.8167 34.4166 21.8167C35.1683 21.8167 35.7916 22.44 35.7916 23.1917V29.975C35.7916 36.4466 29.8333 41.7083 22.4999 41.7083ZM11.9583 30.1766C12.0866 35.035 16.7616 38.9583 22.4999 38.9583C28.2383 38.9583 32.9133 35.035 33.0416 30.1766C30.6583 32.7616 26.8816 34.375 22.4999 34.375C18.1183 34.375 14.3599 32.7616 11.9583 30.1766Z"
        fill={color}
      />
      <path
        d="M22.4999 25.2084C17.4399 25.2084 12.8932 22.9351 10.6749 19.2684C9.72156 17.7101 9.20825 15.8951 9.20825 14.0251C9.20825 10.8718 10.6199 7.90175 13.1683 5.66508C15.6616 3.48342 18.9799 2.29175 22.4999 2.29175C26.0199 2.29175 29.3199 3.48342 31.8316 5.64675C34.3799 7.90175 35.7916 10.8718 35.7916 14.0251C35.7916 15.8951 35.2783 17.6917 34.3249 19.2684C32.1066 22.9351 27.5599 25.2084 22.4999 25.2084ZM22.4999 5.04175C19.6399 5.04175 16.9633 5.99508 14.9649 7.75508C13.0216 9.44175 11.9583 11.6784 11.9583 14.0251C11.9583 15.4001 12.3249 16.6834 13.0216 17.8384C14.7632 20.6984 18.3933 22.4584 22.4999 22.4584C26.6066 22.4584 30.2366 20.6801 31.9783 17.8384C32.6933 16.6834 33.0416 15.4001 33.0416 14.0251C33.0416 11.6784 31.9783 9.44175 30.0166 7.71841C28.0183 5.99508 25.3599 5.04175 22.4999 5.04175Z"
        fill={color}
      />
      <path
        d="M22.4999 34.3751C14.9283 34.3751 9.20825 29.5718 9.20825 23.1917V14.0251C9.20825 7.55342 15.1666 2.29175 22.4999 2.29175C26.0199 2.29175 29.3199 3.48342 31.8316 5.64675C34.3799 7.90175 35.7916 10.8718 35.7916 14.0251V23.1917C35.7916 29.5718 30.0716 34.3751 22.4999 34.3751ZM22.4999 5.04175C16.6883 5.04175 11.9583 9.07508 11.9583 14.0251V23.1917C11.9583 27.9951 16.4866 31.6251 22.4999 31.6251C28.5133 31.6251 33.0416 27.9951 33.0416 23.1917V14.0251C33.0416 11.6784 31.9783 9.44175 30.0166 7.71841C28.0183 5.99508 25.3599 5.04175 22.4999 5.04175Z"
        fill={color}
      />
    </svg>
  );
};
