import { Box, Button, useTheme } from '@mui/material';
import { Card } from '@/components/@core/card';
import { BackIcon } from '@/components/icons/BackIcon';
import { hexToRgbaWithOpacity } from '@/utils/hexToRgbaWithOpacity';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { get } from '@/services/api';
import LoadingDialog from '@/components/LoadingDialog';
import { LogoutModal } from '@/pages/v2/profile/components/logout-modal';

export const Header = ({
  fullName,
  profileImageUrl,
}: {
  fullName: string;
  profileImageUrl: string;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const [closeSession, setCloseSession] = useState(false);

  const logout = () => {
    setOpenLogoutModal(true);
  };
  const hasProfilePicture = profileImageUrl !== '';
  const withOpacity = hexToRgbaWithOpacity(theme.palette.primary.main, 0.5);
  const handleLogout = async () => {
    setCloseSession(true);
    await get('users/logout');
    setCloseSession(false);
    localStorage.clear();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    window.location.href = `${import.meta.env.VITE_APP_LOGIN_HOST}/logout/`!;
  };

  return (
    <>
      <LoadingDialog open={closeSession} />
      <LogoutModal
        openLogoutModal={openLogoutModal}
        setOpenLogoutModal={setOpenLogoutModal}
        handleLogout={handleLogout}
      />

      <Card>
        <Box
          sx={{
            fontSize: '12px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '15px',
            cursor: 'pointer',
          }}
        >
          <Button
            color="secondary"
            style={{
              display: 'flex',
              gap: '5px',
              alignItems: 'center',
              textTransform: 'capitalize',
            }}
            onClick={() => navigate('/')}
          >
            <BackIcon color={theme.palette.secondary.main} />
            <span>{t('general.home')}</span>
          </Button>
          <div
            onClick={logout}
            style={{
              fontSize: '12px',
              color: theme.palette.secondary.main,
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              gap: '5px',
            }}
          >
            <span>{t('general.logout')}</span>
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.62025 11.135H7.55525C5.33525 11.135 4.26525 10.26 4.08025 8.29999C4.06025 8.09499 4.21025 7.90999 4.42025 7.88999C4.62025 7.86999 4.81025 8.02499 4.83025 8.22999C4.97525 9.79999 5.71525 10.385 7.56025 10.385H7.62525C9.66025 10.385 10.3803 9.66499 10.3803 7.62999V4.36999C10.3803 2.33499 9.66025 1.61499 7.62525 1.61499H7.56025C5.70525 1.61499 4.96525 2.20999 4.83025 3.80999C4.80525 4.01499 4.63025 4.16999 4.42025 4.14999C4.21025 4.13499 4.06025 3.94999 4.07525 3.74499C4.24525 1.75499 5.32025 0.86499 7.55525 0.86499H7.62025C10.0753 0.86499 11.1253 1.91499 11.1253 4.36999V7.62999C11.1253 10.085 10.0753 11.135 7.62025 11.135Z"
                  fill={theme.palette.secondary.main}
                />
                <path
                  d="M7.49957 6.375H1.80957C1.60457 6.375 1.43457 6.205 1.43457 6C1.43457 5.795 1.60457 5.625 1.80957 5.625H7.49957C7.70457 5.625 7.87457 5.795 7.87457 6C7.87457 6.205 7.70457 6.375 7.49957 6.375Z"
                  fill={theme.palette.secondary.main}
                />
                <path
                  d="M2.92473 8.04998C2.82973 8.04998 2.73473 8.01498 2.65973 7.93998L0.984727 6.26498C0.839727 6.11998 0.839727 5.87998 0.984727 5.73498L2.65973 4.05998C2.80473 3.91498 3.04473 3.91498 3.18973 4.05998C3.33473 4.20498 3.33473 4.44498 3.18973 4.58998L1.77973 5.99998L3.18973 7.40998C3.33473 7.55498 3.33473 7.79498 3.18973 7.93998C3.11973 8.01498 3.01973 8.04998 2.92473 8.04998Z"
                  fill={theme.palette.secondary.main}
                />
              </svg>
            </span>
          </div>
        </Box>
        {/* Profile Icon */}
        <div>
          {/* Border */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                width: '60px',
                height: '60px',
                border: '1px solid var(--Grey, #E1E2E9)',
                borderRadius: '8px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {hasProfilePicture ? (
                <img
                  src={profileImageUrl}
                  style={{
                    width: '60px',
                    height: '60px',
                    borderRadius: '8px',
                  }}
                />
              ) : (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.1596 11.62C12.1296 11.62 12.1096 11.62 12.0796 11.62C12.0296 11.61 11.9596 11.61 11.8996 11.62C8.99957 11.53 6.80957 9.25 6.80957 6.44C6.80957 3.58 9.13957 1.25 11.9996 1.25C14.8596 1.25 17.1896 3.58 17.1896 6.44C17.1796 9.25 14.9796 11.53 12.1896 11.62C12.1796 11.62 12.1696 11.62 12.1596 11.62ZM11.9996 2.75C9.96957 2.75 8.30957 4.41 8.30957 6.44C8.30957 8.44 9.86957 10.05 11.8596 10.12C11.9096 10.11 12.0496 10.11 12.1796 10.12C14.1396 10.03 15.6796 8.42 15.6896 6.44C15.6896 4.41 14.0296 2.75 11.9996 2.75Z"
                    fill="#1F1333"
                  />
                  <path
                    d="M7.02825 20.6348L7.02557 20.6331C5.74176 19.7833 5.09961 18.6655 5.09961 17.5C5.09961 16.3337 5.74269 15.2056 7.02682 14.3461C8.42832 13.4168 10.2915 12.9375 12.1771 12.9375C14.0633 12.9375 15.921 13.417 17.3119 14.3458L17.3123 14.346C18.589 15.1972 19.2396 16.3161 19.2396 17.48C19.2396 18.6465 18.5964 19.7747 17.312 20.6342C15.9103 21.5685 14.0522 22.05 12.1696 22.05C10.286 22.05 8.4187 21.568 7.02825 20.6348ZM7.30226 14.774C6.25414 15.4727 5.59961 16.4297 5.59961 17.51C5.59961 18.583 6.26706 19.5392 7.3022 20.226C8.64842 21.1285 10.424 21.5625 12.1696 21.5625C13.9154 21.5625 15.6911 21.1284 17.0374 20.2257C18.0853 19.527 18.7396 18.5702 18.7396 17.49C18.7396 16.4168 18.0719 15.4605 17.0365 14.7736C15.6903 13.8764 13.9149 13.445 12.1696 13.445C10.4241 13.445 8.64845 13.8765 7.30226 14.774Z"
                    fill="#1F1333"
                    stroke="#1F1333"
                  />
                </svg>
              )}
            </Box>
            <span
              style={{
                marginTop: '5px',
                textAlign: 'center',
                fontSize: '14px',
                fontFamily: '"Gotham Rounded"',
              }}
            >
              {fullName}
            </span>
          </Box>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '16px',
          }}
        >
          <Box
            onClick={() =>
              navigate(`/profile/image?image_url=${profileImageUrl}`)
            }
            component={'button'}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              borderRadius: '16px',
              border: `1px solid ${theme.palette.primary.main}`,
              background: withOpacity,
              width: '100px',
              padding: '8px',
              cursor: 'pointer',
            }}
          >
            <span
              style={{
                fontSize: '11px',
                fontWeight: 'bold',
                color: theme.palette.primary.main,
              }}
            >
              {t('general.edit')}
            </span>
          </Box>
        </div>
      </Card>
    </>
  );
};
