import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from '@mui/material';
import { useConfig } from '../../hooks/useConfig';
import { PropsWithChildren, useEffect } from 'react';
import Spinner from '../Spinner';

const PersonalizedTheme = ({ children }: PropsWithChildren) => {
  const config = useConfig(true);

  const [_, setLanguageStore] = useLocalStorage<string>('language', 'es');
  const { i18n } = useTranslation();

  useEffect(() => {
    setLanguageStore(config.language);
    i18n.changeLanguage(config.language);
  }, [config.language]);

  return config.status() === 'ready' ? (
    <ThemeProvider theme={config.theme}>{children}</ThemeProvider>
  ) : (
    <Spinner />
  );
};

export default PersonalizedTheme;
