import { CSSProperties } from 'react';

interface IconProps {
  className?: string;
  fillOne?: string;
  fillTwo?: string;
  fillThree?: string;
  style: CSSProperties;
}

export const BaseIcon = ({
  className,
  style,
  fillOne = '#FF0050',
  fillTwo = '#F9D10A',
  fillThree = '#572E88',
}: IconProps) => {
  return (
    <svg
      style={style}
      className={className}
      width="134"
      height="121"
      viewBox="0 0 134 121"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M96.7756 38.961L99.7431 70.3473L96.4884 95.1441L80.2921 84.4429L40.379 88.2744L32.6913 54.0356L33.4366 8.13639L1.87848 -23.7631L-23.5818 -5.31282L-65.2509 -73.7967L-25.1915 -79.4232L24.8964 -60.2278L55.3557 -32.84L96.7756 38.961Z"
        fill={fillOne}
        fillOpacity="0.6"
      />
      <path
        d="M30.0807 -28.6533L-16.6702 29.3549L-13.0853 36.6018L-85.4574 66.0364L-76.7279 28.4529L-84.3744 -6.07171L-47.8337 -44.404L-90.5258 -114.254L-63.8754 -141.264L-46.7448 -116.901L-11.0886 -96.3415L-6.0126 -58.9395L30.0807 -28.6533Z"
        fill={fillTwo}
      />
      <path
        d="M59.3575 50.8158L50.6426 97.0443L22.8082 102.561L-15.0435 80.8185L-41.8984 31.81L8.83551 -67.2049L48.025 -17.0487L24.3378 0.722232L59.3575 50.8158Z"
        fill={fillThree}
      />
    </svg>
  );
};
