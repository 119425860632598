import { Theme } from '@mui/material/styles';
import { CSSProperties } from 'react';

const image: CSSProperties = {
  position: 'absolute',
  filter: 'blur(60px)',
  width: '200px',
  height: '200px',
  top: '-50px',
  left: '-30px',
};

export const contentProgressModalStyles = (theme: Theme) => ({
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: 'rgba(233,233,233,0.4)',
  },
  paper: {
    borderRadius: '1.3rem',
    padding: '1rem',
    paddingTop: '2rem',
    minWidth: '20rem',
    maxWidth: '25rem',
    overflow: 'hidden',
    marginTop: '0px',
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    width: '9rem',
    height: '2.5rem',
    borderRadius: '1.3rem',
    border: 'none',
    cursor: 'pointer',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    overflow: 'hidden',
    paddingTop: '0.5rem',
  },
  image,
  title: {
    fontSize: '1rem',
    fontFamily: 'Gotham Rounded Bold',
  },
  description: {
    fontWeight: 325,
    fontSize: '0.85rem',
  },
  progressTitle: {
    color: '#838383',
    fontSize: '0.8rem',
    width: '7rem',
    fontFamily: 'Gotham Rounded Bold',
  },
  red: {
    color: '#FF0050',
    fontWeight: 700,
  },
  bold: {
    fontFamily: 'Gotham Rounded Bold',
  },
  textSm: {
    fontSize: '0.85rem',
  },
});
