// this component not have straction in the future apply changes

import React, { useRef } from 'react';
import {
  Grid,
  Card,
  CardContent,
  Button,
  Box,
  GridSpacing,
  SxProps,
  useTheme,
} from '@mui/material';
// icons
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import HackuPoints from '../../theme/assets/hackupoints.svg?react';
// styles
import { cardThemeStyles } from './CardTheme.styles';
// api
// custom hooks
import { usePoints } from '../../hooks/usePoints';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useGoBack } from '../../hooks/useGoBack';
import { useUser } from '@/components/AuthInterceptorComponent/hooks/useUser';
import { BackIcon } from '@/components/icons/BackIcon';

interface CardThemeProps {
  children?: React.ReactNode;
  linkHome?: boolean | null;
  container?: boolean | null;
  closeSession?(): void;
  style?: React.CSSProperties;
  spacing?: GridSpacing;
  welcomeUser?: boolean;
  disabledPadding?: boolean;
  styleRoot?: React.CSSProperties;
  showPoints?: boolean;
  className?: any;
  onClick?(): void;
  duration?: string;
  progress?: number;
  sx?: SxProps;
  customCard?: string;
}

const CardTheme = React.forwardRef(
  (
    {
      children,
      linkHome,
      container,
      closeSession,
      style,
      spacing,
      welcomeUser,
      disabledPadding,
      showPoints,
      styleRoot,
      className,
      customCard,
      onClick,
      duration,
      sx,
    }: //progress = 0,
    CardThemeProps,
    _ref,
  ) => {
    // constants
    const { points } = usePoints();
    const { classes } = cardThemeStyles({ progress: 100 });
    const goBack = useGoBack();
    const { user } = useUser();
    const { t } = useTranslation();
    const myRef = useRef<HTMLDivElement>();
    const theme = useTheme();
    // const location = history.location.pathname;

    const handleOnclick = () => {
      if (onClick) onClick();
    };

    const formatDuration = () => {
      if (duration) {
        const [hours, minutes, seconds] = duration.split(':');
        if (parseInt(hours)) {
          return `${parseInt(hours)} hr`;
        }
        if (parseInt(minutes)) {
          return `${parseInt(minutes)} min`;
        }
        if (parseInt(seconds)) {
          return `${parseInt(seconds)} seg`;
        }
      }
      return '10 min(s)';
    };

    return (
      <Card
        sx={sx && sx}
        className={clsx(classes.root, customCard)}
        ref={() => myRef}
        style={styleRoot}
        onClick={handleOnclick}
      >
        <CardContent
          style={style}
          className={
            disabledPadding ? `${classes.paddingNone} ${className}` : className
          }
        >
          <Box display="flex" justifyContent="space-between" width="100%">
            {linkHome && (
              <Button
                size="small"
                className={classes.buttonNav}
                variant="text"
                onClick={() => goBack({ fallback: '/' })}
                data-testid="back-button-card-theme"
                sx={{
                  display: 'flex',
                  gap: '5px',
                }}
              >
                <BackIcon color={theme.palette.secondary.main} />{' '}
                {t('general.home')}
              </Button>
            )}
            {closeSession && (
              <Button
                size="small"
                variant="outlined"
                color="secondary"
                className={classes.buttonClose}
                onClick={closeSession}
                data-testid="close-session-button-card-theme"
              >
                <PowerSettingsNewIcon
                  style={{ margin: '0 2px 0 0', fontSize: '12pt' }}
                />
                {t('general.closeSession')}
              </Button>
            )}
            {showPoints && (
              <Box
                justifyContent="inherit"
                display="inline-flex"
                alignItems="center"
                marginRight="10px"
                data-testid="points-card-theme-box"
              >
                <HackuPoints className={`${classes.imagePoints}`} />
                <p
                  className={classes.ratingTitleText}
                  data-testid="label-points-card-theme"
                >
                  {points}
                </p>
              </Box>
            )}
          </Box>
          {!welcomeUser && container && (
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              data-testid="grid-container-card-theme-children"
              spacing={spacing ? spacing : 0}
            >
              {children}
            </Grid>
          )}
          {!welcomeUser && !container && children}
          {welcomeUser && (
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              spacing={spacing ? spacing : 0}
            >
              <Grid item xs={12}>
                <Box
                  fontWeight={300}
                  component="h3"
                  mt={1}
                  mb={0}
                  textAlign="center"
                  className={classes.titleWelcome}
                >
                  Hola,
                  <span className={classes.titleWelcomeUser}>
                    {user?.first_name}
                  </span>
                </Box>
                <Box
                  component="p"
                  mt={1}
                  mb={0}
                  textAlign="center"
                  data-testid="welcome-message-card-theme"
                  fontWeight={300}
                >
                  Este es tu espacio de
                  <br />
                  aprendizaje
                </Box>
                <Grid item className={classes.estimatedBox}>
                  <div className={classes.wrapperIconBubble}>
                    <Box className={classes.bubbleIcon}>
                      {/* {progress ? ( */}
                      <svg className={classes.svgProgress}>
                        <circle cx="10" cy="10" r="10"></circle>
                        <circle
                          cx="10"
                          cy="10"
                          r="13"
                          className={classes.outlinedSvg}
                        ></circle>
                      </svg>
                      {/* ) : (
                        ''
                      )} */}
                    </Box>
                  </div>

                  <p
                    className={classes.durationText}
                    style={{ marginLeft: '5px' }}
                  >
                    {formatDuration()}
                  </p>
                </Grid>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
    );
  },
);

export default CardTheme;
