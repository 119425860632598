import { ProductItem } from '@/pages/PointsRedemption/components/product-list/product-item';
import { Heading } from './components/product-list/heading';
import { Box, Grid } from '@mui/material';
import { AppLayout } from '@/components';
import { useProducts } from '@/pages/PointsRedemption/hooks/useProducts';
import { ProductListSkeleton } from '@/pages/PointsRedemption/components/product-list/product-list-skeleton';

type ProductList = {
  id: number;
  brand_name: string;
  name: string;
  sku: string;
  brief_description: string;
  main_price: MainPrice;
  stock: number;
  main_file: string;
};
type MainPrice = {
  id: number;
  hacku_points: number;
  hacku_points_discount: number;
};

export const ProductList = () => {
  const query = useProducts();

  return (
    <AppLayout>
      <Box>
        <Heading />
        <Grid marginTop={'1.5rem'} container spacing={2}>
          {query.isLoading ? (
            <ProductListSkeleton />
          ) : (
            <>
              {query.data.results.map((product: ProductList) => {
                return (
                  <Grid item xs={6}>
                    <ProductItem
                      id={product.id}
                      name={product.name}
                      imageUrl={product.main_file}
                      decription={product.brief_description}
                      price={product.main_price.hacku_points}
                      priceWithDescount={
                        product.main_price.hacku_points_discount
                      }
                      stock={product.stock}
                      brand={product.brand_name}
                    />
                  </Grid>
                );
              })}
            </>
          )}
        </Grid>
      </Box>
    </AppLayout>
  );
};
