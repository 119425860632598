import { QueryKeys } from '@/constants/queryKeys';
import { useProfileAdapter } from '@/hooks';
import { get } from '@/services/api';
import { useQuery } from '@tanstack/react-query';

export const useProfilePage = (enabled = true) => {
  const { profileAdapter } = useProfileAdapter();
  const getProfile = async () => {
    const { data } = await get(`users/:user_pk/profile`);
    const formatted = profileAdapter(data);
    return formatted;
  };

  const gatewaysActive = async () => {
    const { data } = await get(`users/:user_pk/gateways/active`);
    return data;
  };

  const activeGateway = useQuery({
    queryKey: [QueryKeys.ACTIVE_GATEWAY],
    queryFn: gatewaysActive,
    enabled,
    refetchOnWindowFocus: true,
  });
  const query = useQuery({
    queryKey: [QueryKeys.PROFILE],
    queryFn: getProfile,
    enabled: enabled,
  });

  return {
    ...query,
    activeGateway: activeGateway?.data?.gateway,
  };
};
