import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const useAlertStyles = makeStyles()((_theme: Theme) => ({
  iconClose: {
    cursor: 'pointer !important',
    right: '-8px',
    top: '-8px',
    position: 'absolute',
    width: '20px',
  },
  alert: {
    position: 'relative',
  },
}));
