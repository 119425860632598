type Props = {
  color?: string;
  width?: number;
  height?: number;
};

export const BackIcon = ({
  color = '#FF0050',
  width = 5,
  height = 10,
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 5 10"
      fill="none"
    >
      <path
        d="M4.50001 9.33477C4.40501 9.33477 4.31001 9.29977 4.23501 9.22477L0.975015 5.96477C0.445015 5.43477 0.445015 4.56477 0.975015 4.03477L4.23501 0.774766C4.38001 0.629766 4.62001 0.629766 4.76501 0.774766C4.91001 0.919766 4.91001 1.15977 4.76501 1.30477L1.50501 4.56477C1.26501 4.80477 1.26501 5.19477 1.50501 5.43477L4.76501 8.69477C4.91001 8.83977 4.91001 9.07977 4.76501 9.22477C4.69001 9.29477 4.59501 9.33477 4.50001 9.33477Z"
        fill={color}
      />
    </svg>
  );
};
