import { CloseIcon } from '@/pages/ContentDetailPage/Icons/CloseIcon';
import { Card as CustomCard } from '@/components/@core/card';
import { Box, Button, Card, Dialog, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const LogoutModal = ({
  openLogoutModal,
  setOpenLogoutModal,
  handleLogout,
}: {
  openLogoutModal: boolean;
  setOpenLogoutModal: (open: boolean) => void;
  handleLogout: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={openLogoutModal}>
      <Card
        style={{
          padding: '1rem',
        }}
      >
        <IconButton
          aria-label="close"
          onClick={() => setOpenLogoutModal(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <Box
          sx={{
            height: '150px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <CustomCard>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 13.75C11.59 13.75 11.25 13.41 11.25 13V7.75C11.25 7.34 11.59 7 12 7C12.41 7 12.75 7.34 12.75 7.75V13C12.75 13.41 12.41 13.75 12 13.75Z"
                fill="#1F1333"
              />
              <path
                d="M12 17.25C11.73 17.25 11.48 17.15 11.29 16.96C11.2 16.86 11.13 16.75 11.07 16.63C11.02 16.51 11 16.38 11 16.25C11 15.99 11.11 15.73 11.29 15.54C11.66 15.17 12.34 15.17 12.71 15.54C12.89 15.73 13 15.99 13 16.25C13 16.38 12.97 16.51 12.92 16.63C12.87 16.75 12.8 16.86 12.71 16.96C12.52 17.15 12.27 17.25 12 17.25Z"
                fill="#1F1333"
              />
              <path
                d="M10.3021 21.7981L10.3002 21.797L4.3621 18.3681C4.36171 18.3678 4.36132 18.3676 4.36093 18.3674C3.31333 17.7557 2.66016 16.6295 2.66016 15.42V8.57999C2.66016 7.37035 3.31345 6.24402 4.36121 5.6324C4.36151 5.63222 4.36181 5.63205 4.3621 5.63188L10.3002 2.203L10.3021 2.20188C11.3458 1.59307 12.6441 1.59202 13.6998 2.20279L13.7002 2.20299L19.6383 5.63188C19.6385 5.63203 19.6388 5.63219 19.6391 5.63235C20.6869 6.24395 21.3402 7.37032 21.3402 8.57999V15.42C21.3402 16.6295 20.687 17.7558 19.6394 18.3674C19.639 18.3676 19.6386 18.3679 19.6383 18.3681L13.7002 21.797L13.6983 21.7981C13.1791 22.1009 12.5869 22.25 12.0002 22.25C11.4135 22.25 10.8213 22.1009 10.3021 21.7981ZM10.5534 2.63514L10.5534 2.63513L10.5501 2.63699L4.61014 6.06698L4.60896 6.06766C3.7164 6.58632 3.16016 7.54018 3.16016 8.57999V15.42C3.16016 16.4486 3.71539 17.4131 4.60896 17.9323L4.61014 17.933L10.549 21.3623C10.5493 21.3624 10.5495 21.3626 10.5498 21.3628C11.4432 21.8815 12.5549 21.8835 13.4419 21.362C13.4426 21.3616 13.4433 21.3612 13.444 21.3608L19.3802 17.933L19.3814 17.9323C20.274 17.4136 20.8302 16.4598 20.8302 15.42V8.57999C20.8302 7.55134 20.275 6.5869 19.3814 6.06766L19.3802 6.06698L13.4421 2.63809C13.4418 2.63791 13.4415 2.63773 13.4412 2.63755C13.0031 2.38225 12.4962 2.24998 12.0002 2.24998C11.5027 2.24998 10.9978 2.38289 10.5534 2.63514Z"
                fill="#1F1333"
                stroke="#1F1333"
              />
            </svg>
          </CustomCard>
          <Box
            sx={{
              fontSize: '16px',
              textAlign: 'center',
              marginTop: '.5rem',
            }}
          >
            {t('general.confirmationLogOutMessage')}
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: '1rem',
          }}
        >
          <Button
            sx={{
              borderRadius: '32px',
              width: '130px',
              height: '44px',
              padding: '17px 12px',
              backgroundColor: '#625A70',
              color: 'white',
              textTransform: 'capitalize',
              '&:hover': {
                backgroundColor: '#625A70',
                border: '1px solid #625A70',
              },
            }}
            onClick={() => setOpenLogoutModal(false)}
          >
            {t('general.cancel')}
          </Button>

          <Button
            sx={{
              borderRadius: '32px',
              width: '130px',
              height: '44px',
              padding: '17px 12px',
              textTransform: 'capitalize',
              backgroundColor: '#FF1A61',
              color: 'white',
              border: 'none',
              '&:hover': {
                backgroundColor: '#FF1A61',
                border: '1px solid #FF1A61',
              },
            }}
            variant="outlined"
            onClick={handleLogout}
          >
            {t('general.logout')}
          </Button>
        </Box>
      </Card>
    </Dialog>
  );
};
