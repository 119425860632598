import React, { FunctionComponent } from 'react';
import {
  Dialog,
  DialogContent,
  Box,
  LinearProgress,
  DialogTitle,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface LoadingDialogProps {
  open: boolean;
  onClose?(): void;
  message?: string | null;
}

const LoadingDialog: FunctionComponent<LoadingDialogProps> = ({
  open,
  onClose,
  message,
}) => {
  return (
    <Dialog open={open} disableEscapeKeyDown maxWidth="xs" fullWidth>
      {onClose && (
        <DialogTitle sx={{ padding: '.5rem', textAlign: 'end' }}>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      )}
      <DialogContent>
        <Box textAlign="center">
          <LinearProgress />
          <h4>{message ? message : 'Cargando...'}</h4>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default LoadingDialog;
