import { lazy } from 'react';
import { lazyRetry } from '../../../utils/general';

const ContentList = lazy(() => lazyRetry(() => import('./ContentList')));
const ModuleItem = lazy(() => lazyRetry(() => import('./ModuleItem')));
const ModuleList = lazy(() => lazyRetry(() => import('./ModuleList')));
const ContentItem = lazy(() => lazyRetry(() => import('./ContentItem')));
const CustomSelect = lazy(() => lazyRetry(() => import('./CustomSelect')));
const Heading = lazy(() => lazyRetry(() => import('./Heading')));

export {
  ContentList,
  ModuleItem,
  ModuleList,
  ContentItem,
  CustomSelect,
  Heading,
};
