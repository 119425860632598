import { Box, useTheme } from '@mui/material';
import { Card } from '@/components/@core/card';
import { BackIcon } from '@/components/icons/BackIcon';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { dataConfig, useConfig } from '@/hooks/useConfig';

export const Header = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const config = useConfig();
  const { data: configData } = config as dataConfig;

  return (
    <>
      <Card
        style={{
          paddingBottom: '2rem',
        }}
      >
        <Box
          sx={{
            fontSize: '12px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '15px',
            cursor: 'pointer',
          }}
        >
          <span
            style={{
              display: 'flex',
              gap: '5px',
              alignItems: 'center',
              color: theme.palette.secondary.main,
            }}
            onClick={() => navigate(-1)}
          >
            <BackIcon color={theme.palette.secondary.main} />
            <span>{t('general.profile', 'Profile')}</span>
          </span>
          <div></div>
        </Box>
        {/* Profile Icon */}
        <div>
          {/* Border */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                width: '60px',
                height: '60px',
                border: '1px solid var(--Grey, #E1E2E9)',
                borderRadius: '8px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 22.81C11.31 22.81 10.66 22.46 10.2 21.85L8.7 19.85C8.67 19.81 8.55 19.76 8.5 19.75H8C3.83 19.75 1.25 18.62 1.25 13V8C1.25 3.58 3.58 1.25 8 1.25H16C20.42 1.25 22.75 3.58 22.75 8V13C22.75 17.42 20.42 19.75 16 19.75H15.5C15.42 19.75 15.35 19.79 15.3 19.85L13.8 21.85C13.34 22.46 12.69 22.81 12 22.81ZM8 2.75C4.42 2.75 2.75 4.42 2.75 8V13C2.75 17.52 4.3 18.25 8 18.25H8.5C9.01 18.25 9.59 18.54 9.9 18.95L11.4 20.95C11.75 21.41 12.25 21.41 12.6 20.95L14.1 18.95C14.43 18.51 14.95 18.25 15.5 18.25H16C19.58 18.25 21.25 16.58 21.25 13V8C21.25 4.42 19.58 2.75 16 2.75H8Z"
                  fill="#1F1333"
                />
                <path
                  d="M12 12C11.44 12 11 11.55 11 11C11 10.45 11.45 10 12 10C12.55 10 13 10.45 13 11C13 11.55 12.56 12 12 12Z"
                  fill="#1F1333"
                />
                <path
                  d="M16 11.5C15.7194 11.5 15.5 11.2771 15.5 11C15.5 10.7261 15.7261 10.5 16 10.5C16.2739 10.5 16.5 10.7261 16.5 11C16.5 11.2771 16.2806 11.5 16 11.5Z"
                  fill="#1F1333"
                  stroke="#1F1333"
                />
                <path
                  d="M8 12C7.44 12 7 11.55 7 11C7 10.45 7.45 10 8 10C8.55 10 9 10.45 9 11C9 11.55 8.56 12 8 12Z"
                  fill="#1F1333"
                />
              </svg>
            </Box>
            <span
              style={{
                marginTop: '5px',
                textAlign: 'center',
                fontSize: '14px',
                fontFamily: '"Gotham Rounded"',
              }}
            >
              {configData?.custom_profile_sections?.student_profile_learning
                ? `${t('gateWayScreen.learningChannelShort')} ${
                    configData.custom_profile_sections.student_profile_learning
                  }`
                : t('gateWayScreen.learningChannel')}
            </span>
          </Box>
        </div>
      </Card>
    </>
  );
};
