import { CloseIcon } from '@/pages/ContentDetailPage/Icons/CloseIcon';
import { Card as CustomCard } from '@/components/@core/card';
import { Box, Card, Dialog, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TYPOGRAPHY } from '@/pages/ListContentPage/constants';

export const ErrorModal = ({
  open,
  onClose,
  onClick,
  title,
  description,
}: {
  open: boolean;
  title: string;
  description?: string;
  onClose: (open: boolean) => void;
  onClick?: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open}>
      <Card
        style={{
          padding: '1rem',
        }}
      >
        <IconButton
          aria-label="close"
          onClick={() => onClose(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <Box
          sx={{
            height: '350px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <CustomCard>
            <svg
              width="52"
              height="51"
              viewBox="0 0 52 51"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M26 4.25C14.2912 4.25 4.75 13.7912 4.75 25.5C4.75 37.2088 14.2912 46.75 26 46.75C37.7088 46.75 47.25 37.2088 47.25 25.5C47.25 13.7912 37.7088 4.25 26 4.25ZM33.14 30.3875C33.7562 31.0037 33.7562 32.0237 33.14 32.64C32.8212 32.9587 32.4175 33.1075 32.0138 33.1075C31.61 33.1075 31.2063 32.9587 30.8875 32.64L26 27.7525L21.1125 32.64C20.7937 32.9587 20.39 33.1075 19.9862 33.1075C19.5825 33.1075 19.1788 32.9587 18.86 32.64C18.2438 32.0237 18.2438 31.0037 18.86 30.3875L23.7475 25.5L18.86 20.6125C18.2438 19.9962 18.2438 18.9763 18.86 18.36C19.4763 17.7438 20.4962 17.7438 21.1125 18.36L26 23.2475L30.8875 18.36C31.5037 17.7438 32.5237 17.7438 33.14 18.36C33.7562 18.9763 33.7562 19.9962 33.14 20.6125L28.2525 25.5L33.14 30.3875Z"
                fill="#FF1A61"
              />
            </svg>
          </CustomCard>
          <Box
            sx={{
              fontSize: '16px',
              textAlign: 'center',
              marginTop: '.5rem',
              fontFamily: TYPOGRAPHY.bold,
            }}
          >
            {title}
          </Box>
          <Box
            sx={{
              fontSize: '16px',
              textAlign: 'center',
              marginTop: '.5rem',
            }}
          >
            {description}
          </Box>
        </Box>
      </Card>
    </Dialog>
  );
};
