/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FunctionComponent } from 'react';
import {
  Dialog as MuiDialog,
  DialogContent,
  DialogTitle,
  Box,
  IconButton,
} from '@mui/material';
import ClosePurpleIcon from '../../static/closePurpleIcon.svg';

//styles
import { dialogStyles } from './dialog.styles';

interface DialogProps {
  open: boolean;
  handleClose?(param?: any): void;
  children: React.ReactNode;
}

const Dialog: FunctionComponent<DialogProps> = ({
  open,
  handleClose,
  children,
}) => {
  const { classes } = dialogStyles();

  return (
    <MuiDialog
      open={open}
      disableEscapeKeyDown
      maxWidth="sm"
      fullWidth
      onClose={handleClose}
      className={classes.dialog}
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        className={classes.closeButton}
      >
        <img src={ClosePurpleIcon} alt="close" width="35" />
      </IconButton>
      <DialogContent style={{ margin: '50px 0px' }}>{children}</DialogContent>
    </MuiDialog>
  );
};

export default Dialog;
