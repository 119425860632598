import { Links } from '@/components/BottomNavbar';
import { apiUrls } from '@/constants/apiUrls';
import { get } from '@/services/api';
import { useNavigate } from 'react-router-dom';

export const usePendingContentRedirect = () => {
  const navigate = useNavigate();
  const handlePendingContentRedirect = async () => {
    const result = await get(apiUrls.users.pending);
    if (result?.data) {
      if (result.data.includes('/content')) {
        return navigate(Links.PENDING);
      }
      window.location.href = result.data;
    }
  };
  return {
    handlePendingContentRedirect,
  };
};
