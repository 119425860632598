import React, { FunctionComponent } from 'react';
import Coin from '../../static/coin.png';

// interface refDiv {
//   current: HTMLHeadingElement | HTMLDivElement;
//   appendChild: any;
//   removeChild: any;
//   childElementCount: any;
// }

const PointsAnimation: FunctionComponent = ({}) => {
  return (
    <div className="coin-position">
      <div className="push-container"></div>
      <div className="all-emoji">
        <img src={Coin} alt="Coin" className="emoji emoji-1" />
      </div>
    </div>
  );
};

export default PointsAnimation;
