import { CSSProperties } from 'react';
import { ContentIcon } from './ContentIcon';
import './styles.css';
import { t } from 'i18next';

type ProgressBarProps = {
  currentProgress?: number;
  currentTooltipText?: string;
  idealProgress?: number;
  idealTooltipText?: string;
  totalContents?: number;
};

export function ProgressBar({
  currentProgress = 0,
  currentTooltipText = '0',
  idealProgress = 0,
  idealTooltipText = '-',
  totalContents = 100,
}: ProgressBarProps) {
  // const

  const currentPorcentProgress = (currentProgress * 100) / totalContents;
  const idealPorcentProgress = (idealProgress * 100) / totalContents;

  const getTooltipStyles = (progress: number): CSSProperties => {
    return {
      bottom: `calc(${String(progress)}% - 10px)`,
    };
  };

  const getProgressBarStyles = (progress: number): CSSProperties => {
    return {
      height: `${String(progress)}%`,
    };
  };

  return (
    <div className="progress-container">
      <div className="progress">
        <div
          className="progress-bar bar-success"
          style={getProgressBarStyles(idealPorcentProgress)}
        ></div>
        <span
          style={getTooltipStyles(idealPorcentProgress)}
          className="tooltip tooltip-success"
        >
          <span className="tooltip-text left">
            {t('contentScreen.minimalProgress')}
            <br />
            {t('contentScreen.viewedContents')}
          </span>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
            }}
          >
            <ContentIcon />
            {idealTooltipText}
          </div>

          <span
            style={{
              position: 'absolute',
              backgroundColor: '#18e58f',
              borderRadius: '50%',
              width: '15px',
              height: '10px',
              right: '-5px',
              bottom: '7px',
            }}
          />
        </span>
      </div>
      <div className="progress">
        <div
          className="progress-bar bar-red"
          style={getProgressBarStyles(currentPorcentProgress)}
        ></div>
        <span
          className="tooltip tooltip-red"
          style={getTooltipStyles(currentPorcentProgress)}
        >
          <span className="tooltip-text right">
            {t('contentScreen.yourContentProgress')}{' '}
          </span>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
            }}
          >
            <ContentIcon />
            {currentTooltipText}
          </div>

          <span
            style={{
              position: 'absolute',
              backgroundColor: '#ff0050',
              borderRadius: '50%',
              width: '15px',
              height: '10px',
              left: '-5px',
              bottom: '7px',
            }}
          />
        </span>
      </div>
    </div>
  );
}
