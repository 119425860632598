import { useInterceptor } from '@/components/AuthInterceptorComponent/hooks/useInterceptor';
import Spinner from '@/components/Spinner';

export const AuthInterceptorComponent = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { enableRender } = useInterceptor();
  if (!enableRender) {
    return <Spinner />;
  }
  return <div>{children}</div>;
};
