import { ProductCard } from '@/pages/PointsRedemption/components/product-details/product-card';
import { Heading } from './components/product-details/heading';
import { AppLayout } from '@/components';
import { useProductDetail } from '@/pages/PointsRedemption/hooks/useProductDetail';
import { usePoints } from '@/hooks/usePoints';
import { ProductDetailSkeleton } from '@/pages/PointsRedemption/components/product-details/skeleton';

export const ProductDetails = () => {
  const product = useProductDetail();
  const points = usePoints();
  const canRedeem =
    points.points > Number(product?.data?.main_price.hacku_points);
  return (
    <AppLayout>
      <div>
        <Heading />
        {product.isLoading ? (
          <ProductDetailSkeleton />
        ) : (
          <ProductCard
            amount={product.data?.main_price.hacku_points as number}
            brand={product.data?.brand_name as string}
            briefDescription={product.data?.brief_description as string}
            longDescription={product.data?.large_description as string}
            discountAmount={
              product.data?.main_price.hacku_points_discount as number
            }
            gallery={product.data?.files.map((file) => file.file) ?? []}
            name={product.data?.name as string}
            specifications={product.data?.specifications}
            stock={product.data?.stock as number}
            id={product.data?.id as number}
            canRedeem={canRedeem}
          />
        )}
      </div>
    </AppLayout>
  );
};
