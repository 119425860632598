import { useQuery } from '@tanstack/react-query';
import { apiUrls } from '../constants/apiUrls';
import { QueryKeys } from '../constants/queryKeys';
import { get } from '../services/api';

export interface PointsPerActions {
  liked_comment: number;
  commented_capsule: number;
  commented_thread: number;
  finished_capsule: number;
  approved_test: number;
  streak: number;
  knowledge_test_first_chance: number;
  knowledge_test_second_chance: number;
  challenge_task_first_chance: number;
  challenge_task_second_chance: number;
  challenge_task_third_chance: number;
}

/**
 * Retrieves the user's points and points per action from the API.
 *
 * @returns {Object} An object containing the user's points and points per action.
 * @property {number} points - The total number of points.
 * @property {PointsPerActions} points_per_action - The points per action object.
 * @property {number} points_per_action.liked_comment - The points for liked comments.
 * @property {number} points_per_action.commented_capsule - The points for commented capsules.
 * @property {number} points_per_action.finished_capsule - The points for finished capsules.
 * @property {number} points_per_action.approved_test - The points for approved tests.
 * @property {number} points_per_action.streak - The points for streak.
 * @property {number} points_per_action.knowledge_test_first_chance - The points for knowledge test first chance.
 * @property {number} points_per_action.knowledge_test_second_chance - The points for knowledge test second chance.
 */
export const usePoints = (): {
  points: number;
  points_per_action: PointsPerActions;
} => {
  const { data } = useQuery({
    queryKey: [QueryKeys.POINTS],
    queryFn: () => {
      return get(apiUrls.users.points);
    },
  });

  return {
    points: data?.data?.points,
    points_per_action: data?.data?.points_per_action,
  };
};
