import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const imageStyles = makeStyles()((_theme: Theme) => ({
  root: {
    display: 'flex',
    '& > span': {
      display: 'flex !important',
    },
  },
  boxShadow: {
    boxShadow: '1px 1px 10px rgba(0, 0, 0, 0.1)',
  },
}));
