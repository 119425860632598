import { IconProps } from '../../types/Icon';
const LikeIcon = ({ color = '#1F1333', size = 20 }: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 30 22`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.48047 18.3505L10.5805 20.7505C10.9805 21.1505 11.8805 21.3505 12.4805 21.3505H16.2805C17.4805 21.3505 18.7805 20.4505 19.0805 19.2505L21.4805 11.9505C21.9805 10.5505 21.0805 9.35046 19.5805 9.35046H15.5805C14.9805 9.35046 14.4805 8.85046 14.5805 8.15046L15.0805 4.95046C15.2805 4.05046 14.6805 3.05046 13.7805 2.75046C12.9805 2.45046 11.9805 2.85046 11.5805 3.45046L7.48047 9.55046"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M2.38086 18.3504V8.55039C2.38086 7.15039 2.98086 6.65039 4.38086 6.65039H5.38086C6.78086 6.65039 7.38086 7.15039 7.38086 8.55039V18.3504C7.38086 19.7504 6.78086 20.2504 5.38086 20.2504H4.38086C2.98086 20.2504 2.38086 19.7504 2.38086 18.3504Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export { LikeIcon };
