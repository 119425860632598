import { Box, useTheme } from '@mui/material';

export const Subtitle = ({
  text,
  icon,
}: {
  icon: React.ReactNode;
  text: string;
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '10px',
        marginTop: '10px',
        marginBottom: '10px',
      }}
    >
      <div>{icon}</div>
      <div
        style={{
          fontFamily: 'Gotham Rounded',
          color: theme.palette.primary.main,
          fontWeight: 'bold',
        }}
      >
        {text}
      </div>
    </Box>
  );
};
