const redirect = () => {
  // logout
  // remove local storage
  window.localStorage.clear();
  if (import.meta.env.VITE_APP_LOGIN_HOST) {
    if (window.location.href !== import.meta.env.VITE_APP_LOGIN_HOST) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      window.location.href = import.meta.env.VITE_APP_LOGIN_HOST;
    }
  }
};

const handleApiError = () => {
  redirect();
};

export { redirect, handleApiError };
