import { AppLayout } from '@/components/';
import { Header } from './components/header';
import { LearningExperience } from './components/learning-experience';
import { PersonalInformation } from './components/persona-information';
import { Box } from '@mui/material';
import { useProfilePage } from '@/pages/v2/profile/hooks/useProfilePage';
import { format } from 'date-fns';
import { ProfileSkeleton } from '@/pages/v2/profile/components/profile-skeleton';

export const ProfilePage = () => {
  const profile = useProfilePage();
  const formatHour = (hour: string) => {
    const dateString = '2022-01-01T' + hour;
    const formatHour = format(new Date(dateString), 'h:mm a');
    return formatHour;
  };

  if (profile.isLoading) {
    return <ProfileSkeleton />;
  }
  return (
    <>
      <AppLayout>
        {/* Card header */}
        <Header
          fullName={profile.data?.first_name + ' ' + profile.data?.last_name}
          profileImageUrl={profile.data?.profile.photo ?? ''}
        />
        <LearningExperience
          changeDaysAndHours={
            profile.data?.profile.usefull_links?.change_days ?? ''
          }
          coach={profile.data?.coach ?? ''}
          company={profile.data?.company_data?.name ?? ''}
          currentCourse={
            profile.data?.profile.get_current_user_route_name ?? ''
          }
          currentModule={
            profile.data?.profile.get_current_user_module_name ?? ''
          }
          dayToLearn={
            profile.data?.profile?.calendar_formated
              ? profile.data?.profile.calendar_formated
                  .toLocaleString()
                  .replaceAll(',', ', ') +
                ' ' +
                formatHour(profile.data?.profile.hour ?? '')
              : ''
          }
          gatewayOptions={profile.activeGateway ?? ''}
          totalHours={
            String(
              profile.data?.profile.get_user_hours_time_consumed_in_contents,
            ) ?? ''
          }
        />
        <Box
          component={'hr'}
          sx={{
            marginY: '3rem',
          }}
        />
        <PersonalInformation />
      </AppLayout>
    </>
  );
};
