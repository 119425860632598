import { DetailedHTMLProps, InputHTMLAttributes } from 'react';

export const Input = ({
  disabled,
  className,
  style,
  errorText,
  label,
  inputProps,
  isError = false,
  onClick,
}: {
  disabled?: boolean;
  className?: string;
  style?: React.CSSProperties;
  label: string;
  errorText?: string;
  isError?: boolean;
  inputProps?: DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
  onClick?: () => void;
}) => {
  return (
    <div className={className} style={style} onClick={onClick}>
      <label
        htmlFor=""
        style={{
          fontSize: '12px',
          color: '#625A70',
        }}
      >
        {label}
      </label>
      <input
        disabled={disabled}
        type="text"
        style={{
          width: '100%',
          height: '52px',
          padding: '8px 16px',
          borderRadius: '6px',
          border: '1px solid #E1E2E9',
        }}
        {...inputProps}
      />
      {isError && (
        <span style={{ color: 'red', fontSize: '12px' }}>{errorText}</span>
      )}
    </div>
  );
};
