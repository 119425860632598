import { Box } from '@mui/material';
import { ReactNode } from 'react';

const AppLayout = ({ children }: { children: ReactNode }) => {
  return (
    <Box
      width={{
        sm: '100%',
        md: '70%',
      }}
      margin={'auto'}
    >
      {children}
    </Box>
  );
};

export { AppLayout };
