import { useTranslation } from 'react-i18next';
import { usePoints } from '../../../hooks/usePoints';
import { modifyString } from '../../../utils/modifyString';
import { useTheme } from '@mui/material';

export default function useDescriptionPoints() {
  const { t } = useTranslation();
  const { points_per_action } = usePoints();
  const theme = useTheme();

  // TODO refactor this code

  const getDescriptionPointsOne = () => {
    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '1rem',
            width: '100%',
          }}
        >
          <div>{t('homeScreen.step1')}</div>
          <div
            style={{
              color: theme.palette.primary.main,
            }}
          >
            {points_per_action?.finished_capsule} Pts
          </div>
        </div>
      </>
    );
  };

  const getDescriptionPointsTwo = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '1rem',
          width: '100%',
        }}
      >
        <div>{t('homeScreen.step2')}</div>
        <div
          style={{
            color: theme.palette.primary.main,
          }}
        >
          {points_per_action?.commented_capsule} Pts
        </div>
      </div>
    );
  };

  const getDescriptionPointsThree = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '1rem',
          width: '100%',
        }}
      >
        <div>{t('homeScreen.step3')}</div>
        <div
          style={{
            color: theme.palette.primary.main,
          }}
        >
          {points_per_action?.approved_test} Pts
        </div>
      </div>
    );
  };

  const getDescriptionPointsFour = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '1rem',
          width: '100%',
        }}
      >
        <div>{t('homeScreen.step4')}</div>
        <div
          style={{
            color: theme.palette.primary.main,
          }}
        >
          {points_per_action?.liked_comment} Pts
        </div>
      </div>
    );
  };

  const getDescriptionPointsFive = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '1rem',
          width: '100%',
        }}
      >
        <div>{t('homeScreen.step5')}</div>
        <div
          style={{
            color: theme.palette.primary.main,
          }}
        >
          {points_per_action?.streak} Pts
        </div>
      </div>
    );
  };

  const getDescriptionPointsSix = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '1rem',
          width: '100%',
        }}
      >
        <div>{t('homeScreen.step6')}</div>
        <div
          style={{
            color: theme.palette.primary.main,
          }}
        >
          {points_per_action?.knowledge_test_first_chance} Pts
        </div>
      </div>
    );
  };
  const getDescriptionPointsSeven = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '1rem',
          width: '100%',
        }}
      >
        <div>{t('homeScreen.step7')}</div>
        <div
          style={{
            color: theme.palette.primary.main,
          }}
        >
          {points_per_action?.knowledge_test_second_chance} Pts
        </div>
      </div>
    );
  };

  const getDescriptionChallengeFirstChance = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '1rem',
          width: '100%',
        }}
      >
        <div>{t('homeScreen.step8')}</div>
        <div
          style={{
            color: theme.palette.primary.main,
          }}
        >
          {points_per_action?.challenge_task_first_chance} Pts
        </div>
      </div>
    );
  };
  const getDescriptionChallengeSecondChance = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '1rem',
          width: '100%',
        }}
      >
        <div>{t('homeScreen.step9')}</div>
        <div
          style={{
            color: theme.palette.primary.main,
          }}
        >
          {points_per_action?.challenge_task_second_chance} Pts
        </div>
      </div>
    );
  };

  const getDescriptionAnwserComment = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '1rem',
          width: '100%',
        }}
      >
        <div>{t('homeScreen.step10')}</div>
        <div
          style={{
            color: theme.palette.primary.main,
          }}
        >
          {points_per_action?.commented_thread} Pts
        </div>
      </div>
    );
  };

  return {
    stepsToObtainPoints: [
      getDescriptionPointsOne(),
      getDescriptionPointsTwo(),
      getDescriptionPointsThree(),
      getDescriptionPointsFour(),
      getDescriptionPointsFive(),
      getDescriptionPointsSix(),
      getDescriptionPointsSeven(),
      getDescriptionChallengeFirstChance(),
      getDescriptionChallengeSecondChance(),
      getDescriptionAnwserComment(),
    ],
  };
}
