export const EyeIcon = ({
  color = '#572D88',
  size = 16,
}: {
  color?: string;
  size?: number;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
    >
      <path
        d="M7.99995 10.8866C6.40661 10.8866 5.11328 9.59328 5.11328 7.99995C5.11328 6.40661 6.40661 5.11328 7.99995 5.11328C9.59328 5.11328 10.8866 6.40661 10.8866 7.99995C10.8866 9.59328 9.59328 10.8866 7.99995 10.8866ZM7.99995 6.11328C6.95995 6.11328 6.11328 6.95995 6.11328 7.99995C6.11328 9.03995 6.95995 9.88661 7.99995 9.88661C9.03995 9.88661 9.88661 9.03995 9.88661 7.99995C9.88661 6.95995 9.03995 6.11328 7.99995 6.11328Z"
        fill={color}
      />
      <path
        d="M7.99997 14.0135C5.4933 14.0135 3.12664 12.5468 1.49997 10.0002C0.793304 8.90015 0.793304 7.10682 1.49997 6.00015C3.1333 3.45348 5.49997 1.98682 7.99997 1.98682C10.5 1.98682 12.8666 3.45348 14.4933 6.00015C15.2 7.10015 15.2 8.89348 14.4933 10.0002C12.8666 12.5468 10.5 14.0135 7.99997 14.0135ZM7.99997 2.98682C5.84664 2.98682 3.78664 4.28015 2.34664 6.54015C1.84664 7.32015 1.84664 8.68015 2.34664 9.46015C3.78664 11.7201 5.84664 13.0135 7.99997 13.0135C10.1533 13.0135 12.2133 11.7201 13.6533 9.46015C14.1533 8.68015 14.1533 7.32015 13.6533 6.54015C12.2133 4.28015 10.1533 2.98682 7.99997 2.98682Z"
        fill={color}
      />
    </svg>
  );
};
