export const getTheme = (config: any, theme: any) => {
  if (config?.colors?.length) {
    const primary = config.colors.find(
      (color: any) => color.color_type === 'MAIN',
    )?.color;
    const secondary = config.colors.find(
      (color: any) => color.color_type === 'SECONDARY',
    )?.color;
    const shade = config.colors.find(
      (color: any) => color.color_type === 'SHADE',
    )?.color;

    if (primary) theme.palette.primary.main = primary;
    if (secondary) theme.palette.secondary.main = secondary;
    if (shade) theme.palette.shade.main = shade;
  }

  return theme;
};
