import React, { FunctionComponent, useEffect } from 'react';
import { CircularProgress, Box, Skeleton } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { imageStyles } from './Image.styles';
import { useConfig } from '../../hooks/useConfig';
import clsx from 'clsx';
import 'react-lazy-load-image-component/src/effects/blur.css';

interface SkeletonProps {
  width: string;
  height: string;
}

interface StaticImageProps {
  route: string;
  alt: string;
  isStatic?: boolean | null;
  className?: string;
  onClick?(): void;
  style?: React.CSSProperties | undefined;
  align?: 'start' | 'center' | 'end' | 'flex-end' | 'flex-start';
  skeletonProps?: SkeletonProps;
  fulltWidth?: boolean;
  shadow?: boolean;
  blur?: boolean;
}

const Image: FunctionComponent<StaticImageProps> = ({
  route,
  alt,
  isStatic,
  className,
  onClick,
  style,
  align,
  skeletonProps,
  fulltWidth,
  shadow,
  blur,
}) => {
  const [image, setImage]: any = React.useState(null);
  const config = useConfig();
  const { classes } = imageStyles();

  const checkClickAction = (): void => {
    if (onClick) onClick();
  };

  useEffect(() => {
    const importIcon = async () => {
      if (isStatic) {
        try {
          const imagePath = new URL(`../../static/${route}`, import.meta.url)
            .href;
          setImage(imagePath);
        } catch (err) {
          console.error(`Error loading image profile ${err}`);
        }
      } else {
        setImage(route);
      }
    };
    importIcon();
  }, [route, isStatic]);

  const addDefaultSrc = (ev: any) => {
    if (config.hideHackuLogo) {
      return (ev.target.src = '/og_logo.png');
    }
    return (ev.target.src = '/logo-HACKU-color.png');
  };

  if (image) {
    return (
      <Box
        className={classes.root}
        justifyContent={align ? align : 'start'}
        sx={{
          width: fulltWidth ? `${fulltWidth}` : 'auto',
        }}
      >
        <LazyLoadImage
          src={image}
          alt={alt}
          className={clsx(className, shadow && classes.boxShadow)}
          onClick={checkClickAction}
          style={style}
          onError={addDefaultSrc}
          data-testid="image-test"
          effect={blur ? 'blur' : undefined}
          placeholder={
            <Skeleton
              variant="rectangular"
              width={
                skeletonProps && skeletonProps.width
                  ? skeletonProps.width
                  : '30px'
              }
              height={
                skeletonProps && skeletonProps.height
                  ? skeletonProps.height
                  : '30px'
              }
            />
          }
        />
      </Box>
    );
  }
  return <CircularProgress size="1.5em" data-testid="circular-progress-test" />;
};

export default Image;
