import { Box, Skeleton, Divider } from '@mui/material';

export const PointsLogSkeleton = () => {
  return (
    <>
      <Box>
        <Skeleton variant="text" width="100%" height={30} />
        <Skeleton variant="text" width="40%" height={20} />
      </Box>
      <Box>
        <Skeleton variant="text" width="100%" height={30} />
        <Skeleton variant="text" width="40%" height={20} />
      </Box>
      <Box>
        <Skeleton variant="text" width="100%" height={30} />
        <Skeleton variant="text" width="40%" height={20} />
      </Box>
      <Box>
        <Skeleton variant="text" width="100%" height={30} />
        <Skeleton variant="text" width="40%" height={20} />
      </Box>
      <Box>
        <Skeleton variant="text" width="100%" height={30} />
        <Skeleton variant="text" width="40%" height={20} />
      </Box>
      <Box marginBottom={2}>
        <Skeleton variant="text" width="100%" height={30} />
        <Skeleton variant="text" width="40%" height={20} />
      </Box>
      <Divider />
      <Box marginTop={2}>
        <Skeleton variant="text" width="100%" height={30} />
        <Skeleton variant="text" width="40%" height={20} />
      </Box>
      <Box>
        <Skeleton variant="text" width="100%" height={30} />
        <Skeleton variant="text" width="40%" height={20} />
      </Box>
      <Box>
        <Skeleton variant="text" width="100%" height={30} />
        <Skeleton variant="text" width="40%" height={20} />
      </Box>
      <Box>
        <Skeleton variant="text" width="100%" height={30} />
        <Skeleton variant="text" width="40%" height={20} />
      </Box>
      <Box>
        <Skeleton variant="text" width="100%" height={30} />
        <Skeleton variant="text" width="40%" height={20} />
      </Box>
    </>
  );
};
