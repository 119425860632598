export function ChallengeTaskIcon() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.29175 19.4582C3.95008 19.4582 3.66675 19.1748 3.66675 18.8332V2.1665C3.66675 1.82484 3.95008 1.5415 4.29175 1.5415C4.63341 1.5415 4.91675 1.82484 4.91675 2.1665V18.8332C4.91675 19.1748 4.63341 19.4582 4.29175 19.4582Z"
        fill="#1F1333"
      />
      <path
        d="M13.6251 14.4585H4.29175C3.95008 14.4585 3.66675 14.1752 3.66675 13.8335C3.66675 13.4918 3.95008 13.2085 4.29175 13.2085H13.6251C14.5334 13.2085 14.9584 12.9668 15.0417 12.7585C15.1251 12.5502 15.0001 12.0835 14.3501 11.4418L13.3501 10.4418C12.9417 10.0835 12.6917 9.54183 12.6667 8.94183C12.6417 8.3085 12.8917 7.6835 13.3501 7.22516L14.3501 6.22516C14.9667 5.6085 15.1584 5.1085 15.0667 4.89183C14.9751 4.67516 14.5001 4.4585 13.6251 4.4585H4.29175C3.94175 4.4585 3.66675 4.17516 3.66675 3.8335C3.66675 3.49183 3.95008 3.2085 4.29175 3.2085H13.6251C15.4501 3.2085 16.0334 3.96683 16.2251 4.41683C16.4084 4.86683 16.5334 5.81683 15.2334 7.11683L14.2334 8.11683C14.0251 8.32516 13.9084 8.61683 13.9167 8.9085C13.9251 9.1585 14.0251 9.3835 14.2001 9.54183L15.2334 10.5668C16.5084 11.8418 16.3834 12.7918 16.2001 13.2502C16.0084 13.6918 15.4167 14.4585 13.6251 14.4585Z"
        fill="#1F1333"
      />
    </svg>
  );
}
