import { apiUrls } from '@/constants/apiUrls';
import { QueryKeys } from '@/constants/queryKeys';
import { useConfig } from '@/hooks/useConfig';
import { get } from '@/services/api';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

export interface ProductDetail {
  id: number;
  brand_name: string;
  name: string;
  sku: string;
  brief_description: string;
  main_price: MainPrice;
  stock: number;
  files: File[];
  large_description: string;
  specifications: Specification[];
}

export interface File {
  id: number;
  file: string;
  ordering: number;
}

export interface MainPrice {
  id: number;
  hacku_points: number;
  hacku_points_discount: number;
}

export interface Specification {
  name: string;
  value: string;
  ordering: number;
}

export const useProductDetail = () => {
  const config = useConfig();
  const { id } = useParams();

  const getProductDetails = async () => {
    const { data } = await get(
      apiUrls.products.details({
        company_slug: config.slug_name,
        product_id: Number(id),
      }),
    );

    return data as ProductDetail;
  };

  const query = useQuery({
    queryKey: [QueryKeys.PRODUCT_DETAIL, id],
    queryFn: getProductDetails,
  });

  return {
    ...query,
  };
};
