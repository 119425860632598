type ContentIconProps = {
  width?: string | number;
  height?: string | number;
  fill?: string;
};

export const ContentIcon = ({
  width = '11',
  height = 12,
  fill = 'black',
}: ContentIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.91301 0.0450401C5.31955 0.154942 4.67113 0.627516 4.33044 1.199C4.07767 1.6386 4.0447 1.79247 4.08866 2.49583C4.12163 3.16623 4.17658 3.36405 4.47331 3.79266C5.49539 5.26534 7.86925 5.01257 8.58361 3.36405C8.80341 2.84752 8.86935 1.74851 8.68252 1.6386C8.62757 1.60563 8.57262 1.48474 8.57262 1.37484C8.57262 1.11108 7.90222 0.429694 7.44064 0.220882C6.96806 0.0120702 6.40757 -0.0538707 5.91301 0.0450401ZM7.41866 0.825337C7.96816 1.199 8.24292 1.70455 8.24292 2.36395C8.24292 3.23217 7.75935 3.90257 6.91311 4.18831C6.38559 4.37514 6.23173 4.36415 5.66024 4.10039C4.814 3.70474 4.41836 2.8695 4.62717 1.94633C4.90193 0.693456 6.36361 0.110981 7.41866 0.825337Z"
        fill={fill}
      />
      <path
        d="M5.74834 1.33143C5.64943 1.3644 5.60547 1.68312 5.60547 2.35351C5.60547 3.66133 5.6824 3.71628 6.69349 3.10084C7.72656 2.4744 7.72656 2.30955 6.71547 1.7051C6.29784 1.46331 5.93517 1.2545 5.91319 1.26549C5.89121 1.26549 5.81428 1.29846 5.74834 1.33143ZM6.53963 2.1447C6.96824 2.39747 6.95725 2.40846 6.3528 2.74915L6.04507 2.91401V2.40846C6.04507 2.13371 6.06705 1.91391 6.08903 1.91391C6.122 1.91391 6.31983 2.02381 6.53963 2.1447Z"
        fill={fill}
      />
      <path
        d="M9.78119 4.62793C9.69327 4.6609 9.29762 5.00159 8.91297 5.38625C8.28653 6.02367 8.19861 6.06763 7.97881 5.94674C7.77 5.82585 5.41812 5.65001 3.92346 5.63902C3.23109 5.63902 2.31891 6.05664 1.93426 6.56219C1.76941 6.771 1.51663 6.97981 1.37376 7.01278C1.06604 7.08971 0 8.15575 0 8.39753C0 8.61733 2.41782 11.0352 2.63762 11.0352C2.90139 11.0352 4.06634 9.80427 4.06634 9.52951C4.06634 9.24377 4.31911 8.96902 4.64881 8.89209C4.78069 8.85912 5.69287 8.82615 6.67099 8.81516L8.45138 8.78219L8.92396 8.27664C9.18772 8.00189 9.57237 7.56229 9.78119 7.30951C10.3527 6.61714 10.5395 6.38634 10.7813 6.11159C11.1 5.73793 11.067 5.06753 10.7044 4.78179C10.4186 4.551 10.1109 4.50704 9.78119 4.62793ZM10.4846 5.24337C10.5725 5.38625 10.5615 5.49615 10.4406 5.69397C10.1988 6.07862 9.11079 7.38644 8.58327 7.92496L8.13267 8.39753H6.34129C4.7697 8.39753 4.50594 8.41951 4.12129 8.61733L3.68168 8.83714L2.86842 8.01288L2.04416 7.19961L2.26396 6.89189C2.38485 6.72704 2.6596 6.49625 2.86842 6.37535C3.28604 6.13357 3.6597 6.12258 6.25337 6.27644C7.5502 6.35337 7.85792 6.39734 7.95683 6.5512C8.04475 6.69407 8.02277 6.79298 7.86891 6.95783C7.68208 7.15565 7.52822 7.18862 6.62703 7.18862C5.80277 7.18862 5.60495 7.22159 5.60495 7.35347C5.60495 7.58427 6.04455 7.6612 7.04465 7.60625C7.96782 7.5513 8.35247 7.35347 8.35247 6.93585C8.35247 6.67209 9.99 4.96862 10.1988 5.01258C10.2867 5.03456 10.4186 5.13347 10.4846 5.24337ZM2.69257 8.50743L3.68168 9.49654L3.18713 9.98011L2.69257 10.4747L1.59356 9.44159L0.494554 8.40852L0.934158 7.96892C1.16495 7.71615 1.4397 7.51832 1.53861 7.51832C1.62653 7.51832 2.15406 7.96892 2.69257 8.50743Z"
        fill={fill}
      />
    </svg>
  );
};
