import axios, { AxiosHeaders, RawAxiosRequestHeaders } from 'axios';

const BASE_URL = import.meta.env.VITE_APP_BASE_URL;

export interface ResponseApiProps {
  status: number;
  success: boolean;
  data?: any;
  error?: any;
}

export function getCookie(cookieName: string) {
  const name = cookieName + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');

  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return '';
}

axios.interceptors.request.use((config) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  config.cancelToken = source.token;

  // if (store) {
  //   const account: any = store.getState().account;
  //   if (account) {
  const pattern: any = new RegExp(/\?.+=.*/g);
  if (
    !pattern.test(config.url) &&
    config.url?.charAt(config.url?.length - 1) !== '/'
  ) {
    config.url = `${config.url}/`;
  } else {
    const positionCharParam: any = config.url?.indexOf('?');
    if (
      positionCharParam > -1 &&
      config.url?.charAt(positionCharParam - 1) !== '/'
    ) {
      config.url = config.url?.replace('?', '/?');
    }
  }
  const userId = window.localStorage.getItem('user_id') || null;
  const accessToken = window.localStorage.getItem('access_token') || null;

  if (
    !config.url?.includes('users/magic-link-checker/') &&
    !config.url?.includes('v2/users/')
  ) {
    if (!Boolean(userId)) {
      source.cancel('Request canceled.');
    }
  }

  config.headers['Accept-Language'] =
    window.localStorage.getItem('language') || 'es';

  if (userId) {
    config.url = config.url?.replaceAll(':user_pk', userId);
  }
  if (accessToken) {
    config.headers['Authorization'] = `Token ${accessToken}`;
  } else if (getCookie('auth_token')) {
    config.headers['Authorization'] = `Token ${getCookie('auth_token')}`;
  }
  // }
  // }
  return config;
});

const handleResponse = (response: any): ResponseApiProps => {
  const responseObj = {
    status: 500,
    success: false,
    error: null,
    data: null,
  };
  if (response && response.status) {
    responseObj.status = response.status;
  } else if (response && response.response) {
    responseObj.status = response.response.status;
  }
  responseObj.success =
    responseObj.status === 200 || responseObj.status === 201;

  if (responseObj.status !== 200 && responseObj.status !== 201) {
    if (response) {
      if (response.response) {
        const errorHandler: any = response.response;
        responseObj.error = errorHandler.data;
        if (errorHandler.status === 403 || errorHandler.status === 401) {
          // logout here remove id and access_token
          window.localStorage.clear();
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          window.location.href = import.meta.env.VITE_APP_LOGIN_HOST!;
        }
      } else if (response.message) {
        responseObj.error = response.message;
      }
    }
  }

  responseObj.data =
    responseObj.status === 200 || responseObj.status === 201
      ? response.data || response.result
      : null;
  return responseObj;
};

interface requestProps {
  resource: string;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  data?: Record<string, unknown>;
  headers?: AxiosHeaders;
  isOutput: boolean;
}

export const request = async ({
  resource,
  method,
  data,
  headers,
  isOutput,
}: requestProps) => {
  const isQueryGet = method === 'GET';
  return await axios
    .request({
      url: isOutput ? resource : `${BASE_URL}/${resource}`,
      method,
      data: isQueryGet ? {} : data,
      params: isQueryGet ? data : null,
      headers,
    })
    .then(handleResponse)
    .catch(handleResponse);
};

export const get = async (
  resource: string,
  headers?: RawAxiosRequestHeaders,
): Promise<ResponseApiProps> => {
  return await axios
    .get(`${BASE_URL}/${resource}`, { headers, timeout: 30000 })
    .then(handleResponse)
    .catch(handleResponse);
};

export const post = async (
  resource: string,
  data?: Record<string, unknown> | null,
  headers?: RawAxiosRequestHeaders,
) => {
  return axios
    .post(`${BASE_URL}/${resource}`, data, { headers })
    .then(handleResponse)
    .catch(handleResponse);
};

export const put = async (
  resource: string,
  data: Record<string, unknown>,
  headers?: RawAxiosRequestHeaders,
) => {
  return await axios
    .put(`${BASE_URL}/${resource}`, data, { headers })
    .then(handleResponse)
    .catch(handleResponse);
};

export const patch = async (
  resource: string,
  data: Record<string, unknown>,
  headers?: RawAxiosRequestHeaders,
) => {
  return await axios
    .patch(`${BASE_URL}/${resource}`, data, { headers })
    .then(handleResponse)
    .catch(handleResponse);
};

export const remove = async (
  resource: string,
  data?: Record<string, unknown>,
  headers?: RawAxiosRequestHeaders,
) => {
  return await axios
    .delete(`${BASE_URL}/${resource}`, { data, headers })
    .then(handleResponse)
    .catch(handleResponse);
};
