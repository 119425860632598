export function MessageIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={21} fill="none">
      <path
        fill="#1F1333"
        d="M14.892 18.725c-.259 0-.517-.067-.75-.208L10.8 16.533c-.35-.008-.7-.033-1.033-.083a.634.634 0 0 1-.5-.4.61.61 0 0 1 .108-.625c.55-.642.833-1.408.833-2.225 0-2.017-1.775-3.658-3.958-3.658-.817 0-1.6.225-2.258.658a.632.632 0 0 1-.967-.45 6.6 6.6 0 0 1-.042-.708c0-4.134 3.584-7.492 7.984-7.492s7.983 3.358 7.983 7.492c0 2.266-1.05 4.35-2.9 5.775l.283 2.266a1.46 1.46 0 0 1-1.442 1.642Zm-3.934-3.45a.534.534 0 0 1 .334.092l3.491 2.075a.185.185 0 0 0 .217 0 .202.202 0 0 0 .092-.209l-.325-2.633a.645.645 0 0 1 .258-.592C16.725 12.817 17.7 11 17.7 9.025c0-3.442-3.017-6.242-6.733-6.242-3.575 0-6.509 2.6-6.725 5.867a5.538 5.538 0 0 1 2-.375c2.875 0 5.208 2.2 5.208 4.908a4.544 4.544 0 0 1-.492 2.092Z"
      />
      <path
        fill="#1F1333"
        d="M3.816 19.458a1.11 1.11 0 0 1-.616-.183 1.142 1.142 0 0 1-.525-1.1l.166-1.283c-1.125-.917-1.791-2.275-1.791-3.7 0-1.625.85-3.142 2.275-4.05a5.37 5.37 0 0 1 2.933-.859c2.875 0 5.208 2.2 5.208 4.909 0 1.1-.4 2.183-1.133 3.041-.942 1.142-2.35 1.809-3.9 1.859L4.4 19.3a1.139 1.139 0 0 1-.584.158ZM6.25 9.533c-.817 0-1.6.225-2.259.659-1.066.683-1.7 1.8-1.7 3 0 1.158.567 2.216 1.567 2.9a.633.633 0 0 1 .267.591l-.184 1.425 1.992-1.183a.632.632 0 0 1 .317-.092c1.225 0 2.383-.525 3.116-1.416.55-.65.842-1.417.842-2.234 0-2.008-1.775-3.65-3.958-3.65Z"
      />
    </svg>
  );
}
