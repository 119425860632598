import { PropsWithChildren } from 'react';
import * as Sentry from '@sentry/react';
import { Error } from '../pages/Error';

const ErrorBoundary = ({ children }: PropsWithChildren) => {
  Sentry.setContext('userInfo', {
    route: window.location.pathname,
  });

  return (
    <Sentry.ErrorBoundary
      onError={(error) =>
        import.meta.env.VITE_APP_ENVIRONMENT === 'local' && console.error(error)
      }
      fallback={({ resetError, error }) => {
        if (error) return <Error resetError={resetError} />;
        return <></>;
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
