export function BurgerIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M10.0001 5.83333C9.07961 5.83333 8.33341 5.08714 8.33341 4.16667C8.33341 3.24619 9.07961 2.5 10.0001 2.5C10.9206 2.5 11.6667 3.24619 11.6667 4.16667C11.6667 5.08714 10.9206 5.83333 10.0001 5.83333Z"
        fill="#572D88"
      />
      <path
        d="M10.0001 11.6667C9.07961 11.6667 8.33341 10.9205 8.33341 10C8.33341 9.07952 9.07961 8.33333 10.0001 8.33333C10.9206 8.33333 11.6667 9.07952 11.6667 10C11.6667 10.9205 10.9206 11.6667 10.0001 11.6667Z"
        fill="#572D88"
      />
      <path
        d="M10.0001 17.5C9.07961 17.5 8.33341 16.7538 8.33341 15.8333C8.33341 14.9129 9.07961 14.1667 10.0001 14.1667C10.9206 14.1667 11.6667 14.9129 11.6667 15.8333C11.6667 16.7538 10.9206 17.5 10.0001 17.5Z"
        fill="#572D88"
      />
    </svg>
  );
}
