function hexToRgbaWithOpacity(hexColor: string, opacity: number): string {
  // Validate if the input color is a valid HEX color
  if (!/^#([0-9A-Fa-f]{3}){1,2}$/.test(hexColor)) {
    console.error('Invalid HEX color');
    return '';
  }

  // Remove the '#' symbol at the beginning of the HEX color
  hexColor = hexColor.slice(1);

  // Convert the HEX color to RGB components
  const r = parseInt(hexColor.slice(0, 2), 16);
  const g = parseInt(hexColor.slice(2, 4), 16);
  const b = parseInt(hexColor.slice(4, 6), 16);

  // Ensure opacity is in the range [0, 1]
  opacity = Math.min(1, Math.max(0, opacity));

  // Create the RGBA color
  const rgbaColor = `rgba(${r}, ${g}, ${b}, ${opacity})`;

  return rgbaColor;
}

export { hexToRgbaWithOpacity };
