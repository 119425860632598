import { useCallback, useEffect, useState } from 'react';
import { get } from '../services/api';
import { SimpleModule } from '../pages/ListContentPage/components/ModuleList';
import { create } from 'zustand';

// Generated by https://quicktype.io

export interface IModulesResponse {
  count: number;
  next: null;
  previous: null;
  results: ResultsModules[];
}

export type ResultsModules = {
  ordering: number;
  content_module: ContentModule;
  progress_percentage: null | number;
  extra_data: ExtraData;
  is_active: boolean;
};

type ExtraData = null | {
  sent: number;
  total: number;
  viewed: number;
};

export type ContentModule = {
  pk: number;
  slug_name: string;
  name: string;
  description: string;
  minutes_duration: number;
  total_contents: number;
};

type Metadata = {
  count: number;
  next: null;
  previous: null;
};

export const modulesStore = create<{
  isLoading: boolean;
  selectedModule: number;
  modules: ResultsModules[];
  udpateSelectedModule: (value: number) => void;
  setModules: (value: ResultsModules[]) => void;
  setLoading: (value: boolean) => void;
}>()((set) => ({
  isLoading: true,
  selectedModule: 0,
  modules: [],
  udpateSelectedModule: (value) => set({ selectedModule: value }),
  setModules: (value) => set({ modules: value }),
  setLoading: (value) => set({ isLoading: value }),
}));

type UseModuleReturn = {
  data: ResultsModules[];
  metadata: Metadata;
  isLoading: boolean;
  selectedModule: number;
  getActiveModule: () => SimpleModule | null;
  getModules: (routedId: number) => Promise<void>;
  listModulesFormatter: (modules: ResultsModules[]) => SimpleModule[];
  getCompletedModulesCount: () => number;
  udpateSelectedModule: (value: number) => void;
};

/**
 * The `useModules` function is a custom hook in TypeScript that manages the state and logic related to
 * modules in an application.
 * @returns The `useModules` function returns an object with the following properties and methods:
 * - data
 * - metadata
 * - isLoading
 * - selectedModule
 * - currentModule
 * - getModules
 * - listModulesFormatter
 * - getCompletedModulesCount
 * - udpateSelectedModule
 */
export const useModules = (enableEvilSideEffect: boolean): UseModuleReturn => {
  const {
    selectedModule,
    udpateSelectedModule,
    setModules,
    modules,
    setLoading,
    isLoading,
  } = modulesStore((state) => state);

  const [metadata, setMetadata] = useState<Metadata>({
    count: 0,
    next: null,
    previous: null,
  });

  const getActiveModule = (): SimpleModule => {
    return listModulesFormatter(modules).filter(
      (item) => item.isActive === true,
    )[0];
  };

  const getModules = useCallback(async (routedId: number) => {
    setLoading(true);
    if (routedId === 0) {
      const data: IModulesResponse = {
        results: [],
        count: 0,
        next: null,
        previous: null,
      };
      setModules(data?.results);
      setMetadata({
        count: data.count,
        next: data.next,
        previous: data.previous,
      });
      setLoading(false);
      return;
    }
    try {
      const response = await get(`users/:user_pk/routes/${routedId}/v2`);
      if (response.status !== 200) return;
      const data: IModulesResponse = response.data;
      setModules(data?.results);
      setMetadata({
        count: data.count,
        next: data.next,
        previous: data.previous,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const listModulesFormatter = (modules: ResultsModules[]): SimpleModule[] => {
    return modules.map((item) => {
      const contentsViewed = item.extra_data?.viewed ?? 0;
      const totalSentContents = item?.extra_data?.sent ?? 0;
      const totalContents = item.content_module.total_contents;

      return {
        description: item.content_module.description,
        isActive: item.is_active,
        unlocked: item.progress_percentage === 100,
        minutesDuraction: item.content_module.minutes_duration,
        name: item.content_module.name,
        orderIntoRute: item.ordering,
        id: item.content_module.pk,
        progressPercentage: item.progress_percentage,
        totalContents,
        contentsViewed,
        pendingContents: totalSentContents - contentsViewed,
      };
    });
  };

  const getCompletedModulesCount = () => {
    return modules.filter((item) => item.progress_percentage === 100).length;
  };

  const evilSideEffect = () => {
    let selected_module: any = new window.URLSearchParams(
      window.location.search,
    ).get('selected_module');
    const hasActiveModule = modules.length > 0 && isLoading === false;
    selected_module = Number(selected_module);
    const hasSelectedModule = Number(selected_module) !== 0;
    if (hasSelectedModule) {
      window.location.href = `#${selected_module}`;
      return udpateSelectedModule(selected_module);
    }

    if (hasActiveModule) {
      if (!Boolean(getActiveModule()?.id)) return;
      if (getActiveModule()?.orderIntoRute >= 3) {
        window.location.href = `#${getActiveModule()?.id}`;
      }
    }
    return udpateSelectedModule(getActiveModule()?.id ?? 0);
  };

  useEffect(() => {
    if (enableEvilSideEffect) {
      if (Boolean(getActiveModule()?.id)) {
        udpateSelectedModule(getActiveModule()?.id);
      }
    }
  }, [modules]);

  useEffect(() => {
    if (enableEvilSideEffect) {
      evilSideEffect();
    }
  }, [modules, isLoading]);

  return {
    // properties
    data: modules,
    metadata,
    isLoading,
    selectedModule,
    getActiveModule,
    // methods
    getModules,
    listModulesFormatter,
    getCompletedModulesCount,
    udpateSelectedModule,
  };
};
