import { BackIcon } from '@/components/icons/BackIcon';
import { useGoBack } from '@/hooks/useGoBack';
import { TYPOGRAPHY } from '@/pages/ListContentPage/constants';
import { Box, Button, useTheme } from '@mui/material';

import { useTranslation } from 'react-i18next';

export const Heading = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const goBack = useGoBack();
  return (
    <div>
      <Box>
        <Button
          onClick={() => goBack({ fallback: '/points' })}
          size="small"
          variant="text"
          data-testid="back-button-card-theme"
          sx={{
            textTransform: 'capitalize',
            color: theme.palette.secondary.main,
            display: 'flex',
            gap: '5px',
            fontFamily: TYPOGRAPHY.bold,
            fontSize: '1rem',
          }}
        >
          <BackIcon
            height={20}
            width={20}
            color={theme.palette.secondary.main}
          />{' '}
          {t('products.products')}
        </Button>
      </Box>
      <Box mt={4} fontFamily={TYPOGRAPHY.bold} fontSize={'1rem'}>
        {t('products.title')}
      </Box>
    </div>
  );
};
