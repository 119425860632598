import React from 'react';
import { Navigate, RouteProps } from 'react-router-dom';
import { useConfig } from '../hooks/useConfig';
import { useUser } from '@/components/AuthInterceptorComponent/hooks/useUser';

export default function ClientRoute(props: RouteProps): React.ReactElement {
  const config = useConfig();
  const { user } = useUser();

  if (config.expired_user_can_use_app) {
    return <>{props.element}</>;
  }

  if (!user?.is_client) {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    return <Navigate to="/" />;
  }

  return <>{props.element}</>;
}
