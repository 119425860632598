import { useQuery } from '@tanstack/react-query';
import { apiUrls } from '../constants/apiUrls';
import { QueryKeys } from '../constants/queryKeys';
import { get } from '../services/api';
import { getTheme } from '../services/configuration';
import theme from '../theme/hackUTheme';
import { create } from 'zustand';
import { useEffect } from 'react';
import { createTheme } from '@mui/material';

export const ResourceState = {
  IDLE: 'idle',
  LOADING: 'loading',
  READY: 'ready',
  ERROR: 'error',
};

const initialState = {
  theme,
  logo: null,
  multipleCompanies: null,
  hideHackuLogo: false,
  showGammification: false,
  showCertificates: false,
  isMeidei: '',
  language: 'es',
  isSimple: false,
  isCustom: null,
  courseLabel: null,
  moduleLabel: null,
  expired_user_can_use_app: false,
};

export interface dataConfig {
  data: {
    name: string;
    slug_name: string;
    change_area: boolean;
    coin_logo: any;
    logo: string;
    is_meidei: boolean;
    simple_experience: boolean;
    multiple_companies: boolean;
    hide_hacku_logos: boolean;
    show_gammification: boolean;
    show_certificates: boolean;
    language: string;
    course_label: string;
    module_label: string;
    expired_user_can_use_app: boolean;
    restrict_name_certificate: boolean;
    custom_profile_sections: {
      placeholder_comment_capsule: string;
      student_profile_learning: string;
      student_profile_capsule: string;
      consumed_hours_profile_capsule: string;
      coach_profile_capsule: string;
      company_profile_capsule: string;
    };
  };
}

interface ResponseApi {
  isLoading: boolean;
  isError: boolean;
  error: any;
  data: dataConfig | undefined;
}

export const useCompanyConfig = create((set) => ({
  config: initialState,
  load: false,
  updateConfig: (newValues: any) =>
    set((state: any) => ({
      load: true,
      config: {
        ...state.config,
        ...newValues,
      },
    })),
}));

/**
 * Custom hook to fetch and manage configuration data.
 *
 * @return {object} The fetched data along with status and error information.
 * @property {object} theme - The theme object.
 * @property {string} logo - The logo URL, default is null.
 * @property {boolean} multipleCompanies - Indicates if multiple companies are supported, default is null.
 * @property {boolean} hideHackuLogo - Indicates if the Hacku logo should be hidden, default is false.
 * @property {boolean} showGammification - Indicates if gamification should be shown, default is false.
 * @property {boolean} showCertificates - Indicates if certificates should be shown, default is false.
 * @property {boolean} isMeidei - Indicates if the user is Meidei, default is false.
 * @property {string} language - The language code, default is 'es'.
 * @property {boolean} isSimple - Indicates if the experience is simple, default is false.
 * @property {string} isCustom - Custom label for the course, default is null.
 * @property {string} courseLabel - Label for the course, default is null.
 * @property {string} moduleLabel - Label for the module, default is null.
 * @property {boolean} expired_user_can_use_app - Indicates if an expired user can use the app, default is false.
 * @property {boolean} change_area - Indicates if the user can change the area, default is false.
 * @property {string} name - The name of the company, default is null.
 * @property {string} slug_name - The slug name company
 * @property {boolean} restrict_name_certificate - Indicates if the name of the certificate should be restricted, default is false.
 */
export const useConfig = (enabled = false) => {
  const { isLoading, isError, error, data }: ResponseApi = useQuery({
    queryKey: [QueryKeys.CONFIG],
    queryFn: () => {
      return get(apiUrls.users.config);
    },
    refetchOnWindowFocus: false,
    retry: false,
    enabled,
  });

  const store: any = useCompanyConfig((state) => state);
  const serviceData = data?.data;

  useEffect(() => {
    // if (serviceData && !store.load) {
    const responseTheme = getTheme(serviceData, theme);
    const newTheme = createTheme({
      ...theme,
      ...responseTheme,
      palette: { ...theme.palette, ...responseTheme.palette, loaded: true },
    });

    store.updateConfig({
      name: serviceData?.name,
      slug_name: serviceData?.slug_name,
      change_area: serviceData?.change_area,
      theme: newTheme,
      logo: serviceData?.logo,
      multipleCompanies: serviceData?.multiple_companies,
      hideHackuLogo: Boolean(serviceData?.hide_hacku_logos),
      showGammification: Boolean(serviceData?.show_gammification),
      showCertificates: Boolean(serviceData?.show_certificates),
      isMeidei: serviceData?.is_meidei ? 'Meidei' : '',
      language: serviceData?.language || initialState.language,
      isSimple: Boolean(serviceData?.simple_experience),
      isCustom: serviceData?.course_label,
      courseLabel: serviceData?.course_label,
      expired_user_can_use_app: Boolean(serviceData?.expired_user_can_use_app),
      restrict_name_certificate: serviceData?.restrict_name_certificate,
      moduleLabel: serviceData?.module_label,
      coin_logo: serviceData?.coin_logo,
    });
    //}
  }, [serviceData]);

  return {
    ...data,
    error: {
      ...error,
    },
    ...store.config,
    status: () => {
      switch (true) {
        case isLoading:
          return ResourceState.LOADING;
        case isError:
          return ResourceState.ERROR;
        case data !== undefined:
          return ResourceState.READY;
        default:
          return ResourceState.IDLE;
      }
    },
  };
};
