import React, { FunctionComponent } from 'react';
import { Button } from '@mui/material';
// styles
import { buttonThemeStyles } from './ButtonTheme.styles';

interface ButtonThemeProps {
  variant?: 'contained' | 'outlined' | 'text';
  children: React.ReactNode;
  mt?: number;
  mb?: number;
  mr?: number;
  ml?: number;
  my?: number;
  mx?: number;
  style?: React.CSSProperties;
  fullWidth?: boolean;
  color?: string;
  onClick?(): void;
  disabled?: boolean;
}

const ButtonTheme: FunctionComponent<ButtonThemeProps> = ({
  variant,
  children,
  style,
  fullWidth,
  color,
  mt,
  mb,
  mr,
  ml,
  mx,
  my,
  onClick,
  disabled,
}) => {
  const { classes } = buttonThemeStyles({ mt, mb, mr, ml, mx, my, color });
  let marginClasses = '';
  if (mt) marginClasses += `${classes.mt} `;
  if (mb) marginClasses += `${classes.mb} `;
  if (mr) marginClasses += `${classes.mr} `;
  if (ml) marginClasses += `${classes.ml} `;
  if (mx) marginClasses += `${classes.mx} `;
  if (my) marginClasses += `${classes.my} `;

  return (
    <Button
      variant={variant}
      fullWidth={fullWidth}
      className={`${variant ? classes[variant] : ''} ${marginClasses} ${
        classes.noTransform
      }`}
      style={style}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};

export default ButtonTheme;
