import { Theme } from '@mui/material/styles';
import Lines from '../../static/lineas-01.svg';
import TimerIcon from '../../static/lista.svg';
import { makeStyles } from 'tss-react/mui';

export const cardThemeStyles = makeStyles<{ progress: any }>()(
  (_theme: Theme, { progress }) => ({
    root: {
      boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.160784)',
      borderRadius: '.8rem',
    },
    buttonNav: {
      display: 'flex',
      alignItems: 'center',
      textTransform: 'initial',
      color: _theme.palette.secondary.main,
      fontWeight: 400,
    },
    buttonClose: {
      borderRadius: '2rem',
      fontSize: '8.5pt',
      display: 'flex',
      alignItems: 'center',
      textTransform: 'initial',
    },
    paddingNone: {
      padding: 0,
      '&:last-child': {
        paddingBottom: 0,
      },
    },
    imagePoints: {
      width: '20px',
      height: '20px',
      '& g': {
        '& circle': {
          fill: _theme.palette.secondary.main,
        },
      },
    },
    ratingTitleText: {
      color: _theme.palette.primary.main,
      fontFamily: 'Gotham Rounded Bold',
      display: 'table-cell',
      verticalAlign: 'middle',
    },
    titleWelcome: {
      width: 'fit-content',
      margin: 'auto',
      position: 'relative',
      maxWidth: '70%',
      '&::before': {
        content: `''`,
        height: '20px',
        width: '20px',
        backgroundImage: `url(${Lines})`,
        backgroundRepeat: 'no-repeat',
        position: 'absolute',
        left: '-13px',
        top: '-8px',
        rotate: '-4deg',
      },
    },
    titleWelcomeUser: {
      fontFamily: 'Gotham Rounded Bold',
      wordBreak: 'break-all',
      marginLeft: _theme?.spacing(0.5),
    },
    estimatedBox: {
      boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.18)',
      height: '40px',
      borderRadius: '25px',
      // border: '1px solid #cccccc',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '20px',
      position: 'relative',
      width: '130px',
    },
    bubbleIcon: {
      width: '30px',
      height: '30px',
      position: 'relative',
      backgroundImage: `url(${TimerIcon})`,
      backgroundSize: '30px',
      backgroundRepeat: 'no-repeat',
      zIndex: 2,
    },
    durationText: {
      fontSize: '0.75rem',
      color: 'var(--black-text)',
    },
    clockIcon: {
      position: 'absolute',
      left: '0',
      top: '0',
      width: '100px',
    },
    outlinedSvg: {
      strokeDashoffset: `calc(122px - (100px * (10 + ${progress})) / 100)`,
      stroke: '#00EFA0',
      strokeDasharray: 100,
      zIndex: -100,
    },
    svgProgress: {
      position: 'absolute',
      width: '50px',
      height: '50px',
      bottom: '-2px',
      left: '1.5px',
      transform: 'rotate(280deg)',
      '& circle': {
        width: '100%',
        height: '100%',
        fill: 'none',
        strokeWidth: 2,
        strokeLinecap: 'round',
        transform: ' translate(5px, 5px)',
      },
    },
    wrapperIconBubble: {
      borderRadius: '50%',
      // boxShadow: ' 0 0 1.5px #000',
      position: 'absolute',
      left: '5px',
      padding: '5px 5px 5px 5px',
    },
  }),
);
