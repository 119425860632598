import React from 'react';
import * as ReactDOMClient from 'react-dom/client';

import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
// Components
import App from './App';
// Styles
import CssBaseline from '@mui/material/CssBaseline';
import './index.css';
import './i18n';
import './configs';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
const rootElement = document.getElementById('root')!;
const root = ReactDOMClient.createRoot(rootElement);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <CssBaseline />
      <App />
    </QueryClientProvider>
  </React.StrictMode>,
);

window.addEventListener('vite:preloadError', (event) => {
  event.preventDefault();
});
