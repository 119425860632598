import { create } from 'zustand';
type ToastStore = {
  openToast: boolean;
  setOpenToast: (value: boolean) => void;
};

const toastStore = create<ToastStore>()((set) => ({
  openToast: false,
  setOpenToast: (value) => set({ openToast: value }),
}));

export { toastStore };
