import { apiUrls } from '@/constants/apiUrls';
import { QueryKeys } from '@/constants/queryKeys';
import { get } from '@/services/api';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export interface User {
  pk: number;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  is_client: boolean;
  profile: Profile;
  super_staff_data: boolean;
  menu_links: MenuLinks;
}

export interface MenuLinks {
  my_modules: string;
  my_certs?: string;
}

export interface Profile {
  friendly_name: string;
  photo: string | null;
  birthdate: Date;
}

export const useUser = () => {
  const getUserInfo = async () => {
    const userId = window.localStorage.getItem('user_id') ?? '';
    const token = window.localStorage.getItem('access_token') ?? '';
    try {
      const response = await get(apiUrls.auth.user(userId as string), {
        Authorization: `Token ${token}`,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const user = useQuery<User>({
    queryKey: [QueryKeys.USER],
    queryFn: getUserInfo,
    enabled: false,
  });

  const client = useQueryClient();
  const invalidate = () => {
    client.invalidateQueries({
      queryKey: [QueryKeys.USER],
    });
  };

  return {
    user: user?.data ?? null,
    invalidate,
    ...user,
  };
};
