import { Box, Grid, Skeleton } from '@mui/material';

export const ProductListSkeleton = () => {
  return (
    <>
      <Grid item xs={6}>
        <CardSkeleton />
      </Grid>
      <Grid item xs={6}>
        <CardSkeleton />
      </Grid>
      <Grid item xs={6}>
        <CardSkeleton />
      </Grid>
      <Grid item xs={6}>
        <CardSkeleton />
      </Grid>
    </>
  );
};

const CardSkeleton = () => {
  return (
    <Box>
      <Skeleton
        sx={{
          marginBottom: '1rem',
        }}
        variant="rectangular"
        width={'100%'}
        height={'20rem'}
      />
      <Skeleton width={'30%'} variant="text" sx={{ fontSize: '1rem' }} />
      <Skeleton width={'50%'} variant="text" sx={{ fontSize: '1.5rem' }} />
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
      <Skeleton width={'50%'} variant="text" sx={{ fontSize: '1.5rem' }} />
      <Skeleton width={'50%'} variant="text" sx={{ fontSize: '1rem' }} />
    </Box>
  );
};
