import { apiUrls } from '@/constants/apiUrls';
import { QueryKeys } from '@/constants/queryKeys';
import { useConfig } from '@/hooks/useConfig';
import { get } from '@/services/api';
import { useQuery } from '@tanstack/react-query';

export const useProducts = () => {
  const config = useConfig();

  const getProducts = async () => {
    const { data } = await get(apiUrls.products.list(config.slug_name));
    return data;
  };

  const query = useQuery({
    queryKey: [QueryKeys.PRODUCT_LIST],
    queryFn: getProducts,
  });

  const hasProducts = query?.data?.results.length > 0;

  return {
    ...query,
    hasProducts,
  };
};
