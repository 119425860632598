import { FC } from 'react';
import { Button, Box, Modal, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useConfig } from '../../../hooks/useConfig';
import { profilePageGatewayStyles } from '../ProfilePageGateway.styles';
//ICONS
import CloseIcon from '@mui/icons-material/Close';
import LogoProfile from '../../../static/og_logo.svg?react';

//COMPONENTS
import Image from '../../../components/Image';
import { get } from '../../../services/api';
import { capitalize } from '../../../utils/general';

interface ModalConnectionProps {
  open: boolean;
  handleClose: () => void;
  photo: string;
  activeOption: Record<string, any>;
}

const ModalConnection: FC<ModalConnectionProps> = ({
  open,
  handleClose,
  photo,
  activeOption,
}) => {
  const { t } = useTranslation();
  const config = useConfig();
  const theme = useTheme();
  const { classes } = profilePageGatewayStyles();

  const noImage = () => {
    if (config.hideHackuLogo) {
      return 'og_logo.png';
    } else {
      return 'og_logo_old.png';
    }
  };

  const optionChange = async (url: string) => {
    try {
      url = url.replace('/api/', '');
      const response = await get(url);
      if (response.status === 200) {
        location.reload();
      }
    } catch (error) {
      location.reload();
    }
  };

  const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 345,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} borderRadius={'10px'} position={'relative'}>
        <Box
          position={'absolute'}
          top={'-10px'}
          right={'-5px'}
          width={'30px'}
          height={'30px'}
          onClick={handleClose}
          sx={{
            boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
            borderRadius: '50%',
            background: theme.palette.primary.main,
            cursor: 'pointer',
          }}
        >
          <CloseIcon sx={{ fill: 'white', margin: '3px' }} />
        </Box>
        <Box
          display={'inline-flex'}
          alignItems={'center'}
          width={'100%'}
          justifyContent={'center'}
          paddingBottom={'20px'}
          borderBottom={'0.5px solid #d9d9d9'}
          minHeight={'10%'}
        >
          {photo ? (
            <Image
              route={photo ? photo : noImage()}
              alt="image-hacku"
              isStatic={!photo}
              className={classes.profileImageModal}
            />
          ) : (
            <Box className={classes.profileImageModal}>
              <LogoProfile width={'60px'} height={'60px'} />
            </Box>
          )}
          <Box
            className={classes.appImageModal}
            position={'relative'}
            marginLeft={'15px'}
          >
            {activeOption.icon}
          </Box>
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          minHeight={'200px'}
          alignItems={'center'}
          borderBottom={'0.5px solid #d9d9d9'}
        >
          <Typography component={'h2'} marginTop={'15px'} fontWeight={'bolder'}>
            {t('gateWayScreen.connectWith')} {activeOption.name}
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{
              mt: 2,
              textAlign: 'justify',
              fontWeight: 'light',
              color: '#505050',
            }}
          >
            {capitalize(String(activeOption.text))}
          </Typography>
        </Box>
        <Box>
          <Button
            sx={{
              borderRadius: '2rem',
              textTransform: 'unset',
              width: '100%',
              marginTop: '30px',
            }}
            type="button"
            variant="contained"
            disabled={activeOption.checked}
            onClick={() => {
              if (activeOption.method === 'redirect') {
                return (window.location.href = activeOption.url);
              }
              return optionChange(activeOption.url);
            }}
          >
            {t('gateWayScreen.connect')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalConnection;
