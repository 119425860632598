import { Box } from '@mui/material';
import { Heading } from './components/Heading';
import List from './components/List';
import { appContainer } from '../../constants/styles';

export function PointsPage() {
  return (
    <Box sx={appContainer}>
      <Heading />
      <List />
    </Box>
  );
}
