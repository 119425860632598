import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const buttonThemeStyles = makeStyles<{
  color: any;
  mt: any;
  mb: any;
  mr: any;
  ml: any;
  mx: any;
  my: any;
}>()((theme: Theme, { color, mb, mt, mr, ml, mx, my }) => ({
  contained: {
    backgroundColor: color || theme.palette.primary.main,
    color: '#FFF',
    borderRadius: '2rem',
    '&:hover': {
      backgroundColor: color || theme.palette.primary.main,
    },
  },
  outlined: {
    backgroundColor: 'transparent',
    color: color || theme.palette.primary.main,
    borderColor: color || theme.palette.primary.main,
    borderRadius: '2rem',
    '&:hover': {
      backgroundColor: 'transparent',
      color: color || theme.palette.primary.main,
    },
  },
  text: {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    borderRadius: '2rem',
  },
  mt: {
    marginTop: theme.spacing(mt || 0),
  },
  mb: {
    marginBottom: theme.spacing(mb || 0),
  },
  mr: {
    marginRight: theme.spacing(mr || 0),
  },
  ml: {
    marginLeft: theme.spacing(ml || 0),
  },
  mx: {
    marginLeft: theme.spacing(mx || 0),
    marginRight: theme.spacing(mx || 0),
  },
  my: {
    marginTop: theme.spacing(my || 0),
    marginBottom: theme.spacing(my || 0),
  },
  noTransform: {
    textTransform: 'none',
  },
}));
