import { Suspense } from 'react';
import Spinner from './components/Spinner';

// this will show the animation

const Loadable =
  (Component: any, Skeleton = <Spinner />) =>
  (props: any) =>
    (
      <Suspense fallback={Skeleton}>
        <Component {...props} />
      </Suspense>
    );

export default Loadable;
