import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const profilePageGatewayStyles = makeStyles()((theme: Theme) => ({
  imageContainer: {
    margin: '0',
    textAlign: 'center',
  },
  profileImage: {
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    borderRadius: '50%',
    width: '130px',
    height: '130px',
  },
  profileImageModal: {
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    borderRadius: '50%',
    width: '60px',
    height: '60px',
  },
  appImageModal: {
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    borderRadius: '50%',
    width: '60px',
    height: '60px',
    '& svg': {
      position: 'absolute',
      bottom: '10px',
      left: '10px',
      width: '40px',
      height: '40px',
    },
  },
  buttonEditImage: {
    position: 'relative',
    margin: 'auto',
    backgroundColor: theme.palette.primary.main,
    fontSize: '9pt',
    fontWeight: 'normal',
    padding: '.2rem .5rem',
    borderRadius: '2rem',
    textTransform: 'initial',
    top: theme.spacing(15),
    zIndex: 10,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  image: {
    width: '130px',
    height: '130px',
    boxShadow: '0 3px 6px 0 rgb(0 0 0 / 16%)',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  saveChangesButton: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    '& .MuiButtonBase-root': {
      width: '80%',
      height: '41px',
      color: '#fff',
      backgroundColor: theme.palette.primary.main,
      borderRadius: '2rem',
      fontFamily: 'Gotham Book',
      fontSize: '14px',
      textTransform: 'none',
    },
  },
  buttonProgress: {
    color: '#fff',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  fontSize14: {
    fontSize: '14px',
  },
  alert: {
    padding: theme.spacing(0.1, 1.5),
  },
}));
