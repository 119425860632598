import {
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  Button,
  Typography,
  IconButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Emoji from '../../../static/Emojisgafas.svg';
import Points from '../../../theme/assets/hackupoints.svg?react';
import ClosePurpleIcon from '../../../static/closePurpleIcon.svg';
import { useNavigate } from 'react-router-dom';
import { useUser } from '@/components/AuthInterceptorComponent/hooks/useUser';

type SuccessModalProps = {
  open: boolean;
  onClose: (value: boolean) => void;
  answers?: Record<any, any>[];
  showNextButton: boolean;
  points: number;
  newDelivery: number;
  url: string;
  customFeedback?: string | null;
  deliveryPk?: number;
};

export function SuccessModal({
  open,
  answers,
  showNextButton,
  points,
  newDelivery,
  url,
  customFeedback,
  deliveryPk,
  onClose,
}: SuccessModalProps) {
  const theme = useTheme();
  const { user } = useUser();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const closeModalInCloseIcon = () => {
    onClose(false);
    return navigate('/content' + '/detail/' + deliveryPk, {
      replace: true,
    });
  };

  const closeModal = () => {
    onClose(false);
    if (Boolean(url)) {
      return (window.location.href = `${
        import.meta.env.VITE_APP_LOGIN_HOST
      }${url}`);
    }
    if (!newDelivery)
      return navigate('/content', {
        replace: true,
      });
    return navigate(`/content/detail/${newDelivery}`, {
      replace: true,
    });
  };

  const RenderFeedBack = () => {
    if (Boolean(customFeedback)) {
      return <>{customFeedback}</>;
    }
    return (
      <>
        {t('selfAssesmentPage.youChooseAnswer')}
        <b> {t('selfAssesmentPage.correct')}</b>
      </>
    );
  };

  return (
    <Box>
      <Dialog
        open={open}
        onClose={() => closeModal()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        scroll="body"
        PaperProps={{
          sx: {
            borderRadius: '1rem',
            paddingY: '2.5rem',
            width: '100%',
            height: 'auto',
            overflow: 'hidden',
            backgroundImage: `url(${Emoji})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100%',
          },
        }}
      >
        <>
          <IconButton
            aria-label="close"
            onClick={closeModalInCloseIcon}
            sx={{
              position: 'absolute',
              right: '0rem',
              top: '0rem',
            }}
          >
            <img src={ClosePurpleIcon} alt="close" width="35" />
          </IconButton>
          <DialogContent
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              paddingTop: '5rem',
            }}
          >
            <Box
              textAlign={'center'}
              alignSelf={'flex-end'}
              width={'100%'}
              paddingTop="1rem"
            >
              <Box>
                <Box
                  width={'80px'}
                  height={'80px'}
                  borderRadius={'50%'}
                  position={'relative'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  mt={'1rem'}
                  ml={'auto'}
                  mr={'auto'}
                  sx={{
                    backgroundColor: theme.palette.success.main,
                    boxShadow: '0px 0px 10px 1px #0f6907 inset',
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="50"
                    height="50"
                    viewBox="0 0 24 24"
                  >
                    <path d="M 19.28125 5.28125 L 9 15.5625 L 4.71875 11.28125 L 3.28125 12.71875 L 8.28125 17.71875 L 9 18.40625 L 9.71875 17.71875 L 20.71875 6.71875 Z"></path>
                  </svg>
                </Box>
                <Typography
                  fontWeight={'bolder'}
                  fontFamily={'Gotham Rounded'}
                  fontSize={'20px'}
                >
                  {user?.first_name},
                </Typography>
                <Typography fontSize={'1rem'} fontFamily={'Gotham Rounded'}>
                  <RenderFeedBack />
                </Typography>
                <Typography fontSize={'1rem'} fontFamily={'Gotham Rounded'}>
                  {t('selfAssesmentPage.timeToPracticeIt')}
                </Typography>
              </Box>
              <Box mt={'2rem'}>
                {answers?.map((answer, index) => (
                  <Box key={index + 'answer'}>
                    <Typography
                      fontFamily={'Gotham Rounded'}
                      fontSize={'0.9rem'}
                      fontWeight={'bold'}
                    >
                      Pregunta {answer.questionIndex}
                    </Typography>
                    <Box
                      borderRadius={'25px'}
                      width={'70%'}
                      sx={{
                        backgroundColor: theme.palette.success.main,
                      }}
                      height={'3rem'}
                      display={'flex'}
                      ml={'auto'}
                      mr={'auto'}
                      mb={'1rem'}
                      justifyContent={'center'}
                      alignItems={'center'}
                    >
                      <Typography fontFamily={'Gotham Rounded'}>
                        Elegiste la opcion {answer?.option}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: 'center',
              padding: '20px 24px',
              display: 'flex',
              flexDirection: 'column',
              alignSelf: 'flex-start',
              margin: '-2rem',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '-0.6rem',
              }}
            >
              <Points
                style={{
                  width: '25px',
                }}
              />{' '}
              <Typography fontWeight={'bold'} fontFamily={'Gotham Rounded'}>
                {points}
              </Typography>
            </Box>
            {showNextButton && (
              <Button
                sx={{
                  color: theme.palette.common.white,
                  width: '60%',
                  borderRadius: '50px',
                  background:
                    'radial-gradient(120.73% 120.73% at 50% 6.1%, #391F58 44.79%, #572E88 83.85%)',
                  fontFamily: 'Gotham Rounded',
                  textTransform: 'none',
                }}
                onClick={() => {
                  closeModal();
                }}
                variant="contained"
              >
                {t('selfAssesmentPage.studyNextContent')}
              </Button>
            )}
          </DialogActions>
        </>
      </Dialog>
    </Box>
  );
}
