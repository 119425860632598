export enum QueryKeys {
  THREADS = 'threads',
  POINTS = 'points',
  CONFIG = 'config',
  CONTENT_DETAILS = 'content-details',
  CONTENT_ASSESTS = 'content-assets',
  ANSWER_SELF_ASSESSMENT = 'answer-self-assessment',
  ACTIVE_COURSE_AND_MODULE = 'active-course-and-module',
  AVAILABLE_ROUTES = 'available-routes',
  AVAILABLE_COURSES = 'available-courses',
  RESTRICT_ACCESS = 'restrict-access',
  USER = 'user',
  STREAK = 'streak',
  POINTS_LOGS = 'points-logs',
  NPS_OPTIONS = 'nps-options',
  PROFILE = 'profile',
  DOCUMENT_TYPE = 'document-type',
  ACTIVE_GATEWAY = 'active-gateway',
  LOGIN = 'login',
  RANKING = 'ranking',
  ACTIVE_ROUTE = 'active-route',
  NOTIFICATIONS = 'notifications',
  COMPANY_RESTRICTIONS = 'company-restrictions',
  USER_PREFERENCES = 'user-preferences',
  PENDING_CONTENT = 'pending-content',
  AVATARS = 'avatars',
  PRODUCT_LIST = 'product-list',
  PRODUCT_DETAIL = 'product-detail',
  HOME_WIDGETS = 'home-widgets',
  HOME_STATS = 'home-stats',
  CAN_UNLOCK = 'can-unlock',
}
