import { create } from 'zustand';

type NextData = {
  can_unlock: boolean;
  new_delivery: number;
  url: string;
  next_distribution_content?: number;
};

type DetailsContentStore = {
  realProgress: number;
  showNextButton: boolean;
  nextData: NextData;
  firstRenderPlayer: boolean;
  setNextData: (value: NextData) => void;
  setShowNextButton: (value: boolean) => void;
  setProgress: (value: number) => void;
  setFirstRenderPlayer: (value: boolean) => void;
};

const useDetailsContentStore = create<DetailsContentStore>()((set) => ({
  showNextButton: false,
  realProgress: 0,
  firstRenderPlayer: true,
  nextData: {
    can_unlock: false,
    new_delivery: 0,
    url: '',
  },
  setFirstRenderPlayer: (value) => set(() => ({ firstRenderPlayer: value })),
  setNextData: (value) => set(() => ({ nextData: value })),
  setShowNextButton: (value) => set(() => ({ showNextButton: value })),
  setProgress: (value) => set(() => ({ realProgress: value })),
}));

export { useDetailsContentStore };
