import { useQuery } from '@tanstack/react-query';
import { get } from '@/services/api';
import { QueryKeys } from '@/constants/queryKeys';

export const useDocumentType = () => {
  const getDocumentType = async () => {
    const { data } = await get(`users/document-types`);
    return data;
  };

  const query = useQuery({
    queryFn: getDocumentType,
    queryKey: [QueryKeys.DOCUMENT_TYPE],
  });

  return {
    ...query,
  };
};
