import { useEffect, useRef, useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';

// Components
import { ProgressBar } from '../ProgressBar';
import { BaseIcon } from './BaseIcon';

// Libraries
import { useSearchParams } from 'react-router-dom';

// Services
import { get } from '../../../../services/api';

// Hooks
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { contentProgressModalStyles } from './styles';
import { useUser } from '@/components/AuthInterceptorComponent/hooks/useUser';
import { useModules } from '@/hooks/useModules';

export interface ExtraData {
  sent: number;
  total: number;
  viewed: number;
}

export interface ContentsData {
  user: string;
  total_contents_theoretical: number;
  pending_contents: number;
}

export interface TheoreticalData {
  extra_data: ExtraData;
  contents_data: ContentsData;
}

export const ContentProgressModal = () => {
  const showTheoreticalModal = useRef('show_theoretical_modal');
  // read query params from url react router dom
  const [_, setSeachParams] = useSearchParams();
  const [theoreticalData, setTheoreticalData] = useState<TheoreticalData>();
  const [isOpen, setOpen] = useState<boolean>(false);
  const { user } = useUser();
  const modules = useModules(false);
  const current_module_pk = modules.getActiveModule()?.id;

  const theme = useTheme();
  const { t } = useTranslation();
  const styles = contentProgressModalStyles(theme);

  useEffect(() => {
    if (user?.pk && current_module_pk) {
      get(
        `users/:user_pk/modules/${current_module_pk}/contents/v2/theoretical`,
      ).then(({ data }) => {
        setTheoreticalData(data);
        if (data?.contents_data?.pending_contents > 0) {
          setTimeout(() => setOpen(true), 2000);
        }
      });
    }
  }, [user?.pk, current_module_pk]);

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      BackdropProps={{
        sx: styles.backDrop,
      }}
      PaperProps={{
        sx: styles.paper,
      }}
    >
      <BaseIcon
        style={styles.image}
        fillOne={theme.palette.error.main}
        fillTwo={theme.palette.warning.main}
        fillThree={theme.palette.primary.main}
      />

      <Typography sx={styles.title} align="center" variant="h2" gutterBottom>
        {user?.first_name},
      </Typography>
      <Typography sx={styles.description} align="center" gutterBottom>
        {t('contentScreen.thisIs')}{' '}
        <span style={styles.bold}>{t('contentScreen.yourProgressLevel')}</span>
        <br /> {t('contentScreen.ofTheContents')}
      </Typography>
      <DialogContent
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          overflow: 'hidden',
          paddingTop: '0.5rem',
        }}
      >
        <Typography align="center" sx={styles.progressTitle}>
          {t('contentScreen.courseContent')} <br />
          <span>{theoreticalData?.extra_data.total}</span>
        </Typography>
        <Box minWidth={'30vw'} />
        {/*TODO aqui va la grafica */}
        <ProgressBar
          totalContents={theoreticalData?.extra_data.total}
          currentProgress={theoreticalData?.extra_data.viewed}
          currentTooltipText={String(theoreticalData?.extra_data.viewed)}
          idealProgress={
            theoreticalData?.contents_data.total_contents_theoretical
          }
          idealTooltipText={String(
            theoreticalData?.contents_data.total_contents_theoretical,
          )}
        />

        <Typography sx={styles.textSm} align="center" gutterBottom>
          <span style={styles.red}>
            {t('contentScreen.youNeedToSee')}{' '}
            {theoreticalData?.contents_data &&
              String(theoreticalData?.contents_data?.pending_contents)}{' '}
            {t('contentScreen.contents')}{' '}
          </span>{' '}
          <br /> {t('contentScreen.tobeUpdated')}
        </Typography>
        <Typography sx={{ ...styles.bold, ...styles.textSm }}>
          {t('contentScreen.dontStayBehind')}{' '}
        </Typography>
      </DialogContent>
      <DialogActions sx={styles.center}>
        <button
          style={styles.button}
          onClick={() =>
            setSeachParams({
              [showTheoreticalModal.current]: 'false',
            })
          }
        >
          {t('contentScreen.done')}
        </button>
      </DialogActions>
    </Dialog>
  );
};
