import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const spinnerStyles = makeStyles()((_theme: Theme) => ({
  loader: {
    width: '100%',
    // WebkitAnimation: 'pulse 2s linear infinite',
    // animation: 'pulse 2s linear infinite',
    height: '100vh',
    position: 'absolute',
    top: '0',
    overflow: 'hidden !important',
    zIndex: 100000,
    background: 'white',
  },
  loadingText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    WebkitTransform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
}));
