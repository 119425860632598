// Generated by https://quicktype.io

interface ProfileResponse {
  pk: number;
  username: string;
  first_name: string;
  last_name: string;
  get_ranking_position: number;
  email: string;
  is_client: boolean;
  profile: Profile;
  company_data: CompanyData;
  coach: string;
  super_staff_data: boolean;
  menu_links: MenuLinks;
  phone_number: string;
  country: string;
  phone_data: PhoneData;
  friendly_name: string;
}

interface CompanyData {
  name: string;
  show_certificates: boolean;
  is_meidei: boolean;
}

interface MenuLinks {
  my_modules: string;
}

interface PhoneData {
  number: string;
  prefix: string;
  verified: boolean;
}

interface Profile {
  photo: null;
  points: number;
  module: Module | null;
  route: Route | null;
  birthdate: string | null;
  get_user_hours_time_consumed_in_contents: number;
  hour: string;
  calendar_formated: string[];
  document_type: string;
  document_number: null;
  company_extra_fields: any[];
  extra_fields: ExtraFields;
  usefull_links: UsefullLinks;
  friendly_name: string;
}

interface ExtraFields {
  [key: string]: unknown;
}

interface Module {
  name: string;
  progress_percentage: number;
  module_pk: number;
  pending: number;
}

interface Route {
  name: string;
  progress_percentage: number;
  route_pk: number;
}

interface UsefullLinks {
  change_hour: string;
  change_days: string;
  change_module: string;
}

// Generated by https://quicktype.io

export interface ProfileLegacy {
  photo: string;
  points: number;
  get_current_user_module_name: string;
  get_current_user_route_name: string;
  current_module_pk: number | null;
  current_route_pk: number | null;
  current_module_progress_percentage: number | null;
  pending_contents: number | null;
  birthdate: string | null;
  get_user_hours_time_consumed_in_contents: number;
  hour: string;
  calendar_formated: string[];
  document_type: string;
  document_number: string | null;
  company_extra_fields: any[];
  extra_fields: ExtraFields;
  usefull_links: UsefullLinks;
  friendly_name: string;
}

// @ts-ignore
interface LegacyProfile extends ProfileResponse {
  profile: ProfileLegacy;
}

export const useProfileAdapter = () => {
  const profileAdapter = (data: ProfileResponse): LegacyProfile => {
    return {
      ...data,
      profile: {
        birthdate: data?.profile?.birthdate ?? '',
        current_module_pk: data?.profile?.module?.module_pk ?? null,
        current_module_progress_percentage:
          data?.profile?.module?.progress_percentage ?? null,
        current_route_pk: data?.profile?.route?.route_pk ?? null,
        get_current_user_module_name: data?.profile?.module?.name ?? '',
        get_current_user_route_name: data?.profile?.route?.name ?? '',
        pending_contents: data?.profile?.module?.pending ?? null,
        photo: data?.profile?.photo ?? '',
        points: data?.profile?.points,
        calendar_formated: data?.profile?.calendar_formated,
        company_extra_fields: data?.profile?.company_extra_fields,
        document_number: data?.profile?.document_number,
        document_type: data?.profile?.document_type,
        extra_fields: data?.profile?.extra_fields,
        get_user_hours_time_consumed_in_contents:
          data?.profile?.get_user_hours_time_consumed_in_contents,
        hour: data?.profile?.hour,
        usefull_links: data?.profile?.usefull_links,
        friendly_name: data?.profile?.friendly_name,
      },
    };
  };
  return {
    profileAdapter,
  };
};
