import { useLogin } from './useLogin';
import { useEffect } from 'react';
import { useUser } from '@/components/AuthInterceptorComponent/hooks/useUser';

export const useInterceptor = () => {
  //  login
  const login = useLogin();
  const user = useUser();
  useEffect(() => {
    if (!login.isLoading && login.isSuccess) user.refetch();
  }, [login.isSuccess, login.isLoading, user.refetch]);

  // request info user
  const enableRender = user.isSuccess && user?.data?.pk;
  return {
    enableRender,
  };
};
