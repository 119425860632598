import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const ErrorStyles = makeStyles()((_theme: Theme) => ({
  container: {
    minHeight: '90vh',
  },
  bold: {
    fontFamily: 'Gotham Rounded Bold',
  },
  iconTool: {
    float: 'left',
    marginTop: '-10px',
    marginLeft: '-10px',
  },
  iconClose: {
    cursor: 'pointer !important',
    right: '-10px',
    top: '-10px',
    position: 'absolute',
    width: '25px',
  },
  grid: {
    position: 'relative',
  },
  userImage: {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    position: 'relative',
    margin: 'auto',
    zIndex: 100,
    top: '30px',
    display: 'flex',
    backgroundColor: '#fff',
    cursor: 'pointer',
  },
  errorImage: {
    height: '14rem',
    margin: 'auto',
    display: 'flex',
    marginTop: '-60px',
  },
  cardTheme: {
    paddingBottom: '50px !important',
    minHeight: '60vh',
  },
  ratingButton: {
    borderRadius: '2em',
    backgroundColor: _theme.palette.primary.main,
    color: '#e1e1e1',
    '&:hover': {
      backgroundColor: _theme.palette.primary.main,
    },
    textTransform: 'initial',
    lineHeight: '1.4',
  },
  ratingTitleText: {
    color: '#888',
    margin: '0',
    marginBottom: '10px',
  },
  ratingRouteText: {
    [_theme.breakpoints.between('md', 'lg')]: {
      margin: '.5rem auto 0 auto',
      width: '50%',
    },
    wordBreak: 'break-word',
    margin: '.5rem 0 0 0',
  },
  hackuPointsContainer: {
    cursor: 'pointer',
    borderRadius: '16px',
    margin: 'auto',
    width: 'fit-content',
    [_theme.breakpoints.up('md')]: {
      padding: '4px 3em',
    },
    [_theme.breakpoints.down('sm')]: {
      padding: '4px 1.5em',
    },
    [_theme.breakpoints.down('xs')]: {
      padding: '0',
    },
    '&:hover': {
      boxShadow: '0px 4px 7px rgba(0, 0, 0, 0.25)',
    },
  },
  divPointsActive: {
    boxShadow: '0px 4px 7px rgba(0, 0, 0, 0.25)',
  },
  ratingScoreText: {
    margin: 0,
    fontWeight: 600,
    color: _theme.palette.primary.main,
    fontSize: '25pt',
    display: 'inline-flex',
    flex: 1,
    alignItems: 'center',
  },
  greyColor: {
    color: '#888',
  },
  blackColor: {
    color: '#000',
    fontWeight: 600,
  },
  gridBlock: {
    alignItems: 'stretch',
  },
  iconContainer: {
    padding: '0 1rem',
    cursor: 'pointer',
  },
  tooltip: {
    backgroundColor: _theme.palette.secondary.main,
  },
  puntosHacku: {
    width: '42px',
    height: 'auto',
  },
  pulse: {
    Animation: 'pulse 3s  infinite ',
    WebkitAnimation: 'pulse 3s  infinite ',
    MozAnimation: 'pulse 3s infinite',
    animationDirection: 'alternate',
  },
}));
