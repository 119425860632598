import { useState, useContext, createContext, ReactNode } from 'react';

interface AlertContextData {
  dataAlert: any;
  // eslint-disable-next-line
  setDataAlert: (v: any) => void;
}

export const alertContextDefaultValue: AlertContextData = {
  dataAlert: {},
  // eslint-disable-next-line
  setDataAlert: () => {},
};

const AlertContext = createContext<AlertContextData>(alertContextDefaultValue);

interface useAlertProps {
  children: ReactNode;
}

function AlertProvider({ children }: useAlertProps): JSX.Element {
  const [dataAlert, setDataAlert] = useState({});

  return (
    <AlertContext.Provider value={{ dataAlert, setDataAlert }}>
      {children}
    </AlertContext.Provider>
  );
}

function useAlert() {
  const context = useContext(AlertContext);

  if (context === undefined) {
    throw new Error('useAlert must be user within a AlertProvider');
  }
  return context;
}

export { AlertProvider, useAlert };
