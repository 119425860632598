import { apiUrls } from '@/constants/apiUrls';
import { QueryKeys } from '@/constants/queryKeys';
import { CustomThemeValues } from '@/constants/styles';
import { useConfig } from '@/hooks/useConfig';
import { TYPOGRAPHY } from '@/pages/ListContentPage/constants';
import { ErrorModal } from '@/pages/PointsRedemption/components/product-details/error-model';
import { ImageSlider } from '@/pages/PointsRedemption/components/product-details/images-slide';
import { post } from '@/services/api';
import { Box, Button, Divider } from '@mui/material';
import { Query, useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const subTitleStyles = {
  fontFamily: TYPOGRAPHY.bold,
  fontSize: '1rem',
  color: '#1F1333',
};

const longDescriptionStyles = {
  fontSize: '14px',
  marginTop: '.5rem',
  color: '#1F1333',
};

export interface Specification {
  name: string;
  value: string;
  ordering: number;
}

export const ProductCard = (props: {
  gallery: string[];
  brand: string;
  briefDescription: string;
  longDescription: string;
  name: string;
  amount: number;
  discountAmount: number;
  specifications?: Specification[];
  stock: number;
  id: number;
  canRedeem: boolean;
}) => {
  const { amount, brand, discountAmount, gallery, name, id, stock } = props;
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [notEnoughPoints, setNotEnoughPoints] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const config = useConfig();
  const queryCLient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async () => {
      return post(
        apiUrls.products.redemption({
          company_slug: config.slug_name,
          product_id: id,
        }),

        {
          quantity: 1,
        },
      );
    },
    onSuccess: (response) => {
      queryCLient.invalidateQueries({
        queryKey: [
          QueryKeys.PRODUCT_LIST,
          QueryKeys.POINTS,
          QueryKeys.POINTS_LOGS,
        ],
      });

      if (response.status === 200) {
        return navigate(`/points/products/success/${id}?hide_navbar=true`);
      }
      if (response.status === 400) {
        return setNotEnoughPoints(true);
      }
      setNotEnoughPoints(true);
    },
    onError: () => {
      setShowErrorModal(true);
    },
  });
  const handleClick = () => {
    mutation.mutate();
  };

  const toggleNotEnoughPoints = () => {
    setNotEnoughPoints(false);
  };

  const toggleErrorModal = () => {
    setShowErrorModal(false);
  };

  useEffect(() => {
    if (stock === 0) {
      toast.error(t('products.productOutOfStock'));
    }
  }, []);

  return (
    <Box>
      {showErrorModal && (
        <ErrorModal
          open
          title={t('products.error.title') as string}
          description={t('products.error.description') as string}
          onClick={toggleErrorModal}
          onClose={toggleErrorModal}
        />
      )}
      {notEnoughPoints && (
        <ErrorModal
          open
          title={t('products.error.title') as string}
          description={t('products.notEnoughPoints') as string}
          onClick={toggleNotEnoughPoints}
          onClose={toggleNotEnoughPoints}
        />
      )}
      <Box
        sx={{
          borderRadius: CustomThemeValues.BORDER,
          border: CustomThemeValues.CARD,
          cursor: 'pointer',
          marginTop: '1rem',
          padding: '1rem',
        }}
      >
        <Box>
          {gallery.length === 1 ? (
            <img
              src={gallery[0]}
              alt={name}
              style={{
                borderRadius: CustomThemeValues.BORDER,
                width: '100%',
                height: '20rem',
                objectFit: 'contain',
              }}
            />
          ) : (
            <ImageSlider
              images={gallery.map((image) => ({ url: image, alt: name }))}
            />
          )}
        </Box>
        <Box>
          <Box
            sx={{
              fontFamily: TYPOGRAPHY.bold,
              fontSize: '1rem',
              color: '#1F1333',
            }}
          >
            {brand}
          </Box>
          <Box
            sx={{
              fontSize: '20px',
              color: '#1F1333',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical',
              lineHeight: '24px',
              marginY: '0.2rem',
            }}
          >
            {name}
          </Box>
          <Box
            sx={{
              fontSize: '14px',
              color: '#797185',
            }}
          >
            {props.briefDescription}
          </Box>
          <Box>
            {Boolean(props.discountAmount) ? (
              <>
                <span
                  style={{
                    color: '#572D88',
                    fontSize: '20px',
                    fontFamily: TYPOGRAPHY.bold,
                    marginRight: '0.5rem',
                  }}
                >
                  {discountAmount} pts
                </span>
                <span
                  style={{
                    textDecoration: 'line-through',
                    color: '#797185',
                    fontSize: '16px',
                  }}
                >
                  {amount} pts
                </span>
              </>
            ) : (
              <>
                <span
                  style={{
                    color: '#572D88',
                    fontSize: '20px',
                    fontFamily: TYPOGRAPHY.bold,
                    marginRight: '0.5rem',
                  }}
                >
                  {amount} pts
                </span>
              </>
            )}
          </Box>
          <Box>
            {props.stock} {t('products.units')}
          </Box>
        </Box>
      </Box>
      <Box marginTop={'2rem'} display={'flex'} justifyContent={'center'}>
        <Button
          onClick={handleClick}
          variant="contained"
          sx={{
            width: '300px',
            borderRadius: '20px',
            textTransform: 'none',
          }}
          disabled={mutation.isPending || stock === 0}
        >
          {mutation.isPending ? t('products.redeeming') : t('products.redeem')}
        </Button>
      </Box>
      <Box marginTop={'2rem'}>
        <Box sx={subTitleStyles}>{t('products.productDescription')}</Box>
        <Box sx={longDescriptionStyles}>{props.longDescription}</Box>
      </Box>
      <Box marginTop={'2rem'}>
        <Box sx={subTitleStyles} marginBottom={'1rem'}>
          {t('products.specification')}
        </Box>
        {props?.specifications?.map((item) => {
          return (
            <Box key={item.name}>
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                paddingX={'1rem'}
              >
                <strong
                  style={{
                    fontFamily: TYPOGRAPHY.bold,
                  }}
                >
                  {item.name}:
                </strong>
                {item.value}
              </Box>

              <Divider sx={{ marginY: '.5rem' }} />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
