import { Box, Skeleton } from '@mui/material';

export const ProductDetailSkeleton = () => {
  return (
    <Box width={'100%'}>
      <Skeleton
        sx={{
          marginBottom: '1rem',
        }}
        variant="rectangular"
        width={'100%'}
        height={'20rem'}
      />
      <Skeleton width={'30%'} variant="text" sx={{ fontSize: '1rem' }} />
      <Skeleton width={'50%'} variant="text" sx={{ fontSize: '1.5rem' }} />
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
      <Skeleton width={'50%'} variant="text" sx={{ fontSize: '1.5rem' }} />
      <Skeleton width={'50%'} variant="text" sx={{ fontSize: '1rem' }} />

      <Box display={'flex'} justifyContent={'center'} marginBottom={'1rem'}>
        <Skeleton
          sx={{
            marginTop: '1rem',
            borderRadius: '20px',
          }}
          variant="rounded"
          width={'80%'}
          height={30}
        />
      </Box>

      <Skeleton width={'50%'} variant="text" sx={{ fontSize: '1.5rem' }} />
      <Skeleton width={'100%'} variant="text" sx={{ fontSize: '1rem' }} />
      <Skeleton width={'100%'} variant="text" sx={{ fontSize: '1rem' }} />
      <Skeleton width={'100%'} variant="text" sx={{ fontSize: '1rem' }} />
    </Box>
  );
};
