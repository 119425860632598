import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import { GatewayType, gatewayListEnum } from '../../hooks/useGateway';
import 'react-international-phone/style.css';
import * as Yup from 'yup';

// hooks
import { PhoneNumberInput } from './PhoneNumberInput';
import { Box } from '@mui/material';
import { patch } from '../../services/api';
import { useTranslation } from 'react-i18next';
import { useUser } from '@/components/AuthInterceptorComponent/hooks/useUser';

export type MissingDataInfo = {
  title: string;
  text: string;
  type: string;
  gateway: GatewayType;
  key: string;
};

type MissingDataModalProps = {
  open: boolean;
  missingDataModalInfo: MissingDataInfo;
  handleClose: () => void;
  onSuccess?: () => void;
  onError?: () => void;
};

const validationSchema = () => {
  return Yup.object().shape({
    email: Yup.string().email('Debe ser un correo válido'),
    phone: Yup.string().min(16),
  });
};

export default function MissingDataModal({
  handleClose,
  missingDataModalInfo,
  open,
  onSuccess,
  onError,
}: MissingDataModalProps) {
  const { text, title, gateway } = missingDataModalInfo;
  const { t } = useTranslation();
  const { user } = useUser();

  const isWhatsappOrSms = () => {
    return (
      gateway === gatewayListEnum.WHATSAPP || gateway === gatewayListEnum.SMS
    );
  };

  const {
    values,
    handleChange,
    handleSubmit,
    resetForm,
    errors,
    touched,
    setErrors,
  } = useFormik<{
    email: string;
    phone: string;
  }>({
    initialValues: {
      email: '',
      phone: '',
    },
    onSubmit: (values) => {
      if (isWhatsappOrSms()) {
        const [prefix, ...number] = values.phone.split(' ');

        addPhone(prefix, number.join(' ').replaceAll(' ', ''))
          .then((resp) => {
            if (resp?.status === 400) {
              setErrors({
                phone: resp.error.phone_number[0],
              });

              return;
            }
            handleClose();
            resetForm();
            onSuccess && onSuccess();
          })
          .catch(() => {
            onError && onError();
          });
      }

      if (gateway !== gatewayListEnum.WHATSAPP) {
        addEmail(values.email)
          .then((resp) => {
            if (resp?.status === 400) {
              setErrors({
                email: resp.error.email[0],
              });
              return;
            }

            handleClose();
            resetForm();
            onSuccess && onSuccess();
          })
          .catch(() => {
            onError && onError();
          });
      }
    },
    validationSchema,
  });
  const getLabel = (key: string) => {
    if (key.toLocaleUpperCase() === gatewayListEnum.EMAIL)
      return t('gateWayScreen.email');
    if (key.toLocaleUpperCase() === gatewayListEnum.TEAMS)
      return t('gateWayScreen.email') + ' Teams';
    if (key.toLocaleUpperCase() === gatewayListEnum.WHATSAPP) return 'Whatsapp';
    if (key.toLocaleUpperCase() === gatewayListEnum.GOOGLE_CHAT)
      return 'Google Chat';
    if (key.toLocaleUpperCase() === gatewayListEnum.SMS) return 'SMS';

    return key;
  };

  const addEmail = async (email: string) => {
    try {
      const response = await patch(`users/${user?.pk}/`, {
        email: email,
      });
      return response;
    } catch (error) {
      window.alert(t('gateWayScreen.errorUpdatingEmail'));
    }
  };

  const addPhone = async (prefixCode: string, phoneNumber: string) => {
    try {
      const response = await patch(`users/${user?.pk}/set-phone/`, {
        phone_number: phoneNumber,
        prefix: prefixCode,
      });
      return response;
    } catch (error) {
      window.alert(t('gateWayScreen.errorUpdating'));
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{ sx: { borderRadius: '2rem', padding: '1rem' } }}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{text}</DialogContentText>
          {isWhatsappOrSms() ? (
            <Box marginTop={'1.5rem'}>
              <PhoneNumberInput
                error={Boolean(errors.phone && touched.phone)}
                value={values.phone}
                onChange={(value) => {
                  handleChange({
                    target: {
                      name: 'phone',
                      value: value,
                    },
                  });
                }}
              />
            </Box>
          ) : (
            <TextField
              error={Boolean(errors.email && touched.email)}
              helperText={touched.email && errors.email ? errors.email : null}
              value={values.email}
              name="email"
              sx={{
                marginTop: '1.5rem',
                borderRadius: '2rem',
              }}
              autoFocus
              margin="dense"
              label={getLabel(gateway)}
              type="email"
              fullWidth
              variant="outlined"
              onChange={handleChange}
              required
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('gateWayScreen.cancel')}</Button>
          <Button
            onClick={() => handleSubmit()}
            variant="contained"
            sx={{
              borderRadius: '2rem',
              color: 'white',
            }}
          >
            {t('gateWayScreen.ready')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
