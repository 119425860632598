import React, { FunctionComponent } from 'react';
import { spinnerStyles } from './Spinner.styles';
import { motion } from 'framer-motion';

const Spinner: FunctionComponent = () => {
  const { classes } = spinnerStyles();

  return (
    <motion.div
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={classes.loader}
    >
      <div className="custom-loader"></div>
    </motion.div>
  );
};

export default Spinner;
