import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import Dialog from '../../../components/Dialog';
import ButtonTheme from '../../../components/ButtonTheme/ButtonTheme';
import rutaenconstr from '../../../theme/assets/rutaenconstr.svg';
import { useUser } from '@/components/AuthInterceptorComponent/hooks/useUser';

type UnderConstructionProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

export const UnderConstruction = ({
  open,
  setOpen,
}: UnderConstructionProps) => {
  const { user } = useUser();
  const { t } = useTranslation();
  const handleUnderConstruction = () => {
    setOpen(false);
  };
  return (
    <Dialog open={open} handleClose={handleUnderConstruction}>
      <Box textAlign="center">
        <img src={rutaenconstr} alt="ruta en construccion" width="200" />
        <Box fontWeight={700} component="h3">
          {user?.first_name},
        </Box>
        <p>
          {t('contentScreen.yourCourse')} <br />
          <b>{t('contentScreen.keepUnderConstruction')}.</b>
        </p>
        <hr />
        <p>
          {t('contentScreen.weWillSendYouSoon')}
          <br />
          <b>{t('contentScreen.theNextCapsule')}</b>
        </p>
        <ButtonTheme
          variant="contained"
          style={{ marginTop: '10px' }}
          onClick={handleUnderConstruction}
        >
          {t('general.getIt')}
        </ButtonTheme>
      </Box>
    </Dialog>
  );
};
